import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import {
  GetAllPatientsRequest,
  PatientIn,
  PatientOut,
} from "../generated/fetchclient"
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { patientCount, patients } from "../state/patient.state"
import { handleApiError } from "../utils/apiUtils"

function updatePatientInList(patients: PatientOut[], patient: PatientOut) {
  return patients.map((p) => {
    if (p.uuid === patient.uuid) {
      return patient
    }
    return p
  })
}

const usePatients = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setPatients = useSetRecoilState(patients)
  const setPatientCount = useSetRecoilState(patientCount)
  const resetPatients = useResetRecoilState(patients)
  const resetPatientCount = useResetRecoilState(patientCount)

  /**
   * Creates a new patient.
   *
   * @param patient The details of the patient to create.
   */
  const createPatient = async (patient: PatientIn) => {
    if (api === undefined) return

    try {
      await api.createPatient({ patientIn: patient })
      setSnackAlertMsg({
        open: true,
        message: "Patient Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      listPatients()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a patient by its ID.
   *
   * @param patientId The ID of the patient to delete.
   */
  const deletePatient = async (patientId: string) => {
    if (api === undefined) return

    try {
      await api.deletePatient({ patientId })
      setSnackAlertMsg({
        open: true,
        message: "Patient Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      listPatients()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Get a patient by its ID.
   *
   * @param patientId The ID of the patient to get.
   */
  const getPatient = async (patientId: string) => {
    if (api === undefined) return

    try {
      return await api.getPatient({ patientId })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists patients.
   *
   * @param query The query to filter the list of patients.
   */
  const listPatients = async (query: GetAllPatientsRequest = {}) => {
    if (api === undefined) return

    try {
      const patients = await api.getAllPatients(query)
      setPatients(patients.items)
      setPatientCount(patients.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Updates a patient.
   *
   * @param patientId The ID of the patient to be updated.
   * @param patient The updated patient object.
   */
  const updatePatient = async (patientId: string, patient: PatientIn) => {
    if (api === undefined) return

    try {
      const updatedPatient = await api.updatePatient({
        patientId,
        patientUpdate: patient,
      })
      setPatients((patients) => updatePatientInList(patients, updatedPatient))
      setSnackAlertMsg({
        open: true,
        message: "Patient Updated",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const resetPatientData = () => {
    resetPatients()
    resetPatientCount()
  }

  return {
    patients: useRecoilValue(patients),
    patientCount: useRecoilValue(patientCount),
    createPatient,
    deletePatient,
    getPatient,
    listPatients,
    updatePatient,
    resetPatientData,
  }
}

export default usePatients
