import Box from "@mui/joy/Box"
import Link from "@mui/joy/Link"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import InternalHeader from "../../components/internalheader/InternalHeader"
import { useEffect, useState } from "react"
import { HeaderList } from "../../interfaces/header.inteface"

const OrganizationContentMenuHeader = () => {
  const [headerList, setHeaderList] = useState<HeaderList[]>([])
  useEffect(() => {
    setHeaderList([
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Organizations",
      },
    ])
  }, [])
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <InternalHeader breadCrumbList={headerList}>
        <Link
          underline="none"
          color="neutral"
          href="#some-link"
          aria-label="Home"
        >
          <HomeRoundedIcon />
        </Link>
      </InternalHeader>
    </Box>
  )
}

export default OrganizationContentMenuHeader
