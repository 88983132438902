import { Hub } from "aws-amplify"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { UserOut } from "../../../generated/fetchclient"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { JWT, currentUser, isHuxleyUser } from "../../../state/auth.state"
import { useCookies } from "react-cookie"
import { handleApiError } from "../../../utils/apiUtils"

/**
 * useLoadUser is a hook that loads the current user from the API.
 * Sets the 'currentUser' recoil state.
 */
export const useLoadUser = () => {
  const api = usePortalApi()
  const setApiCurrentUser = useSetRecoilState(currentUser)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  useEffect(() => {
    api
      ?.currentUser()
      .then((user: UserOut) => {
        setApiCurrentUser(user)
      })
      .catch((error) => {
        handleApiError(setSnackAlertMsg)(error)
      })
  }, [api])
}

/**
 * useHandleSignOut is a hook that handles the sign out event from Cognito.
 * Handles the logout button click [Auth.signOut()]
 */
export const useHandleSignOut = () => {
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies()
  const resetIsHuxleyUser = useResetRecoilState(isHuxleyUser)
  const resetJwt = useResetRecoilState(JWT)

  // Handle the logout button click [Auth.signOut()]
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", async ({ payload: { event } }) => {
      switch (event) {
        case "signOut":
          removeCookie("sessionid")
          resetIsHuxleyUser()
          resetJwt()
          navigate("/login")
          break
      }
    })

    return unsubscribe
  }, [])
}
