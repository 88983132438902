import { Point } from "@huxley-medical/react-components/types"
import { Signal } from "../interfaces/signals.interface"

export function secToHours(seconds: number): number {
  return seconds / 3600
}

/**
 * Converts seconds to hours and minutes.
 * @param d - The number of seconds.
 * @returns The formatted string representing hours and minutes.
 */
export function secondsToHms(d: number) {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)

  const hDisplay = h > 0 ? h + "h " : ""
  const mDisplay = m > 0 ? m + "m " : ""
  return hDisplay + mDisplay
}

/**
 * Converts an array of TimeSeriesPoint objects to an array of Point objects.
 * @param data The array of TimeSeriesPoint objects to convert.
 * @returns An array of Point objects.
 */
export const timeSeriesPointsToPoints = (data: Signal): Point[] =>
  data.timestamps.map((p: number, i: number) => ({ x: p, y: data.values[i] }))

export const dateToFormattedTimeString = (date: Date): string => {
  //check for am or pm
  const hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")
  const milliseconds = date.getMilliseconds().toString().padStart(3, "0")
  const ampm = hours >= 12 ? "pm" : "am"
  const hours12 = hours % 12 || 12

  return (
    hours12 + ":" + minutes + ":" + seconds + "." + milliseconds + " " + ampm
  )
}

export function formatDateTime(
  date: Date,
  format: "date" | "time",
  locale = "en-US"
): string {
  return format === "date"
    ? date.toLocaleDateString(locale, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : date.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
}
