import React from "react"

interface SleepSummaryProps {
  total_study_time: string
  sleep_time: string
  effective_sleep_time: string
  sleep_latency: string
  sleep_efficiency: string
}

const SleepSummary: React.FC<SleepSummaryProps> = ({
  total_study_time,
  sleep_time,
  effective_sleep_time,
  sleep_latency,
  sleep_efficiency,
}) => {
  return (
    <table className="data-table">
      <tr>
        <th className="table-head" colSpan={2}>
          <strong>Sleep Summary</strong>
        </th>
      </tr>
      <tr>
        <td>Total Study Time</td>
        <td>{total_study_time}</td>
      </tr>
      <tr>
        <td>Sleep Time</td>
        <td>{sleep_time}</td>
      </tr>
      <tr>
        <td>Effective Sleep Time</td>
        <td>{effective_sleep_time}</td>
      </tr>
      <tr>
        <td>Sleep Latency</td>
        <td>{sleep_latency}</td>
      </tr>
      <tr>
        <td>Sleep Efficiency</td>
        <td>{sleep_efficiency}%</td>
      </tr>
    </table>
  )
}

export default SleepSummary
