/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudyDataUrlOut
 */
export interface StudyDataUrlOut {
    /**
     * 
     * @type {string}
     * @memberof StudyDataUrlOut
     */
    ahi3Url: string;
    /**
     * 
     * @type {string}
     * @memberof StudyDataUrlOut
     */
    ahi4Url: string;
    /**
     * 
     * @type {string}
     * @memberof StudyDataUrlOut
     */
    signalsUrl: string;
    /**
     * 
     * @type {string}
     * @memberof StudyDataUrlOut
     */
    pulseAnnotationsUrl?: string;
}

/**
 * Check if a given object implements the StudyDataUrlOut interface.
 */
export function instanceOfStudyDataUrlOut(value: object): value is StudyDataUrlOut {
    if (!('ahi3Url' in value) || value['ahi3Url'] === undefined) return false;
    if (!('ahi4Url' in value) || value['ahi4Url'] === undefined) return false;
    if (!('signalsUrl' in value) || value['signalsUrl'] === undefined) return false;
    return true;
}

export function StudyDataUrlOutFromJSON(json: any): StudyDataUrlOut {
    return StudyDataUrlOutFromJSONTyped(json, false);
}

export function StudyDataUrlOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyDataUrlOut {
    if (json == null) {
        return json;
    }
    return {
        
        'ahi3Url': json['ahi_3_url'],
        'ahi4Url': json['ahi_4_url'],
        'signalsUrl': json['signals_url'],
        'pulseAnnotationsUrl': json['pulse_annotations_url'] == null ? undefined : json['pulse_annotations_url'],
    };
}

export function StudyDataUrlOutToJSON(value?: StudyDataUrlOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ahi_3_url': value['ahi3Url'],
        'ahi_4_url': value['ahi4Url'],
        'signals_url': value['signalsUrl'],
        'pulse_annotations_url': value['pulseAnnotationsUrl'],
    };
}

