import { CallbackInterface, RecoilState } from "recoil"
import { EventPlot, ScoringCriteria } from "../types/event.type"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { ExclusionData } from "../interfaces/exclusions.interface"
import {
  ecgBadExclusions,
  ecgExclusions3,
  ecgExclusions4,
  ppgExclusions3,
  ppgExclusions4,
} from "../state/exclusions.state"
import { editEventExclusionStatus } from "../state/study.state"

export type EventPlotExclusions = "HR" | "SpO2" | "ECG"

export const exclusionRemove =
  (callback: CallbackInterface) =>
  (
    exclusionID: number,
    plot: EventPlot,
    exclusionPlottingData: ExclusionAnnotation[],
    userId: string | undefined,
    selectedScoringCriteria: ScoringCriteria
  ) =>
  () => {
    const exclusionData: ExclusionAnnotation[] = [...exclusionPlottingData]
    exclusionData[exclusionID] = {
      ...exclusionData[exclusionID],
      removed: true,
      removedOn: new Date().getTime() / 1000,
      removedBy: userId,
    }

    const isScoring3 = ScoringCriteria.THREE === selectedScoringCriteria

    const exclusionMapping: Record<
      EventPlotExclusions,
      RecoilState<ExclusionData[]>
    > = {
      HR: isScoring3 ? ecgExclusions3 : ecgExclusions4,
      SpO2: isScoring3 ? ppgExclusions3 : ppgExclusions4,
      ECG: ecgBadExclusions,
    }

    if (plot === "HR" || plot === "SpO2" || plot === "ECG") {
      callback.set(exclusionMapping[plot], exclusionData)
      callback.set(editEventExclusionStatus, true)
    }
  }
