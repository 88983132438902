import { atom, selector } from "recoil"
import { studyMetaData3 } from "../state/study.state"
import {
  allNonRemovedExclusions,
  nonExcludedHr,
  nonSelectedCriteriaAllExclusions,
} from "../state/exclusions.state"
import { Signal, SleepInfo } from "../interfaces/signals.interface"
import {
  getNotOverlappingTimeSeriesWithEventArray,
  getOverlappingTimeSeriesWithEventArray,
  sleepWakeEventsToTimeSeriesPoints,
} from "../utils/seriesUtils"
import { getSumOfOverlappingTimeBetweenTwoEventArrays } from "../utils/eventUtils"
import { EventApi } from "../types/event.type"
import { sleepWakeEvents } from "./event.state"
import { LinePlot } from "../types/line.type"
import { PositionMap, SleepStageMap } from "../const/signals.const"
import { NumberValue } from "d3-scale"

export const sleepPlot = selector<Signal>({
  key: "sleepPlot",
  get: ({ get }) => {
    return sleepWakeEventsToTimeSeriesPoints(get(sleepWakeEvents)).data
  },
})

export const studySignals = atom<LinePlot[]>({
  key: "studySignals",
  default: [
    {
      title: "ECG",
      data: { timestamps: [], values: [] },
      lineColor: "#cb2f2f",
      plotType: "ECG",
      unit: "mV",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Snoring",
      data: { timestamps: [], values: [] },
      lineColor: "#5A97F7",
      plotType: "Snoring",
      unit: "",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Resp. Effort",
      data: { timestamps: [], values: [] },
      lineColor: "#5A97F7",
      plotType: "Resp",
      unit: "",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "SpO2",
      data: { timestamps: [], values: [] },
      lineColor: "#5A97F7",
      plotType: "SpO2",
      unit: "% SpO2",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "HR",
      data: { timestamps: [], values: [] },
      lineColor: "#cb2f2f",
      plotType: "HR",
      unit: "BPM",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Chest",
      data: { timestamps: [], values: [] },
      lineColor: "#e0be66",
      plotType: "Chest",
      yDomainPadding: 0.01,
      unit: "m/s^2",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Actigraphy",
      data: { timestamps: [], values: [] },
      lineColor: "#e0be66",
      plotType: "Actigraphy",
      yDomainPadding: 15,
      unit: "m/s^2",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Position",
      data: { timestamps: [], values: [] },
      lineColor: "#2b2b2b",
      plotType: "Position",
      yTickValues: Object.values(PositionMap).map(
        (positionType, _) => positionType
      ),
      yTickFormatter: (num: NumberValue) => {
        const positionKey = Object.entries(PositionMap).find(
          ([_, val]) => val === num
        )
        return positionKey ? positionKey[0].charAt(0) : "?"
      },
      signalLength: 0,
      yDomainPadding: 15,
      unit: "position",
      plotLine: "step-line",
      signalMaxValue: 0,
      signalMinValue: 0,
    },
    {
      title: "Sleep",
      data: { timestamps: [], values: [] },
      lineColor: "#2b2b2b",
      plotType: "SANSASleepStage",
      yTickValues: Object.keys(SleepStageMap).map((_, inx) => inx),
      yTickFormatter: (num: NumberValue) => {
        return Object.keys(SleepStageMap)[num.valueOf()] ?? "?"
      },
      yDomainPadding: 15,
      unit: "",
      plotLine: "step-line",
      signalLength: 0,
      signalMaxValue: 0,
      signalMinValue: 0,
    },
  ],
  dangerouslyAllowMutability: true,
})

export const nonExcludedSpo2 = selector<Signal>({
  key: "nonExcludedSpo2",
  get: ({ get }) => {
    const studySignalData = get(studySignals)
    if (!studySignalData) return { timestamps: [], values: [] }
    const spo2 = studySignalData.filter((study) => study.title === "SpO2")
    const exclusions = get(allNonRemovedExclusions)
    return getNotOverlappingTimeSeriesWithEventArray(
      spo2.length > 0 && spo2[0].data
        ? spo2[0].data
        : { timestamps: [], values: [] },
      exclusions
    ) //
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
})

export const sleepingHr = selector<Signal>({
  key: "sleepingHr",
  get: ({ get }) => {
    return getOverlappingTimeSeriesWithEventArray(
      get(nonExcludedHr),
      get(sleepWakeEvents).filter((event) => event.event_data.sleep_label)
    )
  },
})

export const allPlotTimeDomain = atom<number[]>({
  key: "allPlotTimeDomain",
  default: [0, 0],
})

/**
 * sleepInfo - returns the sleepInfo for given study
 */
export const sleepInfo = selector<SleepInfo>({
  key: "sleepInfo",
  get: ({ get }) => {
    const sleepE = get(sleepWakeEvents)
    const exclusions = get(allNonRemovedExclusions)
    const studyStart = get(studyMetaData3).studyStartTime
    const studyEnd = get(studyMetaData3).studyEndTime
    const sleepDurationSeconds =
      sleepE &&
      sleepE
        .filter((sleepData: EventApi) => sleepData.event_data.sleep_label)
        .reduce((acc, event) => acc + event.endTS - event.startTS, 0)

    const sleepTime =
      sleepE &&
      sleepE.filter((sleepData: EventApi) => {
        return sleepData.event_data.sleep_label
      })

    const exclusionsExceptForLowAmp = exclusions.filter(
      (exclusion) => exclusion.event_data?.label !== 2
    )

    const efftst =
      sleepDurationSeconds -
      (getSumOfOverlappingTimeBetweenTwoEventArrays(
        sleepTime,
        exclusionsExceptForLowAmp
      ) +
        0)

    const firstSleepAwake =
      sleepE &&
      sleepE.find((sleepData: EventApi) => sleepData.event_data.sleep_label)
    const sleepLatencySeconds = firstSleepAwake
      ? firstSleepAwake.startTS - studyStart
      : 0

    const sleepEfficiencyPercent =
      (sleepDurationSeconds / (studyEnd - studyStart)) * 100

    return {
      sleepDurationSeconds,
      sleepLatencySeconds,
      sleepEfficiencyPercent,
      effSleepTime: efftst,
    }
  },
})

/**
 * sleepInfo - returns the sleepInfo for given study
 */
export const nonSelectedScoringCriteriaSleepInfo = selector<SleepInfo>({
  key: "nonSelectedScoringCriteriaSleepInfo",
  get: ({ get }) => {
    const sleepE = get(sleepWakeEvents)
    const exclusions = get(nonSelectedCriteriaAllExclusions)
    const studyStart = get(studyMetaData3).studyStartTime
    const studyEnd = get(studyMetaData3).studyEndTime
    const sleepDurationSeconds =
      sleepE &&
      sleepE
        .filter((sleepData: EventApi) => sleepData.event_data.sleep_label)
        .reduce((acc, event) => acc + event.endTS - event.startTS, 0)

    const sleepTime =
      sleepE &&
      sleepE.filter((sleepData: EventApi) => {
        return sleepData.event_data.sleep_label
      })

    const exclusionsExceptForLowAmp = exclusions.filter(
      (exclusion) => exclusion.event_data?.label !== 2
    )

    const efftst =
      sleepDurationSeconds -
      (getSumOfOverlappingTimeBetweenTwoEventArrays(
        sleepTime,
        exclusionsExceptForLowAmp
      ) +
        0)

    const firstSleepAwake =
      sleepE &&
      sleepE.find((sleepData: EventApi) => sleepData.event_data.sleep_label)
    const sleepLatencySeconds = firstSleepAwake
      ? firstSleepAwake.startTS - studyStart
      : 0

    const sleepEfficiencyPercent =
      (sleepDurationSeconds / (studyEnd - studyStart)) * 100

    return {
      sleepDurationSeconds,
      sleepLatencySeconds,
      sleepEfficiencyPercent,
      effSleepTime: efftst,
    }
  },
})
