import { useState } from "react"
import { emptySelectedPatient } from "../../../components/study/newstudymodal/NewStudyModal"
import PatientSelectForm from "../../../components/study/newstudymodal/PatientSelectForm"
import StudyDetailForm from "../../../components/study/newstudymodal/StudyDetailForm"
import { PatientIdentifier } from "../../../types/patient.type"
import { EditModalStudyData } from "../../../types/event.type"

type StudyOrderFormParams = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  patientData?: PatientIdentifier
  studyData?: EditModalStudyData
}

function StudyOrderForm({
  setOpen,
  page,
  setPage,
  patientData,
  studyData,
}: StudyOrderFormParams) {
  const [selectedPatient, setSelectedPatient] = useState<PatientIdentifier>(
    patientData !== undefined && Object.keys(patientData).length > 0
      ? patientData
      : emptySelectedPatient
  )

  switch (page) {
    case 1:
    default:
      return (
        <PatientSelectForm
          selectedPatient={selectedPatient}
          setPage={setPage}
          setSelectedPatient={setSelectedPatient}
        />
      )
    case 2:
      return (
        <StudyDetailForm
          selectedPatient={selectedPatient}
          studyData={studyData}
          setPage={setPage}
          setOpen={setOpen}
        />
      )
  }
}

export default StudyOrderForm
