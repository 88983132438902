import { OverlayWithTooltipParams } from "../../../types/tooltip"
import usePlotHoverTooltips from "./PlotHoverTooltips"
import { StyledOverlay } from "../../../styled/Bar"

/**
 * OverlayWithTooltip is an abstracted component for rendering performance benefits.
 * It provides the y-axis tooltip with StyledOverlay SVG element for use with plots
 * and the PlotEvents component.
 *
 * @param {OverlayWithTooltipParams} OverlayWithTooltipParams
 * @returns {JSX.Element}
 */
const OverlayWithTooltip = ({
  isDrawDisabled,
  width,
  height,
  innerRef,
  tooltipParams,
}: OverlayWithTooltipParams): JSX.Element => {
  // Setup y-tooltip
  const { hideTooltip, updateTooltip, PlotHoverTooltips } =
    usePlotHoverTooltips(tooltipParams)

  return (
    <>
      <PlotHoverTooltips />
      <StyledOverlay
        sx={{
          ...(isDrawDisabled
            ? {
                fill: "rgba(0,0,0, 0.1)",
              }
            : {}),
        }}
        x={0}
        y={0}
        height={height}
        width={width}
        // Move these params to the plot-events group (in PlotEvents.tsx)
        // to enable tooltip under event window
        innerRef={innerRef}
        onMouseMove={updateTooltip}
        onMouseOut={hideTooltip}
      />
    </>
  )
}

export default OverlayWithTooltip
