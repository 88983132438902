import React, { FC } from "react"

interface ButtonProps {
  size?: number
  color?: string
}

interface SvgButtonProps {
  size?: number
  color?: string
  children: React.ReactNode
}

export const SvgButton: FC<SvgButtonProps> = ({
  size = 16,
  color = "currentColor",
  children,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {children}
  </svg>
)

export const BoldButton: FC<ButtonProps> = ({
  size = 16,
  color = "currentColor",
}) => (
  <SvgButton size={size} color={color}>
    <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
    <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
  </SvgButton>
)

export const UnderLineButton: FC<ButtonProps> = ({
  size = 16,
  color = "currentColor",
}) => (
  <SvgButton size={size} color={color}>
    <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3"></path>
    <line x1="4" y1="21" x2="20" y2="21"></line>
  </SvgButton>
)

export const ItalicButton: FC<ButtonProps> = ({
  size = 16,
  color = "currentColor",
}) => (
  <SvgButton size={size} color={color}>
    <path d="M19 4h-9M14 20H5M14.7 4.7L9.2 19.4" />
  </SvgButton>
)

export const BulletListButton: FC<ButtonProps> = ({
  size = 16,
  color = "currentColor",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="4" cy="7" r="1"></circle>
    <circle cx="4" cy="12" r="1"></circle>
    <circle cx="4" cy="17" r="1"></circle>

    <line x1="8" y1="7" x2="20" y2="7"></line>
    <line x1="8" y1="12" x2="20" y2="12"></line>
    <line x1="8" y1="17" x2="20" y2="17"></line>
  </svg>
)

export const OrderedListButton: FC<ButtonProps> = ({
  size = 16,
  color = "currentColor",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <text x="2" y="10" fontFamily="Arial" fontSize="12" fill={color}>
      1
    </text>
    <line
      x1="14"
      y1="9"
      x2="22"
      y2="9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />

    <text x="2" y="20" fontFamily="Arial" fontSize="12" fill={color}>
      2
    </text>
    <line
      x1="14"
      y1="19"
      x2="22"
      y2="19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
