import { HelpOutline } from "@mui/icons-material"
import { Box, Stack, Tooltip, Typography } from "@mui/joy"

interface DataDisplayProps {
  label: string
  value: React.ReactNode
  tooltipHelp?: string
}

const AccordianDataDisplay: React.FC<DataDisplayProps> = ({
  label,
  value,
  tooltipHelp,
}) => (
  <Typography level="title-sm">
    <Stack
      direction={{ sm: "column", lg: "row" }}
      spacing={0.5}
      alignItems={"center"}
    >
      <Typography fontWeight={700}>{label}:</Typography>
      <Box>{value}</Box>
      {tooltipHelp && (
        <Tooltip title={tooltipHelp} enterTouchDelay={0}>
          <HelpOutline />
        </Tooltip>
      )}
    </Stack>
  </Typography>
)

export default AccordianDataDisplay
