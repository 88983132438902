import { useCallback, useEffect } from "react"
import { relMouseCoords } from "../utils"
import { Point } from "../types"

export type DragMoveCallback = (relPoint: Point, absPoint: Point) => void
export type DragEndCallback = (relPoint: Point, absPoint: Point) => void

type UseWindowDragParams = {
  start: Point | undefined
  el: Element | null
  dragEnd: DragEndCallback
  dragMove: DragMoveCallback
}

/**
 * useWindowDrag is used extensively throughout application to handle
 * resize, and drag user actions. It will calculate relative mouse coordinates
 * that will be passed into the dragEnd and dragMove callbacks given the el.
 *
 * A start point must be provided to invoke the hook, enabling the later
 * invocation of dragMove and dragEnd callbacks.
 *
 * @param {UseWindowDragParams} useWindowDragParams
 * @param {any[]} deps - Dependency array used in useEffect to trigger callback
 * closure changes
 */
export const useWindowDrag = (
  { start, el, dragEnd, dragMove }: UseWindowDragParams,
  deps: any[] = []
) => {
  const handleEndEvent = useCallback(
    (e: MouseEvent) => {
      if (start === undefined) return
      if (el === null) return

      dragEnd(relMouseCoords(e, el), { x: e.pageX, y: e.pageY })
    },
    [start, ...deps]
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (start === undefined) return
      if (el === null) return

      dragMove(relMouseCoords(e, el), { x: e.pageX, y: e.pageY })
    },
    [start, ...deps]
  )

  useEffect(() => {
    window.addEventListener("mouseup", handleEndEvent)
    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mouseup", handleEndEvent)
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [handleMouseMove, handleEndEvent])
}

type UseMouseWheelParams = {
  handler: (wheelEvent: WheelEvent) => void
  activated: boolean
}

/**
 * useMouseWheel invokes the given handler if activated is true.
 *
 * @param {UseMouseWheelParams} UseMouseWheelParams
 * @param {any[]} deps - Dependency array used in useEffect to trigger callback
 * closure changes
 */
export const useMouseWheel = (
  { handler, activated }: UseMouseWheelParams,
  deps: any[] = []
) => {
  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (!activated) return

      handler(e)
    },
    [activated, ...deps]
  )

  useEffect(() => {
    window.addEventListener("wheel", handleWheel)

    return () => {
      window.removeEventListener("wheel", handleWheel)
    }
  }, [handleWheel])
}
