import { atom, selector } from "recoil"
import { ScoringCriteria } from "../types/event.type"
import {
  nonExcludedSpo2,
  nonSelectedScoringCriteriaSleepInfo,
  sleepInfo,
  sleepingHr,
} from "../state/signals.state"
import { activeCriteria } from "../state/study.state"
import {
  activeDesatEvents,
  activeRespEvents,
  activeSvebEvents,
  activeVebEvents,
  nonSelectedActiveRespEvents,
  nonSelectedCriteriaActiveDesatEvents,
} from "../state/event.state"
import { nonExcludedHr } from "../state/exclusions.state"
import { SummaryIndices } from "../interfaces/graphSummary.interface"
import {
  timeseriesMax,
  timeseriesMean,
  timeseriesMin,
} from "../utils/seriesUtils"
import {
  meanOfDesatNadirs,
  sumDesatEventsBetweenValues,
} from "../utils/eventUtils"
import { secToHours } from "../utils/timeUtils"

export const summaryIndices3 = atom<SummaryIndices | undefined>({
  key: "summaryIndices3",
  default: undefined,
})

export const summaryIndices4 = atom<SummaryIndices | undefined>({
  key: "summaryIndices4",
  default: undefined,
})

export const spo2Metrics = selector<{
  meanSpo2: number
  minSpo2: number
  maxSpo2: number
}>({
  key: "spo2Metrics",
  get: ({ get }) => {
    const spo2 = get(nonExcludedSpo2)
    return {
      meanSpo2: timeseriesMean(spo2.values),
      minSpo2: timeseriesMin(spo2.values),
      maxSpo2: timeseriesMax(spo2.values),
    }
  },
})

export const desatMetrics = selector<{
  meanDesatNadir: number
  desatsBelow9: number
  desatsNineTo20: number
  desatsAbove20: number
  totalEvents?: number
}>({
  key: "desatMetrics",
  get: ({ get }) => {
    const desatEventData = get(activeDesatEvents)
    const respEventData = get(activeRespEvents)
    const meanDesatNadir = meanOfDesatNadirs(desatEventData)
    const desatsUnderNine = sumDesatEventsBetweenValues(desatEventData, 0, 10)
    const desatsNineTo20 = sumDesatEventsBetweenValues(desatEventData, 10, 20)
    const desatsAbove20 = sumDesatEventsBetweenValues(desatEventData, 20, 100)
    const totalEvents = respEventData.length

    return {
      meanDesatNadir,
      desatsBelow9: desatsUnderNine,
      desatsNineTo20,
      desatsAbove20,
      totalEvents: totalEvents,
    }
  },
})

export const desatOdiAhiMetrics = selector<{
  odi4: number
  ahi4: number
  odi3: number
  ahi3: number
}>({
  key: "desatOdiAhiMetrics",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const selectedCriteriaSleepDuration = get(sleepInfo).effSleepTime
    const selectedCriteriaDesatEventData = get(activeDesatEvents)
    const selectedCriteriaRespEventData = get(activeRespEvents)
    const selectedCriteriaAhi =
      selectedCriteriaRespEventData.length /
      secToHours(selectedCriteriaSleepDuration)
    const selectedCriteriaOdi =
      selectedCriteriaDesatEventData.length /
      secToHours(selectedCriteriaSleepDuration)
    const nonSelectedCriteriaSleepDuration = get(
      nonSelectedScoringCriteriaSleepInfo
    ).effSleepTime
    const nonSelectedCriteriaDesatEventData = get(
      nonSelectedCriteriaActiveDesatEvents
    )
    const nonSelectedCriteriaRespEventData = get(nonSelectedActiveRespEvents)
    const nonSelectedCriteriaAhi =
      nonSelectedCriteriaRespEventData.length /
      secToHours(nonSelectedCriteriaSleepDuration)
    const nonSelectedCriteriaOdi =
      nonSelectedCriteriaDesatEventData.length /
      secToHours(nonSelectedCriteriaSleepDuration)
    const getCriteriaValue = (
      scoringCriteria: ScoringCriteria,
      selectedCriteria: number,
      nonSelectedCriteria: number
    ) =>
      scoringCriteria === selectedScoringCriteria
        ? selectedCriteria
        : nonSelectedCriteria

    return {
      odi4: getCriteriaValue(
        ScoringCriteria.FOUR,
        selectedCriteriaOdi,
        nonSelectedCriteriaOdi
      ),
      ahi4: getCriteriaValue(
        ScoringCriteria.FOUR,
        selectedCriteriaAhi,
        nonSelectedCriteriaAhi
      ),
      odi3: getCriteriaValue(
        ScoringCriteria.THREE,
        selectedCriteriaOdi,
        nonSelectedCriteriaOdi
      ),
      ahi3: getCriteriaValue(
        ScoringCriteria.THREE,
        selectedCriteriaAhi,
        nonSelectedCriteriaAhi
      ),
    }
  },
})

export const hrMetrics = selector<{
  meanHr: number
  minHr: number
  maxHr: number
  meanHrSleeping: number
  minHrSleeping: number
  maxHrSleeping: number
}>({
  key: "hrMetrics",
  get: ({ get }) => {
    const hr = get(nonExcludedHr)
    const sleepHr = get(sleepingHr)
    return {
      meanHr: timeseriesMean(hr.values),
      minHr: timeseriesMin(hr.values),
      maxHr: timeseriesMax(hr.values),
      meanHrSleeping: timeseriesMean(sleepHr.values),
      minHrSleeping: timeseriesMin(sleepHr.values),
      maxHrSleeping: timeseriesMax(sleepHr.values),
    }
  },
})

export const ecgMetrics = selector<{
  vebEvents: number
  svebEvents: number
}>({
  key: "ecgMetrics",
  get: ({ get }) => {
    const vebEvents = get(activeVebEvents).length
    const svebEvents = get(activeSvebEvents).length
    return {
      vebEvents: vebEvents,
      svebEvents: svebEvents,
    }
  },
})
