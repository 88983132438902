import { atom } from "recoil"

export const deleteDeviceModalOpen = atom<boolean>({
  key: "deleteDeviceModalOpen",
  default: false,
})

export const deviceToBeDeleted = atom<string | undefined>({
  key: "deviceToBeDeleted",
  default: undefined,
})
