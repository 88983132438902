import { Alert, Snackbar } from "@mui/material"
import { atom, useRecoilState } from "recoil"
import { SnackAlert } from "../types/snackAlert.types"

export type CloseHandler = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => void

export const snackAlert = atom<SnackAlert>({
  key: "snackAlert",
  default: {
    open: false,
    message: "",
    severity: "warning",
    autoHideDuration: 3000,
  },
})

const autoHideDefaultMS = 2500

/**
 * SnackAlerts is used to render alerts in the application, triggered
 * by recoil state variable: snackAlert
 *
 * @returns {JSX.Element}
 */
const SnackAlerts = (): JSX.Element => {
  const [snackAlertMsg, setSnackAlertMsg] = useRecoilState(snackAlert)

  const handleClose: CloseHandler = (_, reason) => {
    if (reason === "clickaway") return
    setSnackAlertMsg({ ...snackAlertMsg, open: false })
  }

  return (
    <Snackbar
      open={snackAlertMsg.open}
      autoHideDuration={snackAlertMsg.autoHideDuration ?? autoHideDefaultMS}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        ".MuiPaper-elevation": {
          border: "1.5px solid #EFEEF4",
        },
      }}
    >
      <Alert
        severity={snackAlertMsg.severity ?? "warning"}
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.4) 0px 8px 24px;",
          ...(snackAlertMsg.severity === "error"
            ? { border: "1px solid #d47171 !important" }
            : {}),
        }}
        onClose={() => setSnackAlertMsg({ ...snackAlertMsg, open: false })}
      >
        {snackAlertMsg.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackAlerts
