import { ModalDialog, ModalClose, Stack, DialogTitle, Modal } from "@mui/joy"
import { EditPatientModalProps } from "../../interfaces/modal.interface"
import NewPatientForm from "./newpatientmodal/NewPatientForm"

function EditPatientModal({
  open,
  setOpen,
  patientData,
}: EditPatientModalProps) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog sx={{ maxWidth: 880 }}>
        <ModalClose />
        <Stack spacing={2}>
          <DialogTitle>Edit Patient:</DialogTitle>
          <NewPatientForm setOpen={setOpen} patientData={patientData} />
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default EditPatientModal
