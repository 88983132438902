import {
  ActiveSleepStudyOrderOut,
  PatientOut,
} from "../../generated/fetchclient"
import { studies } from "../../state/study.state"
import {
  Avatar,
  Box,
  Button,
  Chip,
  CssVarsProvider,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  extendTheme,
} from "@mui/joy"
import {
  Add,
  ArrowForward,
  Edit,
  MedicalInformationOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material"
import { dateSortComparator, formatDate } from "../../utils/formatDate"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { StyledDataGrid } from "../../styled/DataGrid"
import { GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import PatientDetailNewStudyModal from "./PatientDetailNewStudyModal"
import { formatPhoneNumber, Value } from "react-phone-number-input"
import {
  getStudyStatusColorAndText,
  simplifyStudyStatus,
  statusStrToEnum,
  StudyStatus,
  studyStatusValues,
} from "../../utils/studyUtils"
import { isHuxleyUser } from "../../state/auth.state"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import React from "react"
import { StudyStatusIndicator } from "../study/StudyTable"
import StudyStatusStepper from "../study/StudyStatusStepper"
import EditStudyModal from "../study/EditStudyModal"
import { emptyStudyInput } from "../study/newstudymodal/StudyDetailForm"
import usePatients from "../../hooks/usePatients"
import { usePortalApi } from "../../connections"
import {
  PatientDetailParams,
  PatientIdentifier,
} from "../../types/patient.type"
import EditPatientModal from "./EditPatientModal"
import { EditModalPatientData } from "../../types/event.type"
import useStudies from "../../hooks/useStudies"
import { patientRaceToString } from "../../const/patient.const"

interface PreviousStudiesTableRow {
  id?: string
  readableId: number
  date: string
  orderingPhysician: string
  interpretingPhysician: string
  device: string
  status: string
}

// const desatValueToString: { [key: string]: string } = {
//   three: "3%",
//   four: "4%",
//   both: "3% and 4%",
// }

const readyStatuses = [
  StudyStatus.READY_FOR_REVIEW,
  StudyStatus.READY_FOR_INTERPRETATION,
]

const emptyPatient: PatientOut = {
  uuid: "",
  organization: "",
  firstName: "",
  lastName: "",
  fullName: "",
  mrn: "",
  dob: new Date(),
  race: "",
  sex: "",
  primaryPhone: "",
  email: "",
  referringPhysicianFirstName: "",
  referringPhysicianLastName: "",
  referringPhysicianPhone: "",
  studyOrders: [],
  activeStudyOrder: undefined,
}

function PatientDetail({ setHeaderList, setIsLoading }: PatientDetailParams) {
  const nav = useNavigate()
  const { patientID } = useParams()
  const patientsApi = usePatients()
  const studiesApi = useStudies()
  const api = usePortalApi()
  const isAdmin = useRecoilValue(isHuxleyUser)
  const studyStatusValue = React.useMemo(() => studyStatusValues(), [])
  const [patient, setPatient] = useState<PatientOut>(emptyPatient)
  const [activeStudy, setActiveStudy] = useState<ActiveSleepStudyOrderOut>()
  const [tableRows, setTableRows] = useState<PreviousStudiesTableRow[]>([])
  const [openEditPatientModal, setOpenEditPatientModal] = useState(false)
  const [openEditStudyModal, setOpenEditStudyModal] = useState(false)
  const [openNewStudyModal, setOpenNewStudyModal] = useState(false)
  const [status, setStatus] = useState<StudyStatus>(StudyStatus.ORDERED)
  const studiesState = useRecoilValue(studies)

  const fetchData = async () => {
    if (patientID === undefined) return

    setIsLoading(true)
    const apiPatient = await patientsApi.getPatient(patientID)
    await studiesApi.listStudyOrders({
      patientId: patientID,
    })
    if (apiPatient) {
      setHeaderList([
        { fontWeight: 500, fontSize: 12, text: "Patients" },
        { fontWeight: 500, fontSize: 12, text: apiPatient.fullName },
      ])
      setPatient(apiPatient)
      setActiveStudy(apiPatient.activeStudyOrder)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const fetchStudyData = async () => {
      if (patientID === undefined) return

      const apiPatient = await patientsApi.getPatient(patientID)

      if (apiPatient && studiesState) {
        setTableRows(
          studiesState
            .filter(
              (studyOrder) =>
                studyOrder.uuid !== apiPatient.activeStudyOrder?.uuid
            )
            .map((studyOrder) => {
              return {
                id: studyOrder.uuid,
                readableId: studyOrder.id,
                date: formatDate(studyOrder.scheduledDate),
                orderingPhysician: studyOrder.orderingPhysician.name,
                interpretingPhysician: studyOrder.interpretingPhysician.name,
                device: studyOrder.assignedDevice,
                status: statusStrToEnum[studyOrder.status],
                activeStudyId: studyOrder.activeStudyId,
                studies: studyOrder.studies,
              }
            })
        )
      }
    }

    fetchStudyData()
  }, [studiesState])

  useEffect(() => {
    // Fetch data when modals close
    if (!openEditPatientModal && !openEditStudyModal && !openNewStudyModal) {
      fetchData()
    }
  }, [
    api,
    patientID,
    openEditPatientModal,
    openEditStudyModal,
    openNewStudyModal,
  ])

  useEffect(() => {
    if (activeStudy) {
      setStatus(statusStrToEnum[activeStudy.status])
    }
  }, [activeStudy])

  useEffect(() => {
    return () => {
      patientsApi.resetPatientData()
    }
  }, [])

  const downloadSleepReport = (studyId: string) => {
    studiesApi.downloadSleepStudy({ studyId: studyId || "" })
  }

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID", 0),
    createBasicGridColDef("readableId", "Study #", 8),
    {
      field: "date",
      headerName: "Study Date",
      flex: 8,
      editable: false,
      sortComparator: (v1: string, v2: string) => dateSortComparator(v1, v2),
    },
    createBasicGridColDef("device", "Device", 8),
    createBasicGridColDef("orderingPhysician", "Ordering Physician", 12),
    createBasicGridColDef(
      "interpretingPhysician",
      "Interpreting Physician",
      12
    ),
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 10,
      editable: false,
      valueOptions: studyStatusValue,
      renderCell: (params) =>
        // Need to change this to include other status for admins
        StudyStatusIndicator(params.row.status as StudyStatus),
    },
    {
      field: "report",
      headerName: "Report",
      flex: 6,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const { status, activeStudyId } = params.row

        if (status === StudyStatus.COMPLETE) {
          return (
            <PictureAsPdfOutlined
              onClick={() => downloadSleepReport(activeStudyId)}
            />
          )
        }

        return <React.Fragment />
      },
    },
  ]

  const theme = extendTheme({
    components: {
      JoyStack: {
        defaultProps: {
          minWidth: { xs: 0, md: "inherit" },
        },
      },
      JoyTypography: {
        defaultProps: {
          overflow: { xs: "hidden" },
          textOverflow: { xs: "ellipsis" },
        },
      },
    },
  })

  function getPatientData(): EditModalPatientData {
    return {
      ...patient,
      uuid: patient.uuid || "",
      organizationId: patient.organization,
    }
  }

  function getSelectedPatient(): PatientIdentifier {
    return {
      uuid: patient.uuid || "",
      name: patient.fullName,
    }
  }

  function getActiveStudyData() {
    if (!activeStudy) return emptyStudyInput

    return {
      ...activeStudy,
      uuid: activeStudy.uuid || "",
      patientId: patient.uuid || "",
      address2: activeStudy.address2 || "",
      orderingPhysicianId: activeStudy.orderingPhysician.uuid,
      interpretingPhysicianId: activeStudy.interpretingPhysician.uuid,
      assignedDeviceSerialNumber: activeStudy.assignedDevice,
      notes: activeStudy.notes || "",
    }
  }

  async function cancelStudy() {
    if (activeStudy && activeStudy.uuid) {
      await studiesApi.deleteStudy(activeStudy.uuid)
      await fetchData()
    }
  }

  return (
    <>
      <EditPatientModal
        open={openEditPatientModal}
        setOpen={setOpenEditPatientModal}
        patientData={getPatientData()}
      />
      <EditStudyModal
        open={openEditStudyModal}
        setOpen={setOpenEditStudyModal}
        patientData={getSelectedPatient()}
        studyData={getActiveStudyData()}
      />
      <PatientDetailNewStudyModal
        open={openNewStudyModal}
        setOpen={setOpenNewStudyModal}
        patientData={getSelectedPatient()}
      />
      <Stack spacing={3} divider={<Divider />}>
        <CssVarsProvider theme={theme}>
          <Grid
            container
            spacing={2}
            padding="10px 0"
            sx={{
              "& > *": {
                alignSelf: "center",
                paddingLeft: "20px !important",
              },
              "& > *:not(:first-of-type)": {
                alignSelf: "start",
                borderLeft: "1px solid #E0E0E0",
              },
              overflowWrap: { md: "anywhere" },
            }}
          >
            <Grid xs>
              <Stack direction="row" alignItems="center">
                <Box display={{ xs: "none", md: "inherit" }}>
                  <Avatar
                    variant="soft"
                    sx={{ marginRight: 4, "--Avatar-size": "50px" }}
                  >
                    <MedicalInformationOutlined fontSize="large" />
                  </Avatar>
                </Box>
                <Stack>
                  <Typography level="h3">{patient.fullName}</Typography>
                  <Typography>
                    <b>MRN</b>&nbsp;&nbsp;
                    {patient.mrn}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs>
              <Stack>
                <Typography textTransform="uppercase" level="body-xs">
                  Bio info
                </Typography>
                <Typography>
                  <b>Date of Birth</b>&nbsp;&nbsp;
                  {formatDate(patient.dob)}
                </Typography>
                <Typography>
                  <b>Race</b>&nbsp;&nbsp;
                  {patientRaceToString[patient.race]}
                </Typography>
                <Typography>
                  <b>Sex</b>&nbsp;&nbsp;
                  {patient.sex}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs>
              <Stack>
                <Typography textTransform="uppercase" level="body-xs">
                  Contact
                </Typography>
                <Typography>
                  <b>Phone</b>&nbsp;&nbsp;
                  {formatPhoneNumber(patient.primaryPhone as Value)}
                </Typography>
                <Typography>
                  <b>Email</b>&nbsp;&nbsp;
                  {patient.email}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack>
                  <Typography textTransform="uppercase" level="body-xs">
                    Referring physician
                  </Typography>
                  <Typography>
                    <b>Name</b>&nbsp;&nbsp;
                    {patient.referringPhysicianFirstName}{" "}
                    {patient.referringPhysicianLastName}
                  </Typography>
                  <Typography>
                    <b>Phone</b>&nbsp;&nbsp;
                    {patient.referringPhysicianPhone &&
                      formatPhoneNumber(
                        patient.referringPhysicianPhone as Value
                      )}
                  </Typography>
                </Stack>
                <IconButton
                  onClick={() => setOpenEditPatientModal(true)}
                  variant="soft"
                  color="primary"
                  sx={{ alignSelf: "flex-start" }}
                >
                  <Edit />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </CssVarsProvider>
        {activeStudy && (
          <Stack spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={2}>
                <b>Current Study: {activeStudy.id}</b>
                <Chip
                  color={
                    getStudyStatusColorAndText(
                      isAdmin ? status : simplifyStudyStatus(status)
                    ).color
                  }
                  sx={{
                    borderRadius: "4px",
                  }}
                  variant="outlined"
                >
                  {isAdmin ? status : simplifyStudyStatus(status)}
                </Chip>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={status !== StudyStatus.ORDERED}
                  size="sm"
                  onClick={cancelStudy}
                >
                  Cancel Study
                </Button>
                <Button
                  disabled={!readyStatuses.includes(status)}
                  size="sm"
                  endDecorator={<ArrowForward fontSize="small" />}
                  onClick={() => {
                    nav("/dataset/" + activeStudy?.study?.uuid)
                  }}
                >
                  View Study
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              border="1px solid #E0E0E0"
              divider={<Divider orientation="vertical" />}
              padding={4}
              spacing={4}
              alignItems="center"
            >
              <Stack
                direction="row"
                minWidth={{ xs: "200px", md: "400px" }}
                justifyContent="space-between"
                spacing={2}
              >
                <Stack spacing={2}>
                  <Stack>
                    <Typography textTransform="uppercase" level="body-xs">
                      Study details
                    </Typography>
                    <Typography>
                      <b>Study Date</b>&nbsp;&nbsp;
                      {formatDate(activeStudy.scheduledDate)}
                    </Typography>
                    <Typography>
                      <b>Device</b>&nbsp;&nbsp;
                      {activeStudy.assignedDevice
                        ? `SANSA (SN: ${activeStudy.assignedDevice})`
                        : "No device assigned"}
                    </Typography>
                    <Typography>
                      <b>Shipping Address</b>
                      <br />
                      {activeStudy.address1}, {activeStudy.city},{" "}
                      {activeStudy.state} {activeStudy.zip}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography textTransform="uppercase" level="body-xs">
                      Study physician
                    </Typography>
                    <Typography>
                      <b>Ordering Physician</b>&nbsp;&nbsp;
                      {activeStudy.orderingPhysician.name}
                    </Typography>
                    <Typography>
                      <b>Interpreting Physician</b>&nbsp;&nbsp;
                      {activeStudy.interpretingPhysician.name}
                    </Typography>
                  </Stack>
                </Stack>
                <IconButton
                  onClick={() => setOpenEditStudyModal(true)}
                  variant="soft"
                  color="primary"
                  sx={{ alignSelf: "flex-start" }}
                >
                  <Edit />
                </IconButton>
              </Stack>
              <Box minWidth="200px" width="100%" overflow="scroll">
                <StudyStatusStepper
                  currentStatus={activeStudy.status}
                  statusHistory={activeStudy.statusHistory}
                />
              </Box>
            </Stack>
          </Stack>
        )}
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2}>
              <b>Previous Studies</b>
              {!!activeStudy || (
                <Chip
                  color="neutral"
                  sx={{
                    borderRadius: "4px",
                  }}
                  variant="outlined"
                >
                  No Active Study
                </Chip>
              )}
            </Stack>
            <Button
              disabled={!!activeStudy}
              size="sm"
              startDecorator={<Add />}
              onClick={() => setOpenNewStudyModal(true)}
            >
              New Study
            </Button>
            <PatientDetailNewStudyModal
              open={openNewStudyModal}
              setOpen={setOpenNewStudyModal}
              patientData={getSelectedPatient()}
            />
          </Stack>
          <StyledDataGrid
            columns={columns}
            rows={tableRows}
            autoHeight
            columnHeaderHeight={48}
            initialState={{
              columns: { columnVisibilityModel: { id: false } },
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10]}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default PatientDetail
