import { useState } from "react"

import { DialogTitle, Modal, ModalClose, ModalDialog, Stack } from "@mui/joy"

import StudyOrderForm from "../study/newstudymodal/StudyOrderForm"
import { PatientDetailNewStudyModalProps } from "../../interfaces/modal.interface"

function PatientDetailNewStudyModal({
  open,
  setOpen,
  patientData,
}: PatientDetailNewStudyModalProps) {
  const [page, setPage] = useState(2)

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog sx={{ maxWidth: 880 }}>
        <ModalClose />
        <Stack spacing={2}>
          <DialogTitle>Order New Study:</DialogTitle>
          <StudyOrderForm
            setOpen={setOpen}
            page={page}
            setPage={setPage}
            patientData={patientData}
          />
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default PatientDetailNewStudyModal
