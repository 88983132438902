import { Box, Button, Typography } from "@mui/joy"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const NotFoundView = () => {
  const [counter, setCounter] = useState(5)
  const navigate = useNavigate()

  useEffect(() => {
    const countdown = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1)
      } else {
        // Navigate to the home page when the countdown is over
        navigate("/")
      }
    }, 1000)

    return () => {
      clearInterval(countdown)
    }
  }, [counter, navigate])

  return (
    <Box
      sx={{
        textAlign: "center",
        marginTop: "20vh",
      }}
    >
      <Typography level="h3">404 - Not Found</Typography>
      <Typography>
        The page you are looking for does not exist. You will be navigated back
        to the home page in:
      </Typography>
      <Typography level="h4">{counter} seconds</Typography>
      <Button color="primary" onClick={() => navigate("/")}>
        Go back to the home page
      </Button>
    </Box>
  )
}

export default NotFoundView
