import "./styles.css"
import { useEditor, EditorContent } from "@tiptap/react"
import Bold from "@tiptap/extension-bold"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Underline from "@tiptap/extension-underline"
import Italic from "@tiptap/extension-italic"
import OrderedList from "@tiptap/extension-ordered-list"
import ListItem from "@tiptap/extension-list-item"
import BulletList from "@tiptap/extension-bullet-list"
import Text from "@tiptap/extension-text"
import { AnyExtension, JSONContent } from "@tiptap/core"
import { useEffect, useMemo } from "react"
import EditorMenu from "../editormenu/EditorMenu"
import { tipTapContentStatus } from "../../../state/tipTap.state"
import { generateHTMLFromJsonContent } from "../../../utils/tipTapUtils"
import { useSetRecoilState } from "recoil"

interface TipTapEditorProps {
  content: JSONContent
  extension: AnyExtension[]
  editable?: boolean
  menuStatus?: boolean
  onContentChange: (contentData: JSONContent) => void
}

const TipTap = ({
  content,
  extension,
  editable = true,
  menuStatus = true,
  onContentChange,
}: TipTapEditorProps) => {
  const editorFormatExtensions = [
    Bold,
    Document,
    Paragraph,
    Italic,
    OrderedList,
    ListItem,
    BulletList,
    Text,
    Underline,
  ]
  const extensions = [...extension, ...editorFormatExtensions]
  const setIsEmptyTipTapContent = useSetRecoilState(tipTapContentStatus)
  const output = useMemo(() => {
    return generateHTMLFromJsonContent(content as JSONContent, [])
  }, [content])
  const editor = useEditor({
    extensions: extensions,
    content: output as string,
    onUpdate: ({ editor }) => {
      onContentChange(editor.getJSON() as JSONContent)
      editor.isEmpty
        ? setIsEmptyTipTapContent(true)
        : setIsEmptyTipTapContent(false)
    },
  })
  useEffect(() => {
    if (editor === undefined || editor === null) return

    editor.commands.setContent(content)
  }, [content, editor])

  useEffect(() => {
    if (editor === undefined || editor === null) return

    if (!editable) editor.setEditable(false)

    return () => {
      if (editor) {
        editor.destroy()
      }
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <>
      {menuStatus && <EditorMenu editor={editor} />}

      <div className="tiptapeditor">
        <EditorContent editor={editor} />
      </div>
    </>
  )
}

export default TipTap
