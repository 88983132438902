/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbortUploadIn
 */
export interface AbortUploadIn {
    /**
     * 
     * @type {string}
     * @memberof AbortUploadIn
     */
    objectKey: string;
    /**
     * 
     * @type {string}
     * @memberof AbortUploadIn
     */
    uploadId: string;
}

/**
 * Check if a given object implements the AbortUploadIn interface.
 */
export function instanceOfAbortUploadIn(value: object): value is AbortUploadIn {
    if (!('objectKey' in value) || value['objectKey'] === undefined) return false;
    if (!('uploadId' in value) || value['uploadId'] === undefined) return false;
    return true;
}

export function AbortUploadInFromJSON(json: any): AbortUploadIn {
    return AbortUploadInFromJSONTyped(json, false);
}

export function AbortUploadInFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbortUploadIn {
    if (json == null) {
        return json;
    }
    return {
        
        'objectKey': json['object_key'],
        'uploadId': json['upload_id'],
    };
}

export function AbortUploadInToJSON(value?: AbortUploadIn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_key': value['objectKey'],
        'upload_id': value['uploadId'],
    };
}

