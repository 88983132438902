import Input from "@mui/joy/Input/Input"
import GridFormInput from "../grid/GridFormInput"
import Textarea from "@mui/joy/Textarea/Textarea"
import { OrganizationLocationOut } from "../../../generated/fetchclient/models/OrganizationLocationOut"
import { states } from "../../../utils/studyUtils"
import Select from "@mui/joy/Select"
import Option from "@mui/joy/Option"
import Box from "@mui/joy/Box"
import Stack from "@mui/joy/Stack"
import IconButton from "@mui/joy/IconButton"
import Grid from "@mui/joy/Grid"
import Typography from "@mui/joy/Typography"
import Divider from "@mui/joy/Divider"
import { OrganizationLocationIn } from "../../../generated/fetchclient"
import PhoneInput, { Value } from "react-phone-number-input/input"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import { inputOrganizationErrors } from "../../../interfaces/organization.interface"

type AddressDataParams = {
  addressData: OrganizationLocationOut
  index: number
  removeLocation: (index: number) => void
  handleLocationChange: (
    index: number,
    property: keyof OrganizationLocationIn,
    value: string | boolean
  ) => void
  inputOrganizationErrors: inputOrganizationErrors
  addLocation: () => void
}

const Address = ({
  index,
  addressData,
  addLocation,
  handleLocationChange,
  removeLocation,
  inputOrganizationErrors,
}: AddressDataParams) => {
  const statusList = [
    {
      name: "Active",
      value: true,
    },
    {
      name: "In-Active",
      value: false,
    },
  ]
  const primaryStatusList = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ]
  return (
    <>
      <Grid xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography level="title-sm">{`Location ${index + 1}`}</Typography>
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="Add Location"
              variant="plain"
              color="primary"
              size="md"
              onClick={() => addLocation()}
            >
              <AddIcon />
            </IconButton>
            {index > 0 && (
              <IconButton
                aria-label="Delete Location"
                variant="plain"
                color="danger"
                size="md"
                onClick={() => removeLocation(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
        <Divider />
      </Grid>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.address1 || ""}
        width={6}
        label="Address 1"
      >
        <Input
          value={addressData.address1}
          onChange={(e) =>
            handleLocationChange(index, "address1", e.target.value)
          }
          required
        />
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.address2 || ""}
        width={6}
        label="Address 2"
      >
        <Input
          value={addressData.address2}
          onChange={(e) =>
            handleLocationChange(index, "address2", e.target.value)
          }
        />
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.phone || ""}
        width={4}
        label="Phone"
      >
        <PhoneInput
          className={"phone-input "}
          country="US"
          value={addressData.phone as Value}
          onChange={(value: Value) =>
            handleLocationChange(index, "phone", value)
          }
          required
        />
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.isPrimary || ""}
        width={4}
        label="Primary"
      >
        <Select
          onChange={(_, value) => {
            handleLocationChange(index, "isPrimary", value as boolean)
          }}
          defaultValue={addressData.isPrimary}
          required
        >
          {statusList.map((statusData, index) => (
            <Option key={index} value={statusData.value}>
              {statusData.name}
            </Option>
          ))}
        </Select>
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.enabled || ""}
        width={4}
        label="Active"
      >
        <Select
          onChange={(_, value) => {
            handleLocationChange(index, "enabled", value as boolean)
          }}
          defaultValue={addressData.enabled}
          required
        >
          {primaryStatusList.map((statusData, index) => (
            <Option key={index} value={statusData.value}>
              {statusData.name}
            </Option>
          ))}
        </Select>
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.city || ""}
        width={4}
        label="City"
      >
        <Input
          value={addressData.city}
          onChange={(e) => handleLocationChange(index, "city", e.target.value)}
          required
        />
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.zip || ""}
        width={4}
        label="Zip"
      >
        <Input
          value={addressData.zip}
          onChange={(e) => handleLocationChange(index, "zip", e.target.value)}
          required
        />
      </GridFormInput>
      <GridFormInput
        error={inputOrganizationErrors.locations[index]?.state || ""}
        width={4}
        label="State"
      >
        <Select
          value={addressData.state}
          onChange={(_, value) => {
            if (value) {
              handleLocationChange(index, "state", value)
            }
          }}
          placeholder="Select"
          required
        >
          {states.map((state, index) => (
            <Option key={index} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </GridFormInput>
      <GridFormInput width={12} label="Description">
        <Textarea
          value={addressData.description}
          onChange={(e) =>
            handleLocationChange(index, "description", e.target.value)
          }
          minRows={3}
          sx={{ mb: 1 }}
        />
      </GridFormInput>
    </>
  )
}

export default Address
