import { Button as MuiButton, ButtonProps, styled } from "@mui/material"
import { styleIf } from "../../utils"

const StyledButton = styled(MuiButton)<ButtonProps>(
  ({ color, size, variant }) => ({
    fontFamily: "Inter",
    fontWeight: 600,
    boxShadow: "none",
    fontSize: "13px",
    ...styleIf(size === "small", {
      fontSize: "12px",
    }),
    ...styleIf(color === undefined, {
      color: "#FFF",
    }),
    ...styleIf(variant === "outlined", {
      color: "#5A97F7",
      borderColor: "#5A97F7",
    }),
  })
)

export default function Button(props: ButtonProps) {
  return <StyledButton variant="contained" {...props} />
}
