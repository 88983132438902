import { epochMs } from "../constants"
import { allPlotTimeDomain } from "../state/signals.state"
import { atom, selector } from "recoil"

/**
 * currentEpoch - used to designate the starting epoch index
 * (pagination/scrolling) to render in stacked plots
 */
export const currentEpoch = atom<number>({
  key: "currentEpoch",
  default: 0,
})

/**
 * numEpochs - returns the number of epochs given the allPlotTimeDomain
 */
export const numEpochs = selector<number>({
  key: "numEpochs",
  get: ({ get }) => {
    const [minX, maxX] = get(allPlotTimeDomain)
    const numMs = maxX - minX
    return Math.ceil(numMs / (epochMs / 2))
  },
})
