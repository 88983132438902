import { SxProps } from "@mui/material"
import Decimal from "decimal.js"
import { Signal } from "interfaces"
import { Point, TimeSeriesPoint } from "./types"

/**
 * relMouseCoords is used to calculate the coordinates of a mouse click
 * relative to a given element.
 *
 * also see: import { localPoint } from "@visx/event"
 *
 * @param {MouseEvent | React.MouseEvent<Element, MouseEvent>} e - MouseEvent
 * @param {Element} el - Element used to compute relative coords
 * @returns {Point} point - Relative mouse coords
 */
export const relMouseCoords = (
  e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
  el: Element
): Point => {
  const bounds = el.getBoundingClientRect()

  // e.pageX & Y should always be populated, but
  // userEvent.pointer isn't applying values in test
  const x = new Decimal(e.pageX ?? 0).minus(bounds.left).toNumber()
  const y = new Decimal(e.pageY ?? 0).minus(bounds.top).toNumber()

  return { x, y }
}

// https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "")

  if (hex.trim() === "") {
    return "rgba(0,0,0,0)"
  }

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  let opa = opacity

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opa /= 100
  }

  return `rgba(${r},${g},${b},${opa})`
}

/**
 * padDomain returns the given domain with padding added using a
 * percentage of the domain.
 *
 * @param {number[]} domain
 * @param {number} percentage
 * @returns {number[]} paddedDomain
 */
export const padDomain = (
  domain: number[],
  percentage: number = 15
): number[] => {
  const [min, max] = domain

  if (percentage < 0) {
    return domain
  }

  let minD = new Decimal(min)
  let maxD = new Decimal(max)
  const percentD = new Decimal(percentage)

  const padding = maxD.minus(minD).times(percentD.dividedBy(100))

  minD = minD.minus(padding)
  maxD = maxD.plus(padding)

  return [minD.toNumber(), maxD.toNumber()]
}

export type GetAlignedTicksIntervalParams = {
  minTick: number
  maxTick: number
  tickInterval: number
}

/**
 * getAlignedTicksInterval returns the list of tick x or y coordinates based
 * on the tickInterval specified.
 *
 * @param {GetAlignedTicksIntervalParams} getAlignedTicksIntervalParams
 * @returns {number[]} alignedTicks
 */
export const getAlignedTicksInterval = ({
  minTick,
  maxTick,
  tickInterval,
}: GetAlignedTicksIntervalParams): number[] => {
  const tickValues: number[] = []

  if (tickInterval < 0) {
    return []
  }

  // return single tick of min and max if they're equal
  if (maxTick <= minTick) {
    return [maxTick]
  }

  const maxTickD = new Decimal(maxTick)

  let currentTick = new Decimal(minTick)
  while (currentTick.lessThanOrEqualTo(maxTickD)) {
    tickValues.push(currentTick.toNumber())
    currentTick = currentTick.plus(tickInterval)
  }

  return tickValues
}

export type GetAlignedTicksParams = {
  minTick: number
  maxTick: number
  numTicks: number
}

/**
 * getAlignedTicks returns the list of tick x or y coordinates based
 * on the numTicks specified and basisNumTicks if set.
 *
 * basisNumTicks is used to align smaller numTicks to a larger numTicks.
 *
 * @param {GetAlignedTicksParams} getAlignedTicksParams
 * @returns {number[]} alignedTicks
 */
export const getAlignedTicks = ({
  minTick,
  maxTick,
  numTicks,
}: GetAlignedTicksParams): number[] => {
  const tickValues: number[] = []

  if (numTicks <= 0) {
    return []
  }

  const minTickD = new Decimal(minTick)
  const maxTickD = new Decimal(maxTick)
  const numTicksF = Math.floor(numTicks)

  // return single tick of min and max if they're equal
  if (maxTick === minTick) {
    return [maxTick]
  }

  const tickInterval = maxTickD.minus(minTickD).dividedBy(numTicksF - 1)

  let currentTick = minTickD

  for (let n = 0; n < numTicksF; n += 1) {
    tickValues.push(currentTick.toNumber())
    currentTick = currentTick.plus(tickInterval)
  }

  return tickValues
}

export const getX = (d: TimeSeriesPoint) => d.date.getTime()
export const getY = (d: TimeSeriesPoint) => d.value

const googleDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{6})Z$/

// helper for mock data only
export function parseGoogleDate(d: string) {
  const m = googleDate.exec(d)

  if (m === null) return new Date(0)

  const year = +m[1]
  const month = +m[2]
  const day = +m[3]
  const hour = +m[4]
  const minute = +m[5]
  const second = +m[6]
  const msec = +m[7]

  return new Date(
    Date.UTC(year, month - 1, day, hour, minute, second, msec / 1000)
  )
}

export const styleIf = <T = SxProps,>(
  condition: boolean | undefined,
  styles: T
): T | {} => {
  return condition ? styles : {}
}

export const checkSignalLength = (signal: Signal) => {
  return (
    signal.timestamps.length > 0 &&
    signal.values.length > 0 &&
    signal.timestamps.length === signal.values.length
  )
}
