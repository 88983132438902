import { styled } from "@mui/joy/styles"
import { DataGrid } from "@mui/x-data-grid"

export const StyledDataGrid = styled(DataGrid)({
  m: 0,
  "& .disabled-user": {
    opacity: 0.5,
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "var(--joy-palette-neutral-100)",
    color: "var(--joy-palette-text-primary)",
    fontWeight: "bold",
    // Forced to use important since overriding inline styles
    maxHeight: "168px !important",
  },
  "& .MuiDataGrid-columnHeader": {
    // Forced to use important since overriding inline styles
    height: "unset !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
      outline: "none !important",
    },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-root .MuiDataGrid-cell": {
    whiteSpace: "normal !important",
    overflow: "auto",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-overlayWrapper": {
    minHeight: "40px",
  },
})
