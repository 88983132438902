import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy"
import "react-phone-number-input/style.css"
import { useRecoilState, useRecoilValue } from "recoil"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import useStudies from "../../hooks/useStudies"
import { deleteStudyModalOpen, studyToBeDeleted } from "../../state/modal.state"

function DeleteStudyModal() {
  const studyApi = useStudies()
  const [modalOpen, setModalOpenState] = useRecoilState(deleteStudyModalOpen)
  const studyId = useRecoilValue(studyToBeDeleted)

  const DeleteStudyFromAPI = async () => {
    if (studyId === undefined) {
      console.error("studyID is undefined") //TODO remove when we implement sentry
      return
    }

    await studyApi.deleteStudy(studyId)
    setModalOpenState(false)
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={modalOpen}
      onClose={() => {
        setModalOpenState(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to remove the study?
        </DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            color="danger"
            onClick={() => DeleteStudyFromAPI()}
          >
            Delete Study
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => setModalOpenState(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default DeleteStudyModal
