/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InterpretationTemplateOut } from './InterpretationTemplateOut';
import {
    InterpretationTemplateOutFromJSON,
    InterpretationTemplateOutFromJSONTyped,
    InterpretationTemplateOutToJSON,
} from './InterpretationTemplateOut';

/**
 * 
 * @export
 * @interface PagedInterpretationTemplateOut
 */
export interface PagedInterpretationTemplateOut {
    /**
     * 
     * @type {Array<InterpretationTemplateOut>}
     * @memberof PagedInterpretationTemplateOut
     */
    items: Array<InterpretationTemplateOut>;
    /**
     * 
     * @type {number}
     * @memberof PagedInterpretationTemplateOut
     */
    count: number;
}

/**
 * Check if a given object implements the PagedInterpretationTemplateOut interface.
 */
export function instanceOfPagedInterpretationTemplateOut(value: object): value is PagedInterpretationTemplateOut {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function PagedInterpretationTemplateOutFromJSON(json: any): PagedInterpretationTemplateOut {
    return PagedInterpretationTemplateOutFromJSONTyped(json, false);
}

export function PagedInterpretationTemplateOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedInterpretationTemplateOut {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(InterpretationTemplateOutFromJSON)),
        'count': json['count'],
    };
}

export function PagedInterpretationTemplateOutToJSON(value?: PagedInterpretationTemplateOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(InterpretationTemplateOutToJSON)),
        'count': value['count'],
    };
}

