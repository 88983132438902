import { DialogTitle, Modal, ModalClose, ModalDialog, Stack } from "@mui/joy"
import StudyOrderForm from "./newstudymodal/StudyOrderForm"
import { useState } from "react"
import { EditStudyModalProps } from "../../interfaces/modal.interface"

function EditStudyModal({
  open,
  setOpen,
  patientData,
  studyData,
}: EditStudyModalProps) {
  const [page, setPage] = useState(2)
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => {
        setPage(2)
        setOpen(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog sx={{ maxWidth: 880, overflowY: "auto" }}>
        <ModalClose />
        <Stack spacing={2}>
          <DialogTitle>Edit Study:</DialogTitle>
          <StudyOrderForm
            setOpen={setOpen}
            page={page}
            setPage={setPage}
            patientData={patientData}
            studyData={studyData}
          />
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

export default EditStudyModal
