import React from "react"
import { useRecoilValue } from "recoil"
import { uploadedSignatureUrl } from "../../../state/signature.state"
import { formatDateTime } from "../../../utils/timeUtils"

const PdfClinicianSignature: React.FC = () => {
  const signatureUrl = useRecoilValue(uploadedSignatureUrl)
  return (
    <div className="sig-block">
      <div className="sig-block-text">
        <span
          className="signature-placeholder"
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          Signature:_________________________
          {signatureUrl ? (
            <span
              style={{
                position: "absolute",
                left: "120px",
                top: "-30px",
                width: "200px",
                height: "50px",
                backgroundImage: `url(${signatureUrl})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                zIndex: 1000,
              }}
            ></span>
          ) : (
            ""
          )}
        </span>
        <span
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <span
            style={{
              position: "absolute",
              left: "50px",
              top: "-10px",
              width: "150px",
              height: "50px",
              padding: "0 5px",
            }}
          >
            {formatDateTime(new Date(), "date")}
          </span>
          Date:____________________
        </span>
      </div>
    </div>
  )
}

export default PdfClinicianSignature
