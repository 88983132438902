export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
  timeZone: "UTC",
}

export const formatDate = (date: Date) => {
  return date.toLocaleDateString("en-US", dateFormatOptions)
}

export const parseTableDate = (dateStr: string): number => {
  const [month, day, year] = dateStr.split("/").map(Number)
  return new Date(year, month - 1, day).getTime()
}

export const dateSortComparator = (v1: string, v2: string): number => {
  return parseTableDate(v1) - parseTableDate(v2)
}
