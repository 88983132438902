import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"
import { UserOut } from "../generated/fetchclient"

export const JWT = atom<string | undefined>({
  key: "JWT",
  default: undefined,
})

export const isHuxleyUser = atom<boolean | undefined>({
  key: "isHuxleyUser",
  default: undefined,
})

export const currentUser = atom<UserOut | undefined>({
  key: "currentUser",
  default: undefined,
})

export const sessionPersist = recoilPersist({
  key: "portal-ui-session",
  storage: sessionStorage,
})

export const postLoginRedirect = atom<string | undefined>({
  key: "postLoginRedirect",
  default: undefined,
  effects_UNSTABLE: [sessionPersist.persistAtom],
})
