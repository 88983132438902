import { UserOut } from "../generated/fetchclient"
import { atom } from "recoil"

export const users = atom<UserOut[]>({
  key: "users",
  default: [],
})

export const userCount = atom<number>({
  key: "userCount",
  default: 0,
})
