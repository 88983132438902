import { Group } from "@visx/group"
import { Circle, Line } from "@visx/shape"
import { bisector } from "d3-array"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import React from "react"
import { Span } from "@huxley-medical/react-components/elements"
import {
  PlotHoverTooltips,
  usePlotHoverTooltipsParams,
} from "../../../types/tooltip"
import { StyledOverlay } from "../../../styled/Bar"
import { dateToFormattedTimeString } from "../../../utils/timeUtils"
import { Signal } from "../../../interfaces/signals.interface"

/**
 * usePlotHoverTooltips is a hook used to build a tooltip component for a plot.
 * It returns { updateTooltip, hideTooltip, ... } event handlers so the rendering of
 * tooltip components can rely on mouse events from external parent component
 *
 * @param {usePlotHoverTooltipsParams} usePlotHoverTooltipsParams
 * @returns {PlotHoverTooltips}
 */
const usePlotHoverTooltips = ({
  timeScale,
  data,
  unit,
  height,
  width,
  yTickFormat,
  yScale,
}: usePlotHoverTooltipsParams): PlotHoverTooltips => {
  const bisectTime = bisector<number, number>((d) => d).left

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<Signal>()

  const { containerRef, TooltipInPortal } = useTooltipInPortal()

  const handleMouseOver = (event: React.MouseEvent<SVGPathElement>) => {
    const coords = localPoint(event.currentTarget, event)

    const x = coords?.x ?? 0
    const y = coords?.y ?? 0

    const index = bisectTime(data.timestamps, timeScale.invert(x), 1)
    showTooltip({
      tooltipLeft: x,
      tooltipTop: y, // yScale(data[index].value) - 40,
      tooltipData: {
        timestamps: [data.timestamps[index]],
        values: [data.values[index]],
      },
    })
  }

  const tooltipY = tooltipData?.values[0] ?? 0
  const cursorX = timeScale(tooltipData?.timestamps[0] ?? 0)

  return {
    hideTooltip: hideTooltip,
    updateTooltip: handleMouseOver,
    PlotHoverTooltips: () => (
      <Group>
        <Line
          from={{ x: cursorX, y: 0 }}
          to={{ x: cursorX, y: height }}
          stroke={"#000"}
          strokeDasharray={2}
          pointerEvents="none"
        />
        <Circle
          cx={cursorX}
          cy={yScale(tooltipY)}
          r={3}
          fill={"#FFF"}
          stroke={"#000"}
          pointerEvents="none"
        />

        {tooltipOpen && (
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <strong>
              {yTickFormat
                ? yTickFormat(tooltipY, 0, [
                    {
                      value: tooltipY,
                      index: 0,
                    },
                  ])
                : tooltipData?.values[0].toFixed(2)}
            </strong>{" "}
            {unit}
            <br />
            <Span sx={{ fontSize: "10px" }}>
              {tooltipData?.timestamps[0]
                ? dateToFormattedTimeString(
                    new Date(tooltipData?.timestamps[0] * 1000)
                  )
                : "invalid time"}
            </Span>
          </TooltipInPortal>
        )}

        <StyledOverlay
          x={0}
          y={0}
          height={height}
          width={width}
          innerRef={containerRef}
        />
      </Group>
    ),
  }
}

export default usePlotHoverTooltips
