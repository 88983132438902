import React from "react"

interface TotalBeatsAnalyzedProps {
  total_beats_analyzed: string
}
const TotalBeatsAnalyzed: React.FC<TotalBeatsAnalyzedProps> = ({
  total_beats_analyzed,
}) => {
  return (
    <table className="data-table top-border">
      <tbody>
        <tr>
          <td id="ox-desat-nadir-label" colSpan={3}>
            Total Beats Analyzed:
          </td>
          <td colSpan={1}>{total_beats_analyzed}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default TotalBeatsAnalyzed
