import React from "react"
import HuxleyGlyph from "../../assets/HUXLEY_GLYPH.svg"

const HuxleyLogo: React.FC = () => {
  return (
    <>
      <img alt="Huxley Logo" src={HuxleyGlyph} width="40px" height="40px" />
    </>
  )
}

export default HuxleyLogo
