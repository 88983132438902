import React from "react"
import huxleySmall from "../HUXLEY_CENTERED_Small.png"

interface PdfTemplateProps {
  study_id: number | undefined
  sw_version: string | undefined
  children: React.ReactNode
}

const PdfTemplate: React.FC<PdfTemplateProps> = ({
  study_id,
  sw_version,
  children,
}) => {
  return (
    <div className="report-page">
      <div id="header">
        <div className="huxley-head-logo">
          <img alt="Huxley Head Logo" src={huxleySmall} />
        </div>
        <div className="title">
          <div className="section-header-title">SANSA Sleep Report</div>
          <p className="study-id">Study ID: {study_id}</p>
        </div>
        <div className="address">
          <div className="huxley-info">
            Huxley Medical, Inc.
            <br />
            1465 Northside Dr NW,
            <br />
            Suite 217 Atlanta, GA 30318
            <br />
            (888) 726-7239 |{" "}
            <a
              href="https://www.huxleymed.com/"
              rel="noreferrer"
              target="_blank"
            >
              www.huxleymed.com
            </a>
          </div>
        </div>
      </div>
      {children}
      <div className="footer-items">
        <div className="footer-left">&copy; Huxley Medical, Inc.</div>
        <div className="footer-right">
          <div className="footer-right-text">
            <span>SW Ver. {sw_version}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdfTemplate
