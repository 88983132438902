import { InputBase, InputBaseProps, styled } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: theme.palette.primary.background,
  marginLeft: 0,
  marginRight: "40px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    color: "#A6AAB5",
  },
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input:focus": {
    transition: "width 0.25s",
    width: "250px",
  },
  "& .MuiInputBase-input": {
    color: "#374957",
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "14px",
    fontFamily: "Inter",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "225px",
    },
  },
}))

export default function SearchBar({
  inputProps = { "aria-label": "search" },
  placeholder = "Search...",
  ...otherProps
}: InputBaseProps) {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeholder}
        inputProps={inputProps}
        {...otherProps}
      />
    </Search>
  )
}
