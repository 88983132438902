import { StepLinePlot } from "@huxley-medical/react-components/components"
import { ScaleLinear } from "d3-scale"
import { Signal } from "../../../interfaces/signals.interface"

interface GraphStepLinePlotProps {
  data: Signal
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  plotWidth: number
  lineColor: string
  lineHeight: number
}

const GraphStepLinePlot = ({
  data,
  timeScale,
  yScale,
  plotWidth,
  lineHeight,
  lineColor,
}: GraphStepLinePlotProps) => {
  return (
    <StepLinePlot
      data={data}
      stroke={lineColor}
      strokeWidth={1.5}
      width={plotWidth}
      height={lineHeight}
      xScale={timeScale}
      yScale={yScale}
    />
  )
}

export default GraphStepLinePlot
