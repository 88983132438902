import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined"
import { styled } from "@mui/system"

const HelpFloater = styled(HelpOutlinedIcon)({
  position: "fixed",
  right: "9px",
  bottom: "62px",
  color: "#5690EB",
  width: "40px",
  height: "40px",
  cursor: "pointer",
  backgroundColor: "#FFF",
  borderRadius: "40px",
  border: "3px solid",
})

export default HelpFloater
