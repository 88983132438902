import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import { SessionKeyOut } from "../generated/fetchclient"
import { useAuthenticator } from "@aws-amplify/ui-react"
import { currentUser, isHuxleyUser } from "../state/auth.state"
import { handleApiError } from "../utils/apiUtils"
import { useLDClient } from "launchdarkly-react-client-sdk"

/**
 * useBackendAuthenticator is a hook that authenticates users in the backend
 * and sets the session ID cookie for Huxley users to access Django Admin.
 */
export const useBackendAuthenticator = () => {
  const api = usePortalApi()
  const [cookies, setCookie] = useCookies()
  const setIsHuxleyUser = useSetRecoilState(isHuxleyUser)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const { route } = useAuthenticator((context) => [context.route])

  useEffect(() => {
    if (api === undefined) return

    if (cookies.sessionid) {
      setIsHuxleyUser(true)
    } else {
      api
        ?.logIn()
        .then((response: SessionKeyOut) => {
          const { sessionKey } = response
          if (sessionKey) {
            setCookie("sessionid", sessionKey, {
              sameSite:
                process.env.REACT_APP_RUNTIME_ENV === "prod" ? "strict" : "lax",
              secure: true,
            })
            setIsHuxleyUser(true)
          } else {
            setIsHuxleyUser(false)
          }
        })
        .catch((error) => {
          setIsHuxleyUser(false)
          handleApiError(setSnackAlertMsg)(error)
        })
    }
  }, [api, route])
}

export const useCurrentUser = () => {
  const api = usePortalApi()
  const setCurrentUser = useSetRecoilState(currentUser)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setIsHuxleyUser = useSetRecoilState(isHuxleyUser)
  const ldClient = useLDClient()

  useEffect(() => {
    if (api === undefined) return

    api
      ?.currentUser()
      .then((response) => {
        setCurrentUser(response)
        if (response.groups.includes("Physician")) {
          setIsHuxleyUser(false)
        }
        ldClient?.identify(
          {
            kind: "user",
            key: response.uuid,
            name: response.firstName + " " + response.lastName,
          },
          undefined,
          () => {
            console.log("New context's flags available")
          }
        )
      })
      .catch((error) => {
        setCurrentUser(undefined)
        handleApiError(setSnackAlertMsg)(error)
      })
  }, [api])
}
