import React, { useEffect, useState } from "react"
import QRCode from "react-qr-code"
import { Auth } from "aws-amplify"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../../components/SnackAlerts"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/joy"
import { handleApiError } from "../../utils/apiUtils"

async function handleTOTPSetup(): Promise<string | undefined> {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const code = await Auth.setupTOTP(user)
    // Open setupUri with an authenticator APP to retrieve an OTP code
    const uri =
      "otpauth://totp/HuxleyPortal:" +
      user.attributes.email +
      "?secret=" +
      code +
      "&issuer=HuxleyPortal"
    return uri // Return URI instead of code
  } catch (error) {
    throw error
  }
  return undefined
}

async function handleTOTPVerification(totpCode: string) {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.verifyTotpToken(user, totpCode)
  } catch (error) {
    throw error
  }
}

async function handleUpdateMFAPreference() {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.setPreferredMFA(user, "TOTP")
  } catch (error) {
    throw error
  }
}

export const AuthenticatorSetup = () => {
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const navigate = useNavigate()

  const [qrCode, setQRCode] = useState<string | undefined>(undefined)
  const [enteredCode, setEnteredCode] = useState<string>("")

  useEffect(() => {
    const setupTOTP = async () => {
      const uri = await handleTOTPSetup()
      if (uri) {
        setQRCode(uri)
      }
    }

    setupTOTP()
  }, [])

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredCode(event.target.value)
  }

  const handleSubmitCode = async () => {
    // Handle the entered code, you can implement verification logic here
    try {
      await handleTOTPVerification(enteredCode)
      await handleUpdateMFAPreference()
      navigate("/profile")
    } catch (error) {
      handleApiError(setSnackAlertMsg)(
        "Something went wrong while setting up MFA"
      )
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Multi-Factor Authentication Setup
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          Follow these steps to set up Multi-Factor Authentication:
        </Typography>

        {qrCode && (
          <>
            <Box sx={{ mt: 3 }}>
              <QRCode value={qrCode} />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="body2" color="textSecondary" align="center">
                1. Download an Authenticator app from your app store.
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                2. Open the Authenticator app and scan the QR code.
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                3. Enter the code generated by the Authenticator app below.
              </Typography>
            </Box>

            <Box component="form" sx={{ mt: 3 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="codeInput"
                label="Enter the code from your authenticator app"
                name="codeInput"
                autoFocus
                value={enteredCode}
                onChange={handleCodeChange}
              />
              <Button
                type="button"
                fullWidth
                onClick={handleSubmitCode}
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Code
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  )
}
