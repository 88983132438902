import { Check } from "@mui/icons-material"
import {
  Box,
  Step,
  StepIndicator,
  Stepper,
  Typography,
  stepClasses,
  stepIndicatorClasses,
} from "@mui/joy"
import { useEffect, useState } from "react"
import { StudyStatus, statusStrToEnum } from "../../utils/studyUtils"
import { useRecoilValue } from "recoil"
import { isHuxleyUser } from "../../state/auth.state"

interface StudyStatusStepperProps {
  currentStatus: string
  statusHistory: object
}

function StudyStatusStepper({
  currentStatus,
  statusHistory,
}: StudyStatusStepperProps) {
  const isAdmin = useRecoilValue(isHuxleyUser)
  const [statuses, setStatuses] = useState<StudyStatus[]>([])
  const steps = isAdmin
    ? [
        StudyStatus.ORDERED,
        StudyStatus.IN_PROGRESS,
        StudyStatus.AWAITING_UPLOAD,
        StudyStatus.UPLOADED,
        StudyStatus.PROCESSING,
        StudyStatus.PROCESSED,
        StudyStatus.READY_FOR_REVIEW,
        StudyStatus.READY_FOR_INTERPRETATION,
        StudyStatus.COMPLETE,
      ]
    : [
        StudyStatus.ORDERED,
        StudyStatus.IN_PROGRESS,
        StudyStatus.READY_FOR_REVIEW,
        StudyStatus.READY_FOR_INTERPRETATION,
        StudyStatus.COMPLETE,
      ]

  useEffect(() => {
    const uniqueStatuses = Array.from(new Set(Object.values(statusHistory)))
    setStatuses(uniqueStatuses.map((status) => statusStrToEnum[status]))
  }, [])

  return (
    <Stepper
      size="sm"
      sx={{
        minWidth: "800px",
        paddingTop: "40px",
        "--Step-connectorThickness": "4px",
        "--Step-connectorInset": "0px",
        "--Step-gap": "15px",
        "--StepIndicator-size": "18px",
        [`& .${stepClasses.completed}`]: {
          "&::after": {
            bgcolor: "primary.500",
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: "primary.500",
            borderWidth: "4px",
          },
          "&::after": {
            bgcolor: "primary.200",
          },
        },
        [`& .${stepClasses.disabled}`]: {
          color: "#888888",
          "& *": { borderWidth: "4px" },
          "&::after": {
            bgcolor: "primary.200",
          },
        },
      }}
    >
      {steps.map((step) => {
        const isActive = step === statusStrToEnum[currentStatus]
        const isCompleted = statuses.includes(step) && !isActive
        const isIncomplete = !isCompleted && !isActive

        return (
          <Step
            key={step}
            completed={isCompleted}
            active={isActive}
            disabled={isIncomplete}
            orientation="vertical"
            indicator={
              <StepIndicator
                variant={isCompleted ? "solid" : "outlined"}
                color="primary"
              >
                {isCompleted ? <Check sx={{ fontSize: "14px" }} /> : null}
              </StepIndicator>
            }
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography>{step}</Typography>
            </Box>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default StudyStatusStepper
