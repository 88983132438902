import { snackAlert } from "../components/SnackAlerts"
import { usePortalApi } from "../connections"
import {
  DeviceIn,
  DeviceOut,
  DeviceUpdate,
  GetAllDevicesRequest,
} from "../generated/fetchclient"
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { handleApiError } from "../utils/apiUtils"
import { devices, deviceCount } from "../state/device.state"
/**
 * Updates a device in the list of devices.
 *
 * @param devices The list of devices.
 * @param device The device to update.
 */
function updateDeviceInList(devices: DeviceOut[], device: DeviceOut) {
  const updatedDevices = devices.map((d) => {
    if (d.uuid === device.uuid) {
      return device
    }

    return d
  })
  return updatedDevices
}

const useDevices = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setDevices = useSetRecoilState(devices)
  const setDeviceCount = useSetRecoilState(deviceCount)
  const resetDevices = useResetRecoilState(devices)
  const resetDeviceCount = useResetRecoilState(deviceCount)

  const createDevice = async (device: DeviceIn) => {
    if (api === undefined) return

    try {
      await api.createDevice({ deviceIn: device })
      setSnackAlertMsg({
        open: true,
        message: "Device Created",
        severity: "success",
        autoHideDuration: 5000,
      })
      listDevices()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Deletes a device by its serial number.
   *
   * @param serialNumber The serial number of the device to delete.
   */
  const deleteDevice = async (serialNumber: string) => {
    if (api === undefined) return

    try {
      await api.deleteDevice({ serialNumber })
      setSnackAlertMsg({
        open: true,
        message: "Device Deleted",
        severity: "success",
        autoHideDuration: 5000,
      })
      listDevices()
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Lists devices.
   *
   * @param query The query to filter the list of devices.
   */
  const listDevices = async (query: GetAllDevicesRequest = {}) => {
    if (api === undefined) return

    try {
      const devices = await api.getAllDevices(query)
      setDevices(devices.items)
      setDeviceCount(devices.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  /**
   * Updates a device.
   *
   * @param serialNumber The serial number of the device to update.
   * @param device The updated device object.
   */
  const updateDevice = async (serialNumber: string, device: DeviceUpdate) => {
    if (api === undefined) return

    try {
      const updatedDevice = await api.updateDevice({
        serialNumber,
        deviceUpdate: device,
      })
      setDevices((devices) => updateDeviceInList(devices, updatedDevice))
      setSnackAlertMsg({
        open: true,
        message: "Device Updated",
        severity: "success",
        autoHideDuration: 5000,
      })
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const resetDeviceData = () => {
    resetDevices()
    resetDeviceCount()
  }

  return {
    devices: useRecoilValue(devices),
    deviceCount: useRecoilValue(deviceCount),
    createDevice,
    deleteDevice,
    listDevices,
    updateDevice,
    resetDeviceData,
  }
}

export default useDevices
