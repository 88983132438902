import { Paper, Typography } from "@mui/material"
import { styled } from "@mui/system"

const Box = styled(Paper)({
  background: "#FFFFFF",
  border: "1px solid #EFEEF4",
  borderRadius: "13px",
  boxShadow: "none",
  padding: "20px",
  marginBottom: "15px",
  "&:last-child": {
    marginBottom: 0,
  },
  "& .MuiTypography-h4": {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "20px",
  },
})

const StyledBoxSubtext = styled(Typography)({
  color: "#B1C3D1",
  display: "inline",
  fontWeight: 600,
  marginLeft: "6px",
})

export const BoxTitle = ({
  children,
  subtext = "",
}: {
  subtext?: string
  children: React.ReactNode
}) => {
  return (
    <Typography variant="h4">
      {children}
      <StyledBoxSubtext>{subtext}</StyledBoxSubtext>
    </Typography>
  )
}

export default Box
