import React from "react"
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy"
import { EventPlot } from "../../../types/event.type"

export interface YScaleModalProps {
  setNewYScales: (newYScales: { minY: number; maxY: number }) => void
  yScales: { [Key: string]: { minY: number; maxY: number } }
  newYScales: { minY: number; maxY: number }
  handleSaveScaleModal: () => void
  scaleModalOpen: { open: boolean; plotType: string }
  handleCloseScaleModal: () => void
  autoScaling: boolean
  setAutoScaling: (autoScaling: boolean) => void
}

const YScaleModal: React.FC<YScaleModalProps> = ({
  scaleModalOpen,
  setNewYScales,
  yScales,
  newYScales,
  handleSaveScaleModal,
  handleCloseScaleModal,
  autoScaling,
  setAutoScaling,
}) => {
  return (
    <Modal
      open={scaleModalOpen.open}
      onClose={handleCloseScaleModal}
      aria-labelledby="modal-y-scale"
      aria-describedby="modal-to-set-plot-y-scales"
    >
      <ModalDialog>
        <ModalClose />
        <Typography>Set {scaleModalOpen.plotType} scale</Typography>
        <DialogContent>
          <FormLabel>Minimum value</FormLabel>
          <Input
            disabled={autoScaling}
            onChange={(e) =>
              setNewYScales({
                minY: Number(e.target.value),
                maxY: newYScales.maxY,
              })
            }
            defaultValue={
              scaleModalOpen.plotType
                ? yScales[scaleModalOpen.plotType as EventPlot].minY || ""
                : 0
            }
          />
          <FormLabel>Maximum value</FormLabel>
          <Input
            disabled={autoScaling}
            defaultValue={
              scaleModalOpen.plotType
                ? yScales[scaleModalOpen.plotType as EventPlot].maxY || ""
                : 0
            }
            onChange={(e) =>
              setNewYScales({
                minY: newYScales.minY,
                maxY: Number(e.target.value),
              })
            }
          />
        </DialogContent>
        <Checkbox
          checked={autoScaling}
          label={"Auto scale"}
          onChange={() => setAutoScaling(!autoScaling)}
        />
        <DialogActions>
          <Button color="neutral" onClick={handleCloseScaleModal}>
            Close
          </Button>
          <Button onClick={handleSaveScaleModal}>Save</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default YScaleModal
