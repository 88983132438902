import { Box, Button, DialogTitle, Divider, IconButton, Sheet } from "@mui/joy"
import "./styles.css"
import TipTap from "../../../components/tiptap/editor/TipTap"
import { JSONContent } from "@tiptap/core"
import React, { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"

interface InterpretationDrawerProps {
  initialInterpretationEditorContentData: JSONContent
  interpretationDrawerWidth: number
  openInterpretationDrawerStatus: boolean
  setOpenInterpretationDrawerStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >
  openInterpretationTemplateModal: () => void
  onInterpretationEditorContentChange: (contentData: JSONContent) => void
  setInterpretationDrawerWidth: React.Dispatch<React.SetStateAction<number>>
  openSignatureModal: () => void
}

const drawerWidth = 350

const InterpretationDrawer = ({
  initialInterpretationEditorContentData,
  interpretationDrawerWidth,
  openInterpretationDrawerStatus,
  setOpenInterpretationDrawerStatus,
  openInterpretationTemplateModal,
  onInterpretationEditorContentChange,
  setInterpretationDrawerWidth,
  openSignatureModal,
}: InterpretationDrawerProps) => {
  const [isResizing, setIsResizing] = useState(false)
  const [lastX, setLastX] = useState<number>(0)

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsResizing(true)
    setLastX(event.clientX)
  }
  const handleMouseUp = () => {
    setIsResizing(false)
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (!isResizing || lastX === null) return
    const deltaX = event.clientX - lastX
    setLastX(event.clientX)
    if (deltaX + drawerWidth)
      setInterpretationDrawerWidth((prevWidth) => {
        const newWidth = prevWidth - deltaX
        return newWidth > 500
          ? 500
          : newWidth <= drawerWidth
          ? drawerWidth
          : newWidth
      })
  }

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("mouseup", handleMouseUp)
    document.addEventListener("mouseleave", handleMouseUp)
    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
      document.removeEventListener("mouseleave", handleMouseUp)
    }
  }, [isResizing, lastX, interpretationDrawerWidth])

  return (
    <>
      <Sheet
        className="Sidebar"
        sx={{
          position: {
            xs: "fixed",
            md: "fixed",
          },
          transform: {
            xs: `translateX(calc(100% - ${
              openInterpretationDrawerStatus ? interpretationDrawerWidth : 0
            }px ))`,
          },
          transition: `${
            isResizing ? "width 0.4s" : "transform 0.4s, width 0.4s"
          }`,
          zIndex: 1,
          height: "100dvh",
          width: interpretationDrawerWidth,
          px: 1,
          py: 1,
          flexShrink: 0,
          flexDirection: "column",
          gap: "15px",
          right: 0,
          borderLeft: "1px solid",
          borderTop: "1px solid",
          borderColor: "divider",
          top: "60px",
        }}
      >
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          style={{
            right:
              interpretationDrawerWidth > 0
                ? interpretationDrawerWidth + "px"
                : "0px",
            transition: isResizing ? "none" : "right 0.3s ease",
          }}
          className="resizeableWrapper"
        ></div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ p: 1 }}>Interpretation Drawer</DialogTitle>
          <IconButton
            onClick={() => setOpenInterpretationDrawerStatus(false)}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            mt: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button size="md" onClick={() => openInterpretationTemplateModal()}>
              Interpretation Templates
            </Button>
          </Box>
          <Box className="interpretationEditor">
            <TipTap
              editable={true}
              menuStatus={true}
              extension={[]}
              content={initialInterpretationEditorContentData as JSONContent}
              onContentChange={onInterpretationEditorContentChange}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button onClick={() => openSignatureModal()} size="md">
              Sign and Preview
            </Button>
          </Box>
        </Box>
      </Sheet>
    </>
  )
}

export default InterpretationDrawer
