import { Divider, Grid } from "@mui/joy"
import { SxProps } from "@mui/material"

type GridDividerParams = {
  xs: number
  sx?: SxProps
  text?: string
}

function GridDivider({ xs, sx, text }: GridDividerParams) {
  return (
    <Grid xs={xs}>
      <Divider sx={sx}>
        <b>{text}</b>
      </Divider>
    </Grid>
  )
}

export default GridDivider
