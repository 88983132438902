import { Box, Button, Divider } from "@mui/joy"
import ModalBox from "../../components/modal/ModalBox"
import OrganizationForm from "../../components/forms/organization/OrganizationForm"
import { OrganizationTableRow } from "../../types/table.type"
import {
  OrganizationIn,
  OrganizationLocationIn,
} from "../../generated/fetchclient"
import { emptyOrganizationLocation } from "../../const/organization.const"
import { inputOrganizationErrors } from "../../interfaces/organization.interface"
import { Value } from "react-phone-number-input"

interface OrganizationAddEditModalProps {
  setIndividualOrganization: React.Dispatch<
    React.SetStateAction<OrganizationTableRow>
  >
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  openModal: boolean
  modalTitle: string
  individualOrganization: OrganizationTableRow
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  inputOrganizationErrors: inputOrganizationErrors
}

const OrganizationAddEditModal = ({
  handleSubmit,
  setOpen,
  openModal,
  modalTitle,
  individualOrganization,
  inputOrganizationErrors,
  setIndividualOrganization,
}: OrganizationAddEditModalProps) => {
  const handlePropChange = (
    property: keyof OrganizationIn,
    value: Date | string | boolean | Value
  ) => {
    setIndividualOrganization({
      ...individualOrganization,
      [property]: value,
    })
  }

  const handleLocationChange = (
    index: number,
    property: keyof OrganizationLocationIn,
    value: string | boolean | Value
  ) => {
    const newLocations = individualOrganization.locations.map(
      (location, locIndex) => {
        if (locIndex === index) {
          return {
            ...location,
            [property]: value,
          }
        }
        return location
      }
    )

    setIndividualOrganization({
      ...individualOrganization,
      locations: newLocations,
    })
  }

  const removeLocation = (index: number) => {
    const newLocations = individualOrganization.locations.filter(
      (_, i) => i !== index
    )
    setIndividualOrganization({
      ...individualOrganization,
      locations: newLocations,
    })
  }

  const addLocation = () => {
    setIndividualOrganization({
      ...individualOrganization,
      locations: [
        emptyOrganizationLocation,
        ...individualOrganization.locations,
      ],
    })
  }
  return (
    <ModalBox
      open={openModal}
      setOpen={setOpen}
      title={modalTitle}
      width={1000}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ maxHeight: "550px", overflowY: "auto" }}>
          <OrganizationForm
            handlePropChange={handlePropChange}
            inputOrganizationErrors={inputOrganizationErrors}
            handleLocationChange={handleLocationChange}
            individualOrganization={individualOrganization}
            removeLocation={removeLocation}
            addLocation={addLocation}
          />
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button type="submit">{"Submit"}</Button>
        </Box>
      </form>
    </ModalBox>
  )
}

export default OrganizationAddEditModal
