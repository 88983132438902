import { useSetRecoilState } from "recoil"
import { usePortalApi } from "../connections"
import { snackAlert } from "../components/SnackAlerts"
import { handleApiError } from "../utils/apiUtils"
import { GetSignatureUrlRequest } from "../generated/fetchclient/apis/DefaultApi"

const useSignature = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  /**
   * Returns a url for uploaded signature
   *
   * @param physicianId user's uuid
   */
  const getSignatureUrl = async (physicianId: GetSignatureUrlRequest) => {
    if (api === undefined) return

    try {
      const url = await api.getSignatureUrl(physicianId)
      return url
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const uploadSignatureImage = async (physicianId: string, File: File) => {
    if (api === undefined) return

    try {
      await api.uploadSignature({
        physicianId: physicianId,
        file: File,
      })
      return true
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      return false
    }
  }

  return {
    getSignatureUrl,
    uploadSignatureImage,
  }
}

export default useSignature
