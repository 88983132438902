import React from "react"
import { ScoringCriteria } from "../../../types/event.type"

interface DesatEventCountProps {
  range_4_9: number
  range_10_20: number
  range_gt_20: number
  total: number
  meanOfDesatNadirs: string
  selectedScoringCriteria: ScoringCriteria
}

const DesatEventCount: React.FC<DesatEventCountProps> = ({
  range_4_9,
  range_10_20,
  range_gt_20,
  total,
  meanOfDesatNadirs,
  selectedScoringCriteria,
}) => {
  return (
    <table className="data-table">
      <tbody>
        <tr className="table-head">
          <th className="table-head" colSpan={5}>
            Desat Event Counts
          </th>
        </tr>
        <tr className="center-row">
          <td>
            <strong>
              {selectedScoringCriteria === ScoringCriteria.THREE ? "3" : "4"}-9%
            </strong>
          </td>
          <td>
            <strong>10-20%</strong>
          </td>
          <td>
            <strong>{">"}20%</strong>
          </td>
          <td>
            <strong>Total</strong>
          </td>
        </tr>
        <tr>
          <td>{range_4_9}</td>
          <td>{range_10_20}</td>
          <td>{range_gt_20}</td>
          <td>{total}</td>
        </tr>
        <tr>
          <td colSpan={5}>Mean Desat Nadir: {meanOfDesatNadirs}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default DesatEventCount
