import { Bar } from "@visx/shape"
import { useRecoilValue } from "recoil"
import { convertHexToRGBA } from "@huxley-medical/react-components/utils"
import { colorMap } from "../../../const/event.const"
import { eventFamily } from "../../../state/event.state"
import { ScaleLinear } from "d3-scale"
import { activeCriteria } from "../../../state/study.state"

type AnnotatedEventParams = {
  eventID: string
  height: number
  timeScale: ScaleLinear<number, number, never>
}

/**
 * AnnotatedEvent represents the colored event regions rendered
 * inside the hypnogram. Its color is based on the event type.
 *
 * @param {AnnotatedEventParams} annotatedEventParams
 * @returns {JSX.Element} JSX.Element
 */
const AnnotatedEvent = ({
  eventID,
  timeScale,
  height,
}: AnnotatedEventParams): JSX.Element => {
  const { type, event_ts, removed, plot, scoringCriteria } = useRecoilValue(
    eventFamily(eventID)
  )
  const selectedScoringCriteria = useRecoilValue(activeCriteria)

  // Scale range to pixels
  const startPx = timeScale(event_ts[0])
  const endPx = timeScale(event_ts[1])
  const widthPx = endPx - startPx < 2 ? 2 : endPx - startPx

  // Identify color to use
  const color = convertHexToRGBA(colorMap[type], 0.7)

  if (removed || selectedScoringCriteria !== scoringCriteria) return <></>

  if (plot === "ECG") return <></>

  return (
    <Bar
      fill={color}
      height={height}
      width={widthPx}
      x={timeScale(event_ts[0])}
    />
  )
}

export default AnnotatedEvent
