import * as React from "react"
import { useState } from "react"

import {
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from "@mui/joy"
import AddIcon from "@mui/icons-material/Add"

import "react-phone-number-input/style.css"
import StudyOrderForm from "./StudyOrderForm"

export const emptySelectedPatient = {
  uuid: "",
  name: "",
}

function NewStudyModal() {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)

  return (
    <React.Fragment>
      <Button
        size="sm"
        startDecorator={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        New Study
      </Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => {
          setPage(1)
          setOpen(false)
        }}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalDialog
          sx={{ maxWidth: 880, overflowY: "auto", marginTop: "40px" }}
        >
          <ModalClose />
          <Stack spacing={2}>
            <DialogTitle>
              Create New Study:
              {page === 1 ? " Select Patient" : " Study Details"}
            </DialogTitle>
            <StudyOrderForm setOpen={setOpen} page={page} setPage={setPage} />
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  )
}

export default NewStudyModal
