import { atom } from "recoil"

export const isSignatureLoading = atom<boolean>({
  key: "isSignatureLoading",
  default: false,
})

export const uploadedSignatureUrl = atom<string | undefined>({
  key: "signatureUrl",
  default: undefined,
})
