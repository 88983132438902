import { LinePath } from "@visx/shape"
import { ScaleLinear } from "d3-scale"
import { checkSignalLength } from "../../utils"
import { Signal } from "../../interfaces"
import { lttbDownsample } from "../../math/lttb"
import { minMaxDownsample } from "../../math/minMax"

const LinePathPlot = ({
  data,
  timeScale,
  yScale,
  width,
  stroke,
  strokeWidth,
  downsampleMethod,
}: {
  data: Signal
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  width: number
  stroke: string
  strokeWidth: number
  downsampleMethod?: "lttb" | "none" | "minMax"
}): JSX.Element => {
  // check if data is greater than width
  // if so, downsample
  // if not, return data
  let downsampledData = data
  // get screen width

  if (!checkSignalLength(data)) {
    throw new Error("Timestamps and values length do not match")
  } else if (
    downsampleMethod === "none" ||
    downsampleMethod === undefined ||
    data.timestamps.length < width ||
    data.values.length < width
  ) {
    downsampledData = data
  } else if (downsampleMethod === "lttb") {
    downsampledData = lttbDownsample(downsampledData, 2400)
  } else if (downsampleMethod === "minMax") {
    const bucketsize = Math.floor(data.timestamps.length / width)
    downsampledData = minMaxDownsample(downsampledData, bucketsize)
  }

  return (
    <LinePath
      data={downsampledData.values}
      x={(_: number, i: number) => timeScale(downsampledData.timestamps[i])}
      y={(d) => yScale(d)}
      stroke={stroke}
      strokeWidth={strokeWidth}
      shapeRendering="geometricPrecision"
    />
  )
}

export default LinePathPlot
