import {
  GetAllDevicesRequest,
  GetAllOrganizationsRequest,
  GetAllPatientsRequest,
  GetAllStudyOrdersRequest,
  GetAllUsersRequest,
} from "../generated/fetchclient"
import { EventsData } from "../interfaces/events.interface"
import { Signal } from "../interfaces/signals.interface"

export const binarySearchData = (
  timesPointData: Signal,
  target: number
): number => {
  let left = 0
  let right = timesPointData.timestamps.length - 1
  let closestIndex = timesPointData.timestamps.length - 1

  while (left <= right) {
    const mid = (left + right) >> 1
    const midDate = timesPointData.timestamps[mid]
    closestIndex = mid
    if (midDate >= target) {
      right = mid - 1
    } else {
      left = mid + 1
    }
  }
  return closestIndex
}

export const findMaxMinValues = (
  timesPointData: Signal,
  startIndex: number,
  endIndex: number
) => {
  if (
    startIndex < 0 ||
    endIndex < 0 ||
    startIndex >= timesPointData.timestamps.length ||
    endIndex >= timesPointData.timestamps.length ||
    startIndex > endIndex
  ) {
    return { max: null, min: null }
  }
  return findMaxMinFromSeries(timesPointData.values, startIndex, endIndex)
}

export const findMaxMinFromSeries = (
  signalData: number[],
  startIndex: number,
  endIndex: number
) => {
  let max = -Infinity,
    min = Infinity

  for (let i = startIndex; i < endIndex; i++) {
    const value = signalData[i]
    max = Math.max(value, max)
    min = Math.min(value, min)
  }
  return { max, min }
}

export function removeEmptyValuesFromObject(
  obj:
    | GetAllDevicesRequest
    | GetAllOrganizationsRequest
    | GetAllPatientsRequest
    | GetAllStudyOrdersRequest
    | GetAllUsersRequest
) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (Array.isArray(v)) {
        return v.length > 0
      }

      return Boolean(v)
    })
  )
}

export const fetchApiData = async (url: string) =>
  await (await fetch(url)).json()

export const fetchEventData = async (url: string): Promise<EventsData> => {
  const eventData = await fetchApiData(url)
  return eventData
}

export const formatUTCDate = (date: Date): string => {
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0")
  const day = date.getUTCDate().toString().padStart(2, "0")
  const year = date.getUTCFullYear()

  return `${month}/${day}/${year}`
}
