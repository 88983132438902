/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    cognitoId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    phone?: string;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof UserUpdate
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    organizationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdate
     */
    groups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    primaryRole?: string;
}

/**
 * Check if a given object implements the UserUpdate interface.
 */
export function instanceOfUserUpdate(value: object): value is UserUpdate {
    return true;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'cognitoId': json['cognito_id'] == null ? undefined : json['cognito_id'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'isActive': json['is_active'] == null ? undefined : json['is_active'],
        'organizationId': json['organization_id'] == null ? undefined : json['organization_id'],
        'groups': json['groups'] == null ? undefined : json['groups'],
        'primaryRole': json['primary_role'] == null ? undefined : json['primary_role'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cognito_id': value['cognitoId'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'email': value['email'],
        'phone': value['phone'],
        'is_active': value['isActive'],
        'organization_id': value['organizationId'],
        'groups': value['groups'],
        'primary_role': value['primaryRole'],
    };
}

