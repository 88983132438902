import { FC } from "react"
import LoadingIndicator from "../../../components/loading/LoadingIndicator"
import { isSignatureLoading } from "../../../state/signature.state"
import { useRecoilValue } from "recoil"
import { isGeneratingPDFReport } from "../../../state/pdfReport.state"
import {
  currentStudy,
  eventExclusionLoader,
  isLoadingStudy,
  reviewStudyChanges,
} from "../../../state/study.state"
import { isTemplateLoading } from "../../../state/interpretationtemplate.state"

interface LoadersParms {
  loadingPreviewModal: boolean
  interpretationTemplateModalStatus: boolean
}

const Loaders: FC<LoadersParms> = ({
  loadingPreviewModal,
  interpretationTemplateModalStatus,
}) => {
  const isSignatureLoadingStatus = useRecoilValue(isSignatureLoading)
  const isGeneratingPDF = useRecoilValue(isGeneratingPDFReport)
  const eventExclusionLoaderStatus = useRecoilValue(eventExclusionLoader)
  const isLoading = useRecoilValue(isLoadingStudy)
  const study = useRecoilValue(currentStudy)
  const isInterpretationTemplateLoading = useRecoilValue(isTemplateLoading)
  const reviewStudyChangesStatus = useRecoilValue(reviewStudyChanges)

  return (
    <>
      {isSignatureLoadingStatus && (
        <LoadingIndicator header="Getting signature" content=" " />
      )}
      {isInterpretationTemplateLoading &&
        !interpretationTemplateModalStatus && (
          <LoadingIndicator
            header="Loading Interpretation Templates"
            content=" "
          />
        )}
      {isLoading && (
        <LoadingIndicator header="Loading Study Dataset" content=" " />
      )}
      {isGeneratingPDF && (
        <LoadingIndicator header="Generating PDF Report" content=" " />
      )}
      {eventExclusionLoaderStatus && (
        <LoadingIndicator header="Saving Changes" content=" " />
      )}
      {reviewStudyChangesStatus && (
        <LoadingIndicator header="Reviewing Study" content=" " />
      )}
      {loadingPreviewModal && (
        <LoadingIndicator header="Loading PDF Preview" content=" " />
      )}
      {!isLoading && !study && (
        <LoadingIndicator
          header="Failed to load study dataset"
          content="Please contact Huxley if this issue persists"
          showCircularProgress={false}
        />
      )}
    </>
  )
}

export default Loaders
