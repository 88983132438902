import AxisLeft from "@visx/axis/lib/axis/AxisLeft"
import { TickFormatter } from "@visx/axis/lib/types"
import { NumberValue, ScaleLinear } from "d3-scale"

interface GraphPlotAxisProps {
  left: number
  scale: ScaleLinear<number, number, never>
  tickValues: number[]
  tickFormat: TickFormatter<NumberValue> | undefined
}

const GraphPlotLeftAxis = ({
  left,
  scale,
  tickValues,
  tickFormat,
}: GraphPlotAxisProps) => {
  return (
    <AxisLeft
      top={0}
      labelOffset={40}
      left={left}
      scale={scale}
      tickValues={tickValues}
      tickFormat={tickFormat}
      orientation={"left"}
    />
  )
}

export default GraphPlotLeftAxis
