import CssBaseline from "@mui/joy/CssBaseline"
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles"
import SnackAlerts from "./components/SnackAlerts"
import Router from "./router"
import "@aws-amplify/ui-react/styles.css"
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles"
import "./cognito"
import { Authenticator } from "@aws-amplify/ui-react"
const materialTheme = materialExtendTheme()

function App() {
  return (
    <Authenticator.Provider>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider disableTransitionOnChange>
          <CssBaseline />
          <Router />
          <SnackAlerts />
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </Authenticator.Provider>
  )
}

export default App
