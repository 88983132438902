import { Button, Divider, Grid, Input, Option, Select } from "@mui/joy"
import * as EmailValidator from "email-validator"
import { phone } from "phone"
import React, { useEffect, useState } from "react"
import PhoneInput, { Value } from "react-phone-number-input/input"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import { PatientIn } from "../../../../src/generated/fetchclient"
import {
  patientRaceOptions,
  patientSexOptions,
} from "../../../const/patient.const"
import GridDivider from "../../griddivider/GridDivider"
import { isHuxleyUser } from "../../../state/auth.state"
import useOrganizations from "../../../hooks/useOrganizations"
import usePatients from "../../../hooks/usePatients"
import { EditModalPatientData } from "../../../types/event.type"
import GridFormInput from "../../forms/grid/GridFormInput"

type NewPatientFormParams = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  patientData?: EditModalPatientData
}

const emptyPatientInput = {
  firstName: "",
  middleName: "",
  lastName: "",
  primaryPhone: "",
  email: "",
  dob: new Date(),
  mrn: "",
  race: "",
  sex: "",
  organizationId: "",
}

const emptyInputErrors = {
  firstName: "",
  lastName: "",
  primaryPhone: "",
  email: "",
  referringPhysicianPhone: "",
}

function NewPatientForm({ setOpen, patientData }: NewPatientFormParams) {
  const api = usePortalApi()
  const patientsApi = usePatients()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const isAdmin = useRecoilValue(isHuxleyUser)
  const { organizations, listOrganizations } = useOrganizations()
  const [patientInput, setPatientInput] = useState<PatientIn>(
    patientData || emptyPatientInput
  )
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)

  useEffect(() => {
    if (isAdmin) listOrganizations()
  }, [isAdmin])

  const handlePropChange = (
    property: keyof PatientIn,
    value: Date | string | Value
  ) => {
    setPatientInput({
      ...patientInput,
      [property]: value,
    })
  }

  const validateForm = () => {
    let primaryPhoneError = ""
    let emailError = ""
    let referringPhysicianPhoneError = ""
    let patientFirstNameError = ""
    let patientLastNameError = ""

    if (patientInput.firstName.trim() === "") {
      patientFirstNameError = "Required"
    }

    if (patientInput.lastName.trim() === "") {
      patientLastNameError = "Required"
    }

    if (!phone(patientInput.primaryPhone).isValid) {
      primaryPhoneError = "Invalid phone number"
    }

    if (!EmailValidator.validate(patientInput.email)) {
      emailError = "Invalid email address"
    }

    if (
      patientInput.referringPhysicianPhone &&
      patientInput.referringPhysicianPhone !== "" &&
      !phone(patientInput.referringPhysicianPhone).isValid
    ) {
      referringPhysicianPhoneError = "Invalid phone number"
    }

    const errors = {
      firstName: patientFirstNameError,
      lastName: patientLastNameError,
      primaryPhone: primaryPhoneError,
      email: emailError,
      referringPhysicianPhone: referringPhysicianPhoneError,
    }

    setInputErrors(errors)

    return Object.values(errors).every((e) => e === "")
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validateForm()) return

    patientData
      ? await patientsApi.updatePatient(patientData.uuid ?? "", patientInput)
      : await patientsApi.createPatient(patientInput)

    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <GridDivider
          xs={12}
          sx={{ "--Divider-childPosition": "0%" }}
          text={"Patient Information"}
        />
        <GridFormInput
          width={4}
          label="First Name*"
          error={inputErrors.firstName}
        >
          <Input
            value={patientInput.firstName}
            onChange={(e) => handlePropChange("firstName", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={4} label="Middle Name">
          <Input
            value={patientInput.middleName}
            onChange={(e) => handlePropChange("middleName", e.target.value)}
          />
        </GridFormInput>
        <GridFormInput
          width={4}
          label="Last Name*"
          error={inputErrors.lastName}
        >
          <Input
            value={patientInput.lastName}
            onChange={(e) => handlePropChange("lastName", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput
          width={6}
          label="Phone Number*"
          error={inputErrors.primaryPhone}
        >
          <PhoneInput
            className={
              "phone-input " + (!!inputErrors.primaryPhone ? "error" : "")
            }
            country="US"
            value={patientInput.primaryPhone as Value}
            onChange={(value) => handlePropChange("primaryPhone", value || "")}
            required
          />
        </GridFormInput>
        <GridFormInput
          width={6}
          label="Email Address*"
          error={inputErrors.email}
        >
          <Input
            type="email"
            error={!!inputErrors.email}
            value={patientInput.email}
            onChange={(e) => handlePropChange("email", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="Date of Birth*">
          <Input
            type="date"
            value={patientInput.dob.toISOString().split("T")[0]}
            onChange={(e) => {
              const dob = e.target.valueAsDate
              if (dob) {
                handlePropChange("dob", dob)
              }
            }}
            slotProps={{
              input: {
                max: new Date().toISOString().split("T")[0],
              },
            }}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="MRN*">
          <Input
            value={patientInput.mrn}
            onChange={(e) => handlePropChange("mrn", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="Race*">
          <Select
            value={patientInput.race}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("race", value)
              }
            }}
            placeholder="Select one"
            required
          >
            {patientRaceOptions.map((option, index) => {
              return (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              )
            })}
          </Select>
        </GridFormInput>
        <GridFormInput width={6} label="Sex*">
          <Select
            value={patientInput.sex}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("sex", value)
              }
            }}
            placeholder="Select one"
            required
          >
            {patientSexOptions.map((option) => {
              return (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              )
            })}
          </Select>
        </GridFormInput>
        <GridFormInput width={6} label="Referring Physician First Name">
          <Input
            value={patientInput.referringPhysicianFirstName}
            onChange={(e) =>
              handlePropChange("referringPhysicianFirstName", e.target.value)
            }
          />
        </GridFormInput>
        <GridFormInput width={6} label="Referring Physician Last Name">
          <Input
            value={patientInput.referringPhysicianLastName}
            onChange={(e) =>
              handlePropChange("referringPhysicianLastName", e.target.value)
            }
          />
        </GridFormInput>
        <GridFormInput
          width={6}
          label="Referring Physician Phone Number"
          error={inputErrors.referringPhysicianPhone}
        >
          <PhoneInput
            className={
              "phone-input " +
              (inputErrors.referringPhysicianPhone ? "error" : "")
            }
            country="US"
            value={patientInput.referringPhysicianPhone as Value}
            onChange={(value) => {
              handlePropChange("referringPhysicianPhone", value || "")
            }}
          />
        </GridFormInput>
        {isAdmin && (
          <GridFormInput width={6} label="Organization">
            <Select
              disabled={!isAdmin}
              value={patientInput.organizationId}
              onChange={(_, value) => {
                if (value !== null) {
                  handlePropChange("organizationId", value)
                }
              }}
              placeholder="Select one"
            >
              {organizations.map((option, index) => {
                return (
                  <Option key={index + 1} value={option.uuid}>
                    {option.name}
                  </Option>
                )
              })}
            </Select>
          </GridFormInput>
        )}
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button type="submit">
            {patientData ? "Edit Patient" : "Create Patient"}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewPatientForm
