import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import Box from "@mui/joy/Box"
import Link from "@mui/joy/Link"
import StudyTable from "../../../components/study/StudyTable"
import useStudies from "../../../hooks/useStudies"
import { useEffect, useState } from "react"
import { usePortalApi } from "../../../connections"
import { useRecoilState, useResetRecoilState } from "recoil"
import { snackAlert } from "../../../components/SnackAlerts"
import InternalHeader from "../../../components/internalheader/InternalHeader"
import LoadingIndicator from "../../../components/loading/LoadingIndicator"
import { HeaderList } from "../../../interfaces/header.inteface"
import { isLoadingStudy, studies } from "../../../state/study.state"
import { getQueryFromUrl } from "../../../utils/urlUtils"
import { GetAllStudyOrdersRequest } from "../../../generated/fetchclient"

const AdminStudiesContent = () => {
  const studiesApi = useStudies()
  const api = usePortalApi()
  const [headerList, setHeaderList] = useState<HeaderList[]>([])
  const [isLoading, setIsLoading] = useRecoilState(isLoadingStudy)
  const resetStudies = useResetRecoilState(studies)
  const resetSetSnackAlertMsg = useResetRecoilState(snackAlert)
  const searchParamsQuery = getQueryFromUrl()
  const [studyQuery, setStudyQuery] =
    useState<GetAllStudyOrdersRequest>(searchParamsQuery)

  useEffect(() => {
    if (api === undefined) return

    setIsLoading(true)
    const fetchData = async () => {
      setStudyQuery(searchParamsQuery)
      await studiesApi.listStudyOrders(searchParamsQuery)
      setIsLoading(false)
    }
    setHeaderList([
      {
        fontWeight: 500,
        fontSize: 12,
        text: "Studies",
      },
    ])
    fetchData()

    return () => {
      resetStudies()
      resetSetSnackAlertMsg()
    }
  }, [api])

  return (
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InternalHeader breadCrumbList={headerList}>
            <Link
              underline="none"
              color="neutral"
              href="#some-link"
              aria-label="Home"
            >
              <HomeRoundedIcon />
            </Link>
          </InternalHeader>
        </Box>
        <StudyTable
          showAdminOptions
          studyQuery={studyQuery}
          setStudyQuery={setStudyQuery}
        ></StudyTable>
      </Box>
      {isLoading && <LoadingIndicator header="Loading Studies" content=" " />}
    </>
  )
}

export default AdminStudiesContent
