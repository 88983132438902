/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationLocationIn
 */
export interface OrganizationLocationIn {
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationIn
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationIn
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationIn
     */
    isPrimary?: boolean;
}

/**
 * Check if a given object implements the OrganizationLocationIn interface.
 */
export function instanceOfOrganizationLocationIn(value: object): value is OrganizationLocationIn {
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    return true;
}

export function OrganizationLocationInFromJSON(json: any): OrganizationLocationIn {
    return OrganizationLocationInFromJSONTyped(json, false);
}

export function OrganizationLocationInFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationLocationIn {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'phone': json['phone'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'isPrimary': json['is_primary'] == null ? undefined : json['is_primary'],
    };
}

export function OrganizationLocationInToJSON(value?: OrganizationLocationIn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'phone': value['phone'],
        'enabled': value['enabled'],
        'is_primary': value['isPrimary'],
    };
}

