import { GetAllOrganizationsRequest } from "../generated/fetchclient/apis/DefaultApi"
import { OrganizationLocationIn } from "../generated/fetchclient/models/OrganizationLocationIn"
import { inputOrganizationErrors } from "../interfaces/organization.interface"
import { OrganizationTableRow } from "../types/table.type"

export const emptyOrganizationQuery: GetAllOrganizationsRequest = {
  name: "",
}

export const emptyOrganizationLocation: OrganizationLocationIn = {
  description: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  isPrimary: true,
  enabled: true,
}

export const emptyOrganization: OrganizationTableRow = {
  name: "",
  description: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  enabled: true,
  locations: [emptyOrganizationLocation],
}

export const emptyOrganizationInputErrors: inputOrganizationErrors = {
  name: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  locations: [],
}
