/* eslint-disable react/display-name */
import { useEffect } from "react"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { relMouseCoords } from "@huxley-medical/react-components/utils"
import { Point } from "@huxley-medical/react-components/types"
import { useParams } from "react-router-dom"
import { EventType, UseEventContextMenuParams } from "../../../types/event.type"
import { StyledEventContextMenu } from "../../../styled/Sheet"
import { StyledListItemText } from "../../../styled/ListItem"
import { eventContextMenu } from "../../../state/event.state"
import { currentUser } from "../../../state/auth.state"
import { eventRemove, eventTypeSelection } from "../../../utils/eventUtils"

type EventContextMenuParams = {
  left: number
  top: number
  onDelete?: React.MouseEventHandler<HTMLLIElement>
  onEventTypeSelected?: (updatedType: EventType) => void
  hideMenu?: () => void
}

/**
 * eventContextMenuFactory returns a instance of the event context menu
 * component given positioning coordinates (top, left) and event handler
 * callbacks.
 *
 * @param {EventContextMenuParams} eventContextMenuParams
 * @returns {(() => JSX.Element)} (() => JSX.Element)
 */
const eventContextMenuFactory =
  ({
    left,
    top,
    hideMenu,
    onDelete,
    onEventTypeSelected,
  }: EventContextMenuParams): (() => JSX.Element) =>
  () => {
    /*const [open, setOpen] = useState(false)
    const handleToggleOpen = () => {
      setOpen((prev) => !prev)
    }*/

    return (
      <StyledEventContextMenu
        sx={{ left, top }}
        onMouseDown={(e) => {
          // A click outside the menu closes it
          // ensure the menu doesn't close when it's clicked
          e.stopPropagation()
        }}
      >
        <MenuList>
          <MenuItem
            onClick={(e) => {
              if (onDelete !== undefined) onDelete(e)
              if (hideMenu !== undefined) hideMenu()
            }}
          >
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <StyledListItemText>Delete</StyledListItemText>
          </MenuItem>
        </MenuList>
      </StyledEventContextMenu>
    )
  }

/**
 * useEventContextMenu is a hook to use in tandem with StackedPlots and Event
 * components to render an MUI event context menu when a user right-clicks.
 * It accepts a ref object of parent plots component used to compute relative
 * mouse coordinates for menu positioning. It returns a StyledEventContextMenu
 * component instance.
 *
 * @param {UseEventContextMenuParams} useEventContextMenuParams
 * @returns {(() => JSX.Element | null)} (() => JSX.Element | null)
 */
export const useEventContextMenu = ({
  plotsRef,
}: UseEventContextMenuParams): (() => JSX.Element | null) => {
  //const events = useRecoilValue(eventIDs)

  const { studyID } = useParams()

  const [contextMenuSelectedEvent, setContextMenuSelectedEvent] =
    useRecoilState(eventContextMenu)
  const handleEventTypeSelection = useRecoilCallback(eventTypeSelection)
  const handleEventRemove = useRecoilCallback(eventRemove)
  const user = useRecoilValue(currentUser)

  let eventCoords: Point | undefined

  // Set eventCoords if the ref and contextMenuSelectedEvent state is set
  if (contextMenuSelectedEvent !== undefined && plotsRef.current !== null) {
    eventCoords = relMouseCoords(
      contextMenuSelectedEvent.mouseEvent,
      plotsRef.current
    )
  }

  const hideMenu = () => {
    setContextMenuSelectedEvent(undefined)
  }

  useEffect(() => {
    window.addEventListener("mousedown", hideMenu)

    return () => {
      window.removeEventListener("mousedown", hideMenu)
    }
  }, [])

  // Return nothing if event coords aren't set
  // which will happen when menu is hidden
  if (eventCoords === undefined || contextMenuSelectedEvent === undefined)
    return () => {
      return null
    }

  // Return context menu instance with bound event handlers and data
  return eventContextMenuFactory({
    onDelete: handleEventRemove(
      contextMenuSelectedEvent.eventData.id,
      studyID,
      contextMenuSelectedEvent.eventData,
      user?.uuid
    ),
    onEventTypeSelected: handleEventTypeSelection(
      contextMenuSelectedEvent.eventData
    ),
    hideMenu,
    left: eventCoords.x,
    top: eventCoords.y,
  })
}
