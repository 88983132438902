import { ScaleLinear } from "d3-scale"
import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { Group } from "@visx/group"
import { Bar } from "@visx/shape"
import { currentEpoch } from "../../../state/epoch.state"
import {
  DragMoveCallback,
  useWindowDrag,
} from "@huxley-medical/react-components/hooks/mouse"
import { epochMs } from "../../../constants"
import { Point } from "@huxley-medical/react-components/types"
import {
  convertHexToRGBA,
  relMouseCoords,
} from "@huxley-medical/react-components/utils"
import { intervalTimeEventTypeAtom } from "../../../state/study.state"
import { styled } from "@mui/joy/styles"
import { graphTimeIntervals } from "../../../const/signals.const"

export const StyledScrollBar = styled(Bar)({
  cursor: "move",
})

/**
 * getContainedEpochFromPx returns the epoch that contains the xPx pixel value
 *
 * @param timeScale - Scale object used to convert between px and milliseconds
 * @param xPx - The x pixel value for the returned epoch
 * @returns {number} containedEpoch - epoch that contains the x pixel value
 */
const getContainedEpochFromPx = (
  timeScale: ScaleLinear<number, number, never>,
  xPx: number
): number => {
  let newEpoch = Math.ceil(
    (timeScale.invert(xPx * 2) - timeScale.domain()[0]) /
      graphTimeIntervals[0].value
  )
  // left guardrail
  if (newEpoch < 0) newEpoch = 0
  return newEpoch
}

/**
 * onEpochWindowDrag handles dragging the epoch window. It calculates
 * the relative change from the starting mouse down event and updates
 * the range state with the new position.
 *
 * @param OnEpochWindowDragParams
 * @returns {DragMoveCallback} handlerFunc
 */

export type OnEpochWindowDragParams = {
  moveEventStart: Point | undefined
  range: number[]
  width: number
  setRange: React.Dispatch<React.SetStateAction<number[]>>
  setFromLocalUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

const onEpochWindowDrag =
  ({
    moveEventStart,
    range,
    width,
    setRange,
    setFromLocalUpdate,
  }: OnEpochWindowDragParams): DragMoveCallback =>
  (_: Point, absPoint: Point) => {
    if (moveEventStart === undefined) {
      return
    }

    const changeFromStart = absPoint.x - moveEventStart.x
    let start = range[0] + changeFromStart

    // Prevent event from overflowing right side
    if (start + range[1] > width) {
      start = width - range[1]
    }

    // Prevent event from overflowing left side
    if (start < 0) {
      start = 0
    }

    // Signify this is a local range update (prevent cyclical state updates)
    setFromLocalUpdate(true)
    setRange([start, range[1]])
  }

/**
 * EpochScrollWindow represents the transparent overlay which sits on
 * top of hypnogram. It renders a semi-transparent region that represents
 * the current visible range displayed on the plots rendered below the
 * hypnogram. This region can be dragged to page through epochs.
 *
 * @param {EpochScrollWindowParams} epochScrollWindowParams
 * @returns {JSX.Element} JSX.Element
 */

type EpochScrollWindowParams = {
  width: number
  height: number
  timeScale: ScaleLinear<number, number, never>
  parentRef: React.RefObject<SVGSVGElement>
}

const EpochScrollWindow = ({
  width,
  height,
  timeScale,
  parentRef,
}: EpochScrollWindowParams): JSX.Element => {
  const [moveEventStart, setMoveEventStart] = useState<undefined | Point>(
    undefined
  )
  const [xMin, xMax] = timeScale.domain()
  const [range, setRange] = useState<number[]>([])
  const [epoch, setEpoch] = useRecoilState(currentEpoch)

  // Local state is used to prevent cyclical state updates that could be
  // triggered from epoch updates outside of this component
  const [fromLocalUpdate, setFromLocalUpdate] = useState(false)
  const selectedTimeInterval = useRecoilValue(intervalTimeEventTypeAtom)

  // Set inital range on render or window resize
  useEffect(() => {
    // prevent large scroll range when timescale
    // domain isn't set
    if (width !== 0 && xMin !== 0) {
      setRange([
        timeScale(
          xMin + epoch * epochMs + selectedTimeInterval >= xMax
            ? xMax - selectedTimeInterval - epochMs
            : xMin + epoch * epochMs
        ),
        timeScale(xMin + selectedTimeInterval),
      ])
    }
  }, [width, height, timeScale, selectedTimeInterval])

  // set epoch when range changes
  useEffect(() => {
    // ignore inital range before width is calculated
    // only update epoch if from local update
    if (range[1] !== 0 && fromLocalUpdate) {
      setEpoch(getContainedEpochFromPx(timeScale, range[0]))
    }
  }, [range])

  // Set range when updated outside component with arrow keys
  useEffect(() => {
    if (fromLocalUpdate) {
      setFromLocalUpdate(false)
    } else {
      let start = timeScale(xMin + epoch * epochMs)
      // Prevent event from overflowing right side
      if (start + range[1] > width) {
        start = width - range[1]
      }

      // Prevent event from overflowing left side
      if (start < 0) {
        start = 0
      }
      // NOTE: we could move the arrow key code here...
      setRange([start, range[1]])
    }
  }, [epoch])

  useWindowDrag({
    start: moveEventStart,
    el: parentRef.current,
    // TODO: debounce
    dragMove: onEpochWindowDrag({
      moveEventStart,
      range,
      width,
      setRange,
      setFromLocalUpdate,
    }),
    dragEnd: () => {
      setMoveEventStart(undefined)
    },
  })

  return (
    <Group>
      <Bar
        height={height}
        width={width}
        fill="transparent"
        onMouseDown={(e) => {
          // left click
          if (e.nativeEvent.button === 0) {
            setMoveEventStart({ x: e.pageX, y: e.pageY })
            const coords = relMouseCoords(e, e.currentTarget)

            let start = coords.x - range[1] / 2

            // Prevent event from overflowing right side
            if (start + range[1] > width) {
              start = width - range[1]
            }

            // Prevent event from overflowing left side
            if (start < 0) {
              start = 0
            }

            // Signify this is a local range update (prevent cyclical
            // state updates)
            setFromLocalUpdate(true)
            setRange([
              // center window over cursor on click
              start,
              range[1],
            ])
          }
        }}
      />
      <StyledScrollBar
        onMouseDown={(e) => {
          // left click
          if (e.nativeEvent.button === 0) {
            setMoveEventStart({ x: e.pageX, y: e.pageY })
          }
        }}
        x={range[0]}
        width={range[1]}
        height={height}
        fill={convertHexToRGBA("#000", 0.25)}
      />
    </Group>
  )
}

export default EpochScrollWindow
