import { Amplify } from "aws-amplify"
import { cognitoClientId, cognitoUserPoolId } from "./connections"

const region = process.env.REACT_APP_AWS_REGION as string

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognitoUserPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognitoClientId,
  },
})
