import ReactDOM from "react-dom/client"
import App from "./App"
import { RecoilRoot } from "recoil"
import { BrowserRouter } from "react-router-dom"
import "./index.css"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import { launchDarklyClientID } from "./connections"

const LDProvider = withLDProvider({
  clientSideID: launchDarklyClientID,
  context: {
    kind: "user",
    key: "anonymous",
    name: "anonymous",
  },
})(App)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <LDProvider />
    </BrowserRouter>
  </RecoilRoot>
)
