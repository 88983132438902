import { BaseEvent } from "../interfaces/events.interface"
import {
  DetectionFlags,
  EventApi,
  EventPlot,
  EventType,
  HandleEventDragParams,
  HandleEventResizeParams,
  ScorerEventData,
  ScoringCriteria,
} from "../types/event.type"
import { Signal } from "../interfaces/signals.interface"
import {
  beatsDefaultLabel,
  beatsLabel,
  rhythmsDefaultLabel,
  rhythmsLabel,
} from "../const/event.const"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { eventFamily, eventIDs } from "../state/event.state"
import { CallbackInterface } from "recoil"
import { ScaleLinear } from "d3-scale"
import { binarySearchData, findMaxMinValues } from "./utils"
import { SnackAlert } from "../types/snackAlert.types"
import { Point } from "@huxley-medical/react-components/types"
import { ScrollRoutineContext } from "../types/scroller.type"
import { DragMoveCallback } from "@huxley-medical/react-components/hooks/mouse"
import {
  eventIntersector,
  findEventIntersectEnd,
  findEventIntersectStart,
} from "../components/scoring/event/utils"
import { scrollError } from "../components/scoring/scroll/hooks"
import {
  handleWidth,
  minEventWidth,
} from "../components/scoring/event/EventHandle"
import { editEventExclusionStatus } from "../state/study.state"
import { v1 as uuidv1 } from "uuid"

export const moveError: SnackAlert = {
  open: true,
  message: "Unable to move, other event in the way",
  severity: "error",
}

export function sumDesatEventsBetweenValues(
  desatEvents: ScorerEventData[],
  min: number,
  max: number
): number {
  return desatEvents.filter((eventData) => {
    const maxSpo2 = eventData.event_data?.max_spo2
    const minSpo2 = eventData.event_data?.min_spo2
    return (
      maxSpo2 != null &&
      minSpo2 != null &&
      maxSpo2 - minSpo2 >= min &&
      maxSpo2 - minSpo2 < max
    )
  }).length
}

export function meanOfDesatNadirs(desatEvents: ScorerEventData[]): number {
  const nadirValues = desatEvents
    .map((eventData) => {
      return eventData.event_data?.min_spo2 || NaN
    })
    .filter((value) => !isNaN(value))
  return nadirValues.reduce((a, b) => a + b, 0) / nadirValues.length
}

/**
 * filterPage - returns a filter function for EventData[] based
 * on the given x-domain
 * @param timeScaleDomain
 * @returns {(eventData: ScorerEventData) => boolean}
 */
export const filterPage =
  (timeScaleDomain: number[]): ((eventData: ScorerEventData) => boolean) =>
  (eventData: ScorerEventData) => {
    return (
      (eventData.event_ts[0] >= timeScaleDomain[0] &&
        eventData.event_ts[0] <= timeScaleDomain[1]) ||
      (eventData.event_ts[1] >= timeScaleDomain[0] &&
        eventData.event_ts[1] <= timeScaleDomain[1]) ||
      (eventData.event_ts[0] <= timeScaleDomain[0] &&
        eventData.event_ts[1] >= timeScaleDomain[1])
    )
  }

/**
 * Calculates the overlapping time between two events.
 * @param event1 - The first event.
 * @param event2 - The second event.
 * @returns The duration of the overlapping time in milliseconds.
 */
export function overlappingTimeBetweenEvents(
  event1: BaseEvent,
  event2: BaseEvent
): number {
  const [start1, end1] = [event1.startTS, event1.endTS]
  const [start2, end2] = [event2.startTS, event2.endTS]
  const start = Math.max(start1, start2)
  const end = Math.min(end1, end2)
  return Math.max(0, end - start)
}

/**
 * Calculates the sum of overlapping time between two arrays of events.
 * @param events1 The first array of events.
 * @param events2 The second array of events.
 * @returns The sum of overlapping time between the two arrays of events.
 */
export function getSumOfOverlappingTimeBetweenTwoEventArrays(
  events1: BaseEvent[],
  events2: BaseEvent[]
): number {
  let sum = 0
  events1 &&
    events1.forEach((event1) => {
      events2 &&
        events2.forEach((event2) => {
          if (event1.startTS > event2.endTS || event1.endTS < event2.startTS) {
            return
          }
          sum += overlappingTimeBetweenEvents(event1, event2)
        })
    })
  return sum
}

export const saveEventDataConverter = (eventData: ScorerEventData) => {
  return {
    uuid: eventData.id,
    startTS: eventData.event_ts[0],
    endTS: eventData.event_ts[1],
    autogenerated: eventData?.autogenerated,
    addedBy: eventData.addedBy,
    addedOn: eventData.addedOn,
    removed: eventData.removed,
    removedBy: eventData.removedBy,
    removedOn: eventData.removedOn,
    ...(eventData.plot === "SpO2" && {
      event_data: {
        max_spo2: eventData.event_data?.max_spo2,
        min_spo2: eventData.event_data?.min_spo2,
      },
    }),
    ...(eventData.plot === "ECG" &&
      eventData.type === "Rhythms" && {
        event_data: {
          label: rhythmsDefaultLabel[eventData.label as string],
        },
      }),
  }
}

export const saveBeatsEventDataConverter = (eventData: ScorerEventData) => {
  return {
    uuid: eventData.id,
    ts: eventData.event_ts[0],
    autogenerated: eventData?.autogenerated,
    addedBy: eventData.addedBy,
    addedOn: eventData.addedOn,
    removed: eventData.removed,
    removedBy: eventData.removedBy,
    removedOn: eventData.removedOn,
    event_data: {
      label: beatsDefaultLabel[eventData.label as string],
    },
  }
}

export const eventApiData = (
  eventData: EventApi,
  plotType: EventPlot,
  eventType: EventType,
  studyID: string,
  scoringCriteria?: ScoringCriteria
) => {
  const event_ts =
    eventType === "Beats"
      ? [eventData.ts, eventData.ts]
      : [eventData.startTS, eventData.endTS]
  const label =
    plotType === "ECG" && eventType === "Rhythms"
      ? rhythmsLabel[eventData.event_data.label]
      : plotType === "ECG" && eventType === "Beats"
      ? beatsLabel[eventData.event_data.label]
      : undefined
  const result = {
    ...(label && { label }),
  }
  return {
    id: eventData?.uuid ? eventData.uuid : uuidv1(),
    plot: plotType,
    studyID,
    type: eventType,
    event_ts: event_ts,
    event_data: {
      max_spo2: Number(eventData?.event_data?.max_spo2) || null,
      min_spo2: Number(eventData?.event_data?.min_spo2) || null,
    },
    autogenerated: eventData?.autogenerated,
    addedBy: eventData?.addedBy,
    addedOn: eventData?.addedOn,
    removed: eventData?.removed,
    removedBy: eventData?.removedBy,
    removedOn: eventData?.removedOn,
    result,
    ...(scoringCriteria && { scoringCriteria }),
  }
}

/**
 * Converts event data to scorer event data format.
 *
 * @param eventData - The array of events to be converted.
 * @param plotType - The type of plot for the events.
 * @param eventType - The type of event.
 * @param studyID - The ID of the study.
 * @returns The converted scorer event data array.
 */
export const eventApiDataConverter = (
  eventData: EventApi[],
  plotType: EventPlot,
  eventType: EventType,
  studyID: string,
  scoringCriteria?: ScoringCriteria
): ScorerEventData[] => {
  return eventData.map((event: EventApi) =>
    eventApiData(event, plotType, eventType, studyID, scoringCriteria)
  )
}

/**
 * Filters a signal based on a specified time range. Inclusive of start and end
 * @param signal - The signal to be filtered.
 * @param startTime - The start time of the filter range.
 * @param endTime - The end time of the filter range.
 * @returns The filtered signal within the specified time range.
 */
export const timeFilteredSignal = (
  signal: Signal,
  startTime: number,
  endTime: number
): Signal => {
  //check that length of signal timestamps and values are the same
  if (signal.timestamps.length !== signal.values.length) {
    throw new Error("Timestamps and values length do not match")
  }

  if (
    startTime > signal.timestamps[signal.timestamps.length - 1] ||
    endTime < signal.timestamps[0]
  ) {
    return {
      timestamps: [],
      values: [],
    }
  }

  //get index of first timestamp element matching start time
  let startIndex = signal.timestamps.findIndex(
    (timestamp) => timestamp >= startTime
  )
  if (startIndex === -1) {
    startIndex = 0
  }

  //get index of last timestamp element matching end time
  let endIndex = signal.timestamps.findIndex((timestamp) => timestamp > endTime)
  if (endIndex === -1) {
    endIndex = signal.timestamps.length - 1
  }

  return {
    timestamps: signal.timestamps.slice(startIndex, endIndex),
    values: signal.values.slice(startIndex, endIndex),
  }
}

// Function to convert an integer to a flag
export function intToSpo2Flag(value: number): DetectionFlags | null {
  for (const flag in DetectionFlags) {
    if (!isNaN(Number(flag))) {
      const flagValue = Number(flag)
      if ((value & flagValue) === flagValue) {
        return flagValue as DetectionFlags
      }
    }
  }
  return null // Return null if no matching flag is found
}

export const leadOffToExclusion = (ecgEventData: EventApi[]) => {
  return ecgEventData.map((data: EventApi) => {
    return { ...data, label: "leads_off_events" }
  }) as unknown as ExclusionAnnotation[]
}

/**
 * eventRemove is a recoil callback to handle deleting
 * an event window.
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventRemove =
  (callback: CallbackInterface) =>
  (
    eventID: string | undefined,
    studyID: string | undefined,
    eventData: ScorerEventData,
    userId: string | undefined
  ) =>
  () => {
    if (studyID !== undefined && eventID !== undefined) {
      callback.set(eventFamily(eventData.id), {
        ...eventData,
        removed: true,
        removedOn: new Date().getTime() / 1000,
        removedBy: userId,
      })
    }
  }

/**
 * eventMakeSelected is a recoil callback to handle making
 * an event window selected (last element of array).
 *
 * @param callback
 * @returns callbackHandler
 */
/**
 * eventMakeSelected is a recoil callback to handle making
 * an event window selected (last element of array).
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventMakeSelected =
  (callback: CallbackInterface) => (eventID: string, events: string[]) => {
    // Move the changed event to end of event list
    // So it's always on top
    const findIndex = events.findIndex((eID) => eventID === eID)

    if (findIndex === undefined)
      throw new Error("unable to find event to move or resize")

    const updatedEvents = [...events]
    updatedEvents.splice(findIndex, 1)
    updatedEvents.push(eventID)

    callback.set(eventIDs, updatedEvents)
  }

/**
 * eventMoveResize is a recoil callback to handle moving or
 * resizing an event window
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventMoveResize =
  (callback: CallbackInterface) =>
  (
    eventData: ScorerEventData,
    timeScale: ScaleLinear<number, number, never>,
    newRangePx: number[],
    data: Signal
  ) => {
    const startingInterval = binarySearchData(
      data,
      timeScale.invert(newRangePx[0])
    )
    const endingInterval = binarySearchData(
      data,
      timeScale.invert(newRangePx[1])
    )

    const { max, min } = findMaxMinValues(
      data,
      startingInterval,
      endingInterval
    )

    callback.set(eventFamily(eventData.id), {
      // Keep other event values intact
      ...eventData,

      // set new range values
      event_ts: [
        // Convert the pixels to timescale
        timeScale.invert(newRangePx[0]),
        timeScale.invert(newRangePx[1]),
      ],
      event_data: {
        max_spo2: max,
        min_spo2: min,
      },
      autogenerated: false,
    })
    callback.set(editEventExclusionStatus, true)
  }

/**
 * eventTypeSelection
 * event type.
 *
 * @param callback
 * @returns callbackHandler
 */
export const eventTypeSelection =
  (callback: CallbackInterface) =>
  (eventData: ScorerEventData) =>
  (updatedType: EventType) => {
    callback.set(eventFamily(eventData.id), {
      ...eventData,
      type: updatedType,
    })
    callback.set(editEventExclusionStatus, true)
  }
/**
 * handleEventDrag is a callback to handle changing the
 * event's position on drag. Will activate scroll routine
 * via setScrollMode.
 *
 * @param {HandleEventDragParams} handleEventDragParams
 * @returns {DragMoveCallback} DragMoveCallback
 */

export const handleEventDrag = ({
  range,
  parentWidth,
  widthPx,
  moveEventStart,
  eventID,
  allPlotEvents,
  plot,
  data,
  timeScale,
  scrollRoutine,
  moveEventStartRel,
  xOffset,
  dragThresholds,
  setSnackAlertMsg,
  setMoveEventStart,
  setNewRange,
  setScrollMode,
}: HandleEventDragParams): DragMoveCallback => {
  return (_: Point, absPoint: Point) => {
    if (moveEventStart === undefined || plot === "ECG") return

    const ctx = scrollRoutine.current.additional as ScrollRoutineContext

    if (ctx.rangePx === undefined) {
      return
    }

    const changeFromStart = absPoint.x - moveEventStart.x
    const start = range[0] + changeFromStart

    const rangeEndUnmodified = range[0] + range[1]
    const rangeEnd = start + range[1]

    const eventIntersectionDetector = eventIntersector({
      eventID,
      widthPx,
      parentWidth,
      allPlotEvents,
      exclusions: ctx.exclusionData,
      timeScale,
    })

    // Set stateful vars for scroll epoch edge case
    // The user will drag, unless the start or end are visible
    // at some point during drag, and the predicted drag
    // (start or end) goes off screen.
    // Basically we drag when one or both of the event windows is offscreen
    if (rangeEnd < parentWidth) {
      dragThresholds.current.brokeRightThreshold = true
    }
    if (start > 0) {
      dragThresholds.current.brokeLeftThreshold = true
    }

    // trigger right scroll
    if (
      rangeEnd > parentWidth &&
      ((changeFromStart > 0 && rangeEndUnmodified <= parentWidth) ||
        dragThresholds.current.brokeRightThreshold)
    ) {
      dragThresholds.current.brokeLeftThreshold = false
      const endOfPlotStart = parentWidth - widthPx
      const endOfPlotEnd = parentWidth

      const intersect = eventIntersectionDetector(endOfPlotStart, endOfPlotEnd)

      // Don't activate scroll mode if the window positioned at end
      // of plot would intersect
      if (intersect.intersectedWith !== undefined) {
        setSnackAlertMsg(scrollError)
        return
      }

      // Trigger scroll routine in parent
      setScrollMode({
        method: "drag",
        orientation: "right",
        // times per second
        speed: Math.min(10, Math.max((rangeEnd - parentWidth) / 10, 1)),
      })

      const startPx = ctx.rangePx[0]

      // Ensure the event window is flush with end of plot before scroller startup
      if ((startPx + widthPx).toFixed(2) !== parentWidth.toFixed(2)) {
        setNewRange([endOfPlotStart, endOfPlotEnd])
      }

      return

      // Trigger left scroll routine in parent
    } else if (
      start < 0 &&
      ((changeFromStart < 0 && range[0] >= 0) ||
        dragThresholds.current.brokeLeftThreshold)
    ) {
      dragThresholds.current.brokeRightThreshold = false

      const startOfPlotStart = 0
      const startOfPlotEnd = widthPx

      const intersect = eventIntersectionDetector(
        startOfPlotStart,
        startOfPlotEnd
      )

      // Don't activate scroll mode if the window positioned at start
      // of plot would intersect
      if (intersect.intersectedWith !== undefined) {
        setSnackAlertMsg(scrollError)
        return
      }

      // Trigger state change in parent
      setScrollMode({
        method: "drag",
        orientation: "left",
        speed: Math.min(10, Math.max(Math.abs(start) / 10, 1)),
      })

      // Ensure the event window is flush with start of plot before scroller startup
      if (ctx.rangePx[0] !== startOfPlotStart)
        setNewRange([startOfPlotStart, startOfPlotEnd])

      return
    }

    if (scrollRoutine.current.started) {
      // Get scrollMode orientation so we know how to reset setMoveEventStart
      const ctx = scrollRoutine.current.additional as ScrollRoutineContext

      // X coord reletive to where the mouse clicked in event
      const moveStartRel = moveEventStartRel?.x as number

      // Right
      let newX = parentWidth - widthPx + moveStartRel + xOffset

      // Left
      if (ctx.scrollMode?.orientation === "left") {
        newX = xOffset + moveStartRel
      }

      // Trigger stop in useScrollRoutine
      setScrollMode(undefined)

      // Refresh useWindowDrag here
      setMoveEventStart({
        // end of plot, minus where the range was originally clicked
        x: newX,
        // x: absPoint.x,
        y: moveEventStart.y,
      })

      return
    }

    const end = start + widthPx

    // Don't allow event overlap
    const intersect = eventIntersectionDetector(start, end)

    // there was an overlap intersection or new location goes off
    // grid, do nothing
    if (intersect.suggestedRange === undefined) {
      setSnackAlertMsg(moveError)
      return
    }

    setNewRange([intersect.suggestedRange[0], intersect.suggestedRange[1]])
  }
}

/**
 * handleEventResize is a callback for handling resizing an event window. It
 * prevents windows from being resized outside of visible plot area, or on top
 * of other events.
 *
 * @param {HandleEventResizeParams} handleEventResizeParams
 * @returns {DragMoveCallback} DragMoveCallback
 */

export const handleEventResize =
  ({
    orientation,
    resizeEventStart,
    range,
    parentWidth,
    allPlotEvents,
    timeScale,
    eventData,
    scrollRoutine,
    xOffset,
    moveEventStartRel,
    setMoveEventStart,
    setNewRange,
    setScrollMode,
    setSnackAlertMsg,
    setEditEventExclusionStatus,
  }: HandleEventResizeParams): DragMoveCallback =>
  (_: Point, absPoint: Point) => {
    if (resizeEventStart === undefined) return

    const ctx = scrollRoutine.current.additional as ScrollRoutineContext
    if (ctx.rangePx === undefined) return

    const changeFromStart = absPoint.x - resizeEventStart.x

    const eventIntersectionDetector = eventIntersector({
      eventID: eventData?.id,
      widthPx: ctx.rangePx[1],
      exclusions: ctx.exclusionData,
      parentWidth,
      allPlotEvents: ctx.allPlotEvents,
      timeScale: ctx.timeScale,
    })

    if (orientation === "right") {
      const rangeData = range
      let width = range[1] + changeFromStart

      //prevent from reducing to less than 2 seconds
      const mindist = Math.abs(timeScale(2) - timeScale(0))
      // Prevent inverting selection
      if (width <= mindist) {
        width = mindist
      }

      const rangeEnd = rangeData[0] + width

      // If the range end is greater than parent width
      if (rangeEnd > parentWidth) {
        const endOfWindowPx = ctx.rangePx[0] + ctx.rangePx[1] + changeFromStart

        const intersect = eventIntersectionDetector(
          ctx.rangePx[0],
          endOfWindowPx
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        setScrollMode({
          method: "resize",
          orientation: "right",
          // times per second
          speed: Math.min(10, Math.max((rangeEnd - parentWidth) / 10, 1)),
        })

        // Ensure the event window is flush with end of plot before scroller startup
        if (
          (ctx.rangePx[0] + ctx.rangePx[1]).toFixed(2) !==
          parentWidth.toFixed(2)
        ) {
          setNewRange([ctx.rangePx[0], parentWidth])
          setEditEventExclusionStatus(true)
        }

        return
      }

      if (scrollRoutine.current.started) {
        // X coord reletive to where the mouse clicked in event
        const moveStartRel = moveEventStartRel?.x as number

        // Right
        const newX = parentWidth - handleWidth + moveStartRel + xOffset

        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setMoveEventStart({
          // end of plot, minus where the range was originally clicked
          x: newX,
          // x: absPoint.x,
          y: resizeEventStart.y,
        })
        setEditEventExclusionStatus(true)

        return
      }

      let end = rangeData[0] + width

      // Prevent event from exceding any start
      const intersectEventStart = findEventIntersectStart(
        allPlotEvents,
        ctx.exclusionData,
        timeScale,
        eventData?.id,
        rangeData[0],
        end
      )

      if (intersectEventStart !== undefined) {
        end = intersectEventStart
      }

      setNewRange([rangeData[0], end])
      setEditEventExclusionStatus(true)
    } else if (orientation === "left") {
      // Extend width for new left start
      let width =
        range[1] +
        (changeFromStart < 0 ? Math.abs(changeFromStart) : changeFromStart * -1)

      // Prevent left dragging moving start
      if (width <= minEventWidth) {
        return
      }

      // Prevent inverting selection
      //prevent from reducing to less than 2 seconds
      const mindist = Math.abs(timeScale(2) - timeScale(0))

      if (width <= mindist) {
        width = mindist
      }

      let start = range[0] + changeFromStart

      // Prevent left overflow
      if (start < 0) {
        const endOfWindowPx = ctx.rangePx[0] + ctx.rangePx[1]

        const intersect = eventIntersectionDetector(
          ctx.rangePx[0] + changeFromStart,
          endOfWindowPx
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        setScrollMode({
          method: "resize",
          orientation: "left",
          // times per second
          speed: Math.min(10, Math.max(Math.abs(start) / 10, 1)),
        })

        // Ensure the event window is flush with end of plot before scroller startup
        if (ctx.rangePx[0].toFixed(2) !== (0).toFixed(2)) {
          setNewRange([0, endOfWindowPx])
          setEditEventExclusionStatus(true)
        }

        return
      }

      if (scrollRoutine.current.started) {
        // X coord reletive to where the mouse clicked in event
        const moveStartRel = moveEventStartRel?.x as number

        // Right
        const newX = xOffset + moveStartRel

        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setMoveEventStart({
          // end of plot, minus where the range was originally clicked
          x: newX,
          // x: absPoint.x,
          y: resizeEventStart.y,
        })
        setEditEventExclusionStatus(true)

        return
      }

      const end = start + width

      // Prevent event start from being less than any end
      const intersectEventEnd = findEventIntersectEnd(
        allPlotEvents,
        ctx.exclusionData,
        timeScale,
        eventData?.id,
        start,
        end
      )

      if (intersectEventEnd !== undefined) {
        start = intersectEventEnd
      }

      setNewRange([start, end])
      setEditEventExclusionStatus(true)
    }
  }
