import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  useBackendAuthenticator,
  useCurrentUser,
} from "./hooks/useAuthenticator"
import AdminRoute from "./views/admin"
import { CognitoLogin } from "./views/auth/CognitoLogin"
import NotFoundView from "./views/NotFound"
import AuthenticatedLayout from "./views/layout/authenticated"
import AdminStudiesContent from "./views/internal/studies/AdminStudiesContent"
import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { MFAView } from "./views/auth/mfa"
import { UserProfile } from "./views/profile/ProfileView"
import { useIdleTimer } from "react-idle-timer"
import { JWT, currentUser, isHuxleyUser } from "./state/auth.state"
import PatientsRoute from "./views/patients"
import StudiesRoute from "./views/studies"
import StudyScoringContent from "./views/scoring/StudyScoringContent"
import UsersRoute from "./views/users"
import DevicesRoute from "./views/devices"
import OrganizationRoute from "./views/organization"

/* Note from amplify documents on below: If you're logged in and refresh the browser, 
the route may temporarily be set to idle or setup while it loads. That will cause a 
redirection back to the /login page, which in turn will redirect back to the protected 
route. The user will not notice anything, and the protected route will be displayed. 
However, if you like to have more control of this process you can use 
Auth.currentAuthenticatedUser() instead. */
export const getJWT = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const JWTvalue = user.signInUserSession.getAccessToken().getJwtToken()
    return JWTvalue
  } catch (error) {
    console.log(error) //TODO Remove this when we add sentry reporting
  }
}

export function RequireAuth() {
  const location = useLocation()
  const navigate = useNavigate()
  const setJWT = useSetRecoilState(JWT)
  const isAdmin = useRecoilValue(isHuxleyUser)
  const onIdle = () => {
    if (isAdmin) {
      Auth.signOut()
    }
  }

  useIdleTimer({
    onIdle,
    timeout: 15 * 60 * 1000, // 15 minutes
    throttle: 1000,
  })

  useBackendAuthenticator()
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          const jwtValue = user.signInUserSession.getAccessToken().getJwtToken()
          if (
            user.signInUserSession.getAccessToken().decodePayload()[
              "token_use"
            ] === "id"
          ) {
            setJWT(jwtValue)
          }
        } else {
          return <Navigate to="/login" state={{ from: location }} replace />
        }
      } catch (error) {
        navigate("/login")
      }
    }
    checkAuthentication()
  }, []) // Empty dependency array to run the effect once on mount
  useCurrentUser()
  return <Outlet />
}

export function RequireHuxleyUser() {
  const isAdmin = useRecoilValue(isHuxleyUser)

  if (isAdmin === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (isAdmin) {
    return <Outlet />
  } else {
    return <NotFoundView />
  }
}

export function RequireHuxleyAdminOrOrgAdmin() {
  const user = useRecoilValue(currentUser)

  if (user === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (
    user.primaryRole.includes("Huxley") ||
    user.primaryRole === "OrgAdmin"
  ) {
    return <Outlet />
  } else {
    return <NotFoundView />
  }
}

export function DashboardLayout() {
  const isAdmin = useRecoilValue(isHuxleyUser)

  if (isAdmin === undefined) {
    // Avoid a flash of the not found view while authenticating
    return <></>
  } else if (isAdmin) {
    return <Navigate to="/internal" replace />
  } else {
    return <Navigate to="/studies" replace />
  }
}

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<CognitoLogin />} />
      <Route>
        <Route element={<RequireAuth />}>
          <Route element={<AuthenticatedLayout />}>
            <Route path="/mfa" element={<MFAView />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/" element={<DashboardLayout />} />
            <Route path="/studies" element={<StudiesRoute />} />
            <Route path="/dataset/:studyID" element={<StudyScoringContent />} />
            <Route path="/patients/:patientID?" element={<PatientsRoute />} />
            <Route element={<RequireHuxleyUser />}>
              <Route path="/admin" element={<AdminRoute />} />
              <Route path="/internal" element={<AdminStudiesContent />} />
              <Route path="/devices" element={<DevicesRoute />} />
              <Route path="/organizations" element={<OrganizationRoute />} />
            </Route>
            <Route element={<RequireHuxleyAdminOrOrgAdmin />}>
              <Route path="/users" element={<UsersRoute />} />
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Route here for 404*/}
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  )
}

export default Router
