import React from "react"
import { useRecoilValue } from "recoil"
import {
  activeCriteria,
  currentStudy,
  studyMetaData3,
} from "../../state/study.state"
import { sleepInfo } from "../../state/signals.state"
import {
  desatMetrics,
  desatOdiAhiMetrics,
  hrMetrics,
  spo2Metrics,
  summaryIndices3,
  summaryIndices4,
} from "../../state/graphSummary.state"
import { timeStampConverter } from "../../utils/seriesUtils"
import { formatDateTime, secondsToHms } from "../../utils/timeUtils"
import { ScoringCriteria } from "../../types/event.type"
import PdfTemplate from "./templates/PdfTemplate"
import PdfPatientInformation from "./templates/PdfPatientInformation"
import EventSummary from "./templates/EventSummary"
import SleepSummary from "./templates/SleepSummary"
import SpO2Stats from "./templates/SpO2Stats"
import DesatEventCount from "./templates/DesatEventCount"
import HeartRateStatistics from "./templates/HeartRateStatistics"
import TotalBeatsAnalyzed from "./templates/TotalBeatsAnalyzed"
import PdfClinicianSignature from "./templates/PdfClinicianSignature"
import { formatUTCDate } from "../../utils/utils"

const PdfStudySummary: React.FC<{
  interpretationNotesData: boolean
}> = ({ interpretationNotesData }) => {
  const selectedScoringCriteria = useRecoilValue(activeCriteria)
  const studyMetaDataValue = useRecoilValue(studyMetaData3)
  const sleepInfoValue = useRecoilValue(sleepInfo)
  const spo2metrics = useRecoilValue(spo2Metrics)
  const hrmetrics = useRecoilValue(hrMetrics)
  const desatmetrics = useRecoilValue(desatMetrics)
  const study = useRecoilValue(currentStudy)
  const desatOdiAhiMetricsData = useRecoilValue(desatOdiAhiMetrics)
  const summaryindices3 = useRecoilValue(summaryIndices3)
  const summaryindices4 = useRecoilValue(summaryIndices4)
  const { studyDate, studyEndDate } = {
    studyDate: new Date(timeStampConverter(studyMetaDataValue.studyStartTime)),
    studyEndDate: new Date(timeStampConverter(studyMetaDataValue.studyEndTime)),
  }
  const totalStudyTime =
    studyMetaDataValue.studyEndTime - studyMetaDataValue.studyStartTime
  const totalStudyTimeString = secondsToHms(totalStudyTime)
  const sleep_efficiency =
    (sleepInfoValue.sleepDurationSeconds / totalStudyTime) * 100
  const totalSleepString = secondsToHms(sleepInfoValue.sleepDurationSeconds)
  const effSleepTimeString = secondsToHms(sleepInfoValue.effSleepTime)
  const meanOfDesatNadirs = desatmetrics.meanDesatNadir.toFixed(1)

  const numBeats =
    selectedScoringCriteria === ScoringCriteria.THREE
      ? summaryindices3?.num_heart_beats !== undefined
        ? String(summaryindices3.num_heart_beats)
        : "N/A"
      : summaryindices4?.num_heart_beats !== undefined
      ? String(summaryindices4.num_heart_beats)
      : "N/A"

  const scoringcrit =
    selectedScoringCriteria === ScoringCriteria.THREE ? "AHI-3%" : "AHI-4%"

  const pdfTemplateData = {
    study_id: study?.id,
    sw_version: studyMetaDataValue.swVersion,
  }

  const pdfPatientInformationData = {
    patient_name: study?.patient?.name,
    dob: study?.patient?.dob && formatUTCDate(study?.patient?.dob),
    mrn: study?.patient?.mrn,
    referring_physician: study?.patient?.referringPhysician ?? "",
    interpreting_physician: study?.studyOrder?.interpretingPhysician.name,
    scoring_criteria: scoringcrit,
    study_date: formatDateTime(studyDate, "date"),
    study_start_time: formatDateTime(studyDate, "time"),
    study_end_time: formatDateTime(studyEndDate, "time"),
  }

  const pdfEventSummary = {
    sahi_3: desatOdiAhiMetricsData.ahi3?.toFixed(1) ?? "N/A",
    sahi_4: desatOdiAhiMetricsData.ahi4?.toFixed(1) ?? "N/A",
    odi_3: desatOdiAhiMetricsData.odi3?.toFixed(1) ?? "N/A",
    odi_4: desatOdiAhiMetricsData.odi4?.toFixed(1) ?? "N/A",
    total_resp_events: desatmetrics.totalEvents ?? "N/A",
  }

  const pdfSleepSummary = {
    total_study_time: totalStudyTimeString,
    sleep_time: totalSleepString,
    effective_sleep_time: effSleepTimeString,
    sleep_latency: secondsToHms(sleepInfoValue.sleepLatencySeconds),
    sleep_efficiency: sleep_efficiency.toFixed(0),
  }

  const spO2Statistics = {
    min: spo2metrics.minSpo2.toFixed(1),
    mean: spo2metrics.meanSpo2.toFixed(1),
    max: spo2metrics.maxSpo2.toFixed(1),
  }

  const desatEventCountData = {
    range_4_9: desatmetrics.desatsBelow9,
    range_10_20: desatmetrics.desatsNineTo20,
    range_gt_20: desatmetrics.desatsAbove20,
    total:
      desatmetrics.desatsBelow9 +
      desatmetrics.desatsNineTo20 +
      desatmetrics.desatsAbove20,
  }
  const heartRateStatisticsData = {
    awake: {
      min: hrmetrics.minHr.toFixed(0),
      mean: hrmetrics.meanHr.toFixed(0),
      max: hrmetrics.maxHr.toFixed(0),
    },
    sleep: {
      min: hrmetrics.minHrSleeping.toFixed(0),
      mean: hrmetrics.meanHrSleeping.toFixed(0),
      max: hrmetrics.maxHrSleeping.toFixed(0),
    },
  }

  return (
    <PdfTemplate
      study_id={pdfTemplateData.study_id}
      sw_version={pdfTemplateData.sw_version}
    >
      <div className="section-header">Study Information</div>
      <PdfPatientInformation
        patient_name={pdfPatientInformationData.patient_name}
        dob={pdfPatientInformationData.dob}
        mrn={pdfPatientInformationData.mrn}
        referring_physician={pdfPatientInformationData.referring_physician}
        interpreting_physician={
          pdfPatientInformationData.interpreting_physician
        }
        scoring_criteria={pdfPatientInformationData.scoring_criteria}
        study_date={pdfPatientInformationData.study_date}
        study_start_time={pdfPatientInformationData.study_start_time}
        study_end_time={pdfPatientInformationData.study_end_time}
      />
      <div className="section-header">Summary Data</div>
      <div id="summary">
        <div className="summary-column">
          <EventSummary
            sahi_3={pdfEventSummary.sahi_3}
            sahi_4={pdfEventSummary.sahi_4}
            odi_3={pdfEventSummary.odi_3}
            odi_4={pdfEventSummary.odi_4}
            total_resp_events={pdfEventSummary.total_resp_events}
          />
        </div>
        <div className="summary-column">
          <SleepSummary
            total_study_time={pdfSleepSummary.total_study_time}
            sleep_time={pdfSleepSummary.sleep_time}
            effective_sleep_time={pdfSleepSummary.effective_sleep_time}
            sleep_latency={pdfSleepSummary.sleep_latency}
            sleep_efficiency={pdfSleepSummary.sleep_efficiency}
          />
        </div>
        <div className="summary-column">
          <SpO2Stats
            min={spO2Statistics.min}
            mean={spO2Statistics.mean}
            max={spO2Statistics.max}
          />
          <DesatEventCount
            range_4_9={desatEventCountData.range_4_9}
            range_10_20={desatEventCountData.range_10_20}
            range_gt_20={desatEventCountData.range_gt_20}
            total={desatEventCountData.total}
            meanOfDesatNadirs={meanOfDesatNadirs}
            selectedScoringCriteria={selectedScoringCriteria}
          />
        </div>
        <div className="summary-column">
          <HeartRateStatistics
            awakemin={heartRateStatisticsData.awake.min}
            awakemean={heartRateStatisticsData.awake.mean}
            awakemax={heartRateStatisticsData.awake.max}
            sleepmin={heartRateStatisticsData.sleep.min}
            sleepmean={heartRateStatisticsData.sleep.mean}
            sleepmax={heartRateStatisticsData.sleep.max}
          />
          <TotalBeatsAnalyzed total_beats_analyzed={numBeats} />
        </div>
      </div>
      <div className="graph-placeholder"></div>
      {interpretationNotesData ? (
        <PdfClinicianSignature />
      ) : (
        <div className="empty-block"></div>
      )}
    </PdfTemplate>
  )
}

export default PdfStudySummary
