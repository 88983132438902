import React, { useState } from "react"
import { IconButton, styled, Typography } from "@mui/material"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import ExpandIcon from "@mui/icons-material/Expand"
import Zoom from "@mui/material/Zoom"
import Slide from "@mui/material/Slide"
import Box from "../base/Box"
import { Div } from "../../elements"

const StyledSidebar = styled("div")({
  minWidth: "350px",
  padding: "25px",
  paddingRight: "0",
  "& .MuiIconButton-root": {
    float: "right",
    backgroundColor: "#D9E9F5",
    color: "#006BB9",
  },
})

const StyledMinimizedSidebar = styled("div")({
  width: "auto",
  padding: "25px",
  paddingRight: "0",
  fontSize: "16px",
  color: "#006BB9",
  fontWeight: 500,
  "& .MuiIconButton-root": {
    backgroundColor: "#D9E9F5",
    color: "#006BB9",
    transform: "rotate(90deg)",
    width: "26px",
    height: "26px",
    marginBottom: "10px",
  },
  "& .MuiIconButton-root svg": {
    width: "16px",
    height: "16px",
  },
})

type SidebarParams = {
  children?: React.ReactNode
  title: string
}

const Sidebar = ({ children, title = "Sidebar Title" }: SidebarParams) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return !isExpanded ? (
    <StyledMinimizedSidebar className="styled-minimized-sidebar">
      <Zoom in={!isExpanded}>
        <Box
          sx={{
            display: "inline-block",
            padding: "10px",
            cursor: "pointer",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
          onClick={() => {
            setIsExpanded(true)
          }}
        >
          <IconButton aria-label="expand" size="small">
            <ExpandIcon />
          </IconButton>
          <Div
            sx={{
              color: "#006BB9",
              writingMode: "tb-rl",
              transform: "rotate(180deg)",
            }}
          >
            {title}
          </Div>
        </Box>
      </Zoom>
    </StyledMinimizedSidebar>
  ) : (
    <Slide direction="right" in={isExpanded}>
      <StyledSidebar className="styled-sidebar">
        <Typography
          variant="h4"
          sx={{
            marginBottom: "6px",
            lineHeight: "30px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          {title}
          <IconButton
            onClick={() => {
              setIsExpanded(false)
            }}
            aria-label="colapse"
            size="small"
          >
            <CloseFullscreenIcon fontSize="inherit" />
          </IconButton>
        </Typography>

        {children}
      </StyledSidebar>
    </Slide>
  )
}

export default Sidebar
