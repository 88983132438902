import { PatientOut } from "../generated/fetchclient"
import { atom } from "recoil"

export const patients = atom<PatientOut[]>({
  key: "patients",
  default: [],
})

export const patientCount = atom<number>({
  key: "patientCount",
  default: 0,
})
