import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"

export const createBasicGridColDef = (
  field: string,
  headerName: string,
  flex = 8,
  editable = false
): GridColDef => {
  return {
    field,
    headerName,
    flex,
    editable,
  }
}
