import { styled, SxProps, Theme } from "@mui/material"
import React from "react"

const StyledAppFooterBar = styled("footer")({
  position: "fixed",
  width: "100%",
  bottom: 0,
  left: 0,
  boxShadow: "2.02937px -4px 30.4406px rgba(236, 236, 236, 0.6)",
  borderTop: "1px solid #EFEEF4",
  paddingLeft: "25px",
  paddingRight: "25px",
  zIndex: "2",
  backgroundColor: "#FFF",
})

type AppFooterBarParams = {
  sx?: SxProps<Theme> | undefined
  children?: React.ReactNode
}

const AppFooterBar = ({ sx, children }: AppFooterBarParams) => {
  return <StyledAppFooterBar sx={sx}>{children}</StyledAppFooterBar>
}

export default AppFooterBar
