import { Text } from "@visx/text"
import { EventPlot } from "../../../types/event.type"

interface GraphPlotLeftAxisLabelProps {
  labelWidth: number
  lineHeight: number
  lineColor: string
  title: string
  plotType: EventPlot
  handleOpenScaleModal: (plotType: EventPlot) => void
}

const GraphPlotLeftAxisLabel = ({
  labelWidth,
  lineHeight,
  lineColor,
  handleOpenScaleModal,
  plotType,
  title,
}: GraphPlotLeftAxisLabelProps) => {
  return (
    <g onClick={() => handleOpenScaleModal(plotType)}>
      <rect
        x={0}
        y={0}
        width={labelWidth}
        height={lineHeight}
        fill="transparent"
        cursor="pointer"
      />

      <Text
        textAnchor="middle"
        dominantBaseline="middle"
        x={labelWidth / 2 - 10}
        y={lineHeight / 2}
        angle={270}
        fontSize="12px"
        fontWeight={"bold"}
        fill={lineColor}
        width={55}
        height={lineHeight}
        overflow={"hidden"}
        white-space={"nowrap"}
        text-overflow={"ellipsis"}
        cursor={"pointer"}
      >
        {title}
      </Text>
    </g>
  )
}

export default GraphPlotLeftAxisLabel
