import { defaultPlotEventTypeMap } from "../../../../const/event.const"
import { HandleCreateEventParams } from "../../../../types/handlers.type"
import { ScrollRoutineContext } from "../../../../types/scroller.type"
import { v1 as uuidv1 } from "uuid"

const handleClickCreateEvent = async ({
  mouseCoordinates,
  plot,
  width,
  allPlotEvents,
  studyID,
  plotData,
  scrollRoutine,
  currentUserData,
  endDraw,
  createEvent,
  selectedScoringCriteria,
  setEditEventExclusionStatus,
}: HandleCreateEventParams) => {
  const ctx = scrollRoutine.current.additional as ScrollRoutineContext

  await createEvent({
    id: uuidv1(),
    plot,
    studyID,
    addedBy: currentUserData?.uuid,
    type: defaultPlotEventTypeMap[plot],
    event_ts: [
      ctx.timeScale.invert(mouseCoordinates?.x ?? 0),
      ctx.timeScale.invert(mouseCoordinates?.x ?? 0) + 1,
    ],
    addedOn: new Date().getTime() / 1000,
    removed: false,
    removedBy: null,
    removedOn: null,
    autogenerated: false,
    scoringCriteria: selectedScoringCriteria,
  })
  setEditEventExclusionStatus(true)
  endDraw()
}

export default handleClickCreateEvent
