import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import Stack from "@mui/joy/Stack"
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup"
import { activeCriteria, isLoadingStudy } from "../../../state/study.state"
import { ScoringCriteria } from "../../../types/event.type"
import Button from "@mui/joy/Button"
import { Typography } from "@mui/joy"

export const scoringCriteriaMapping = {
  [ScoringCriteria.THREE.toString()]: ScoringCriteria.THREE,
  [ScoringCriteria.FOUR.toString()]: ScoringCriteria.FOUR,
}

const SelectionScoringCriteria = () => {
  const scoringSelectedCriteria = useRecoilValue(activeCriteria)
  const setIsLoadingStudy = useSetRecoilState(isLoadingStudy)
  const handleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newValue: string) => {
        setIsLoadingStudy(true)
        await new Promise((resolve) => setTimeout(resolve, 500))
        if (scoringCriteriaMapping[newValue]) {
          set(activeCriteria, scoringCriteriaMapping[newValue])
        }
        await snapshot.getPromise(activeCriteria)
        setIsLoadingStudy(false)
      }
  )
  const changeScoringCriteria = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    event?.preventDefault()
    event?.stopPropagation()
    if (newValue !== null) {
      handleChange(newValue)
    }
  }
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography fontSize="sm">Scoring Criteria</Typography>
      <ToggleButtonGroup
        style={{ zIndex: 0 }}
        value={scoringSelectedCriteria.toString()}
        onChange={changeScoringCriteria}
      >
        <Button value={ScoringCriteria.THREE.toString()}>3%</Button>
        <Button value={ScoringCriteria.FOUR.toString()}>4%</Button>
      </ToggleButtonGroup>
    </Stack>
  )
}

export default SelectionScoringCriteria
