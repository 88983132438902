export const themeColors = {
  palette: {
    primary: {
      main: "#5A97F7",
      light: "#D9E9F5",
      dark: "#5A97F7",
      background: "#F8FAFE",
      fontColor: "#0E003C",
    },
    success: {
      light: "#D2EFCD",
      main: "#b0e597",
      dark: "#30A150",
    },
    error: {
      main: "#D47171",
      dark: "#D76868",
    },
    info: {
      main: "#F1F3F3",
      dark: "#8991AB",
    },
    warning: {
      main: "#FEF0D6",
      dark: "#FBB533",
    },
  },
}
