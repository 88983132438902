import React from "react"

interface SpO2StatsProps {
  min: string
  mean: string
  max: string
}
const SpO2Stats: React.FC<SpO2StatsProps> = ({ min, mean, max }) => {
  return (
    <table className="oxygen-sat-table nadirs">
      <tbody>
        <tr className="table-head">
          <th className="table-head" colSpan={3}>
            SpO2 Statistics (%)
          </th>
        </tr>
        <tr className="center-row">
          <td>Min </td>
          <td>Mean </td>
          <td>Max</td>
        </tr>
        <tr className="center-row">
          <td>{min}</td>
          <td>{mean}</td>
          <td>{max}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default SpO2Stats
