import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Input,
  ModalDialog,
  FormControl,
  FormLabel,
  DialogActions,
  Modal,
} from "@mui/joy"

import { useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import useStudies from "../../hooks/useStudies"
import {
  fulfillStudyModalOpen,
  studyToBeFulfilled,
} from "../../state/modal.state"

function FulfillStudyModal() {
  const [modalOpen, setModalOpenState] = useRecoilState(fulfillStudyModalOpen)
  const studyId = useRecoilValue(studyToBeFulfilled)
  const [deviceId, setDeviceId] = useState("")
  const studies = useStudies()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (studyId === undefined) {
      console.error("studyID is undefined")
      return
    }
    await studies
      .fulfillStudy(studyId, deviceId)
      .then(() => {
        setModalOpenState(false)
        setDeviceId("")
      })
      .catch(() => null)
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={modalOpen}
      onClose={() => {
        setModalOpenState(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog variant="outlined" role="alertdialog">
        <form onSubmit={handleSubmit}>
          <DialogTitle>Fulfill Study</DialogTitle>
          <Divider />
          <DialogContent>
            Enter the Device Serial Number that is being sent to the Patient
          </DialogContent>
          <FormControl>
            <FormLabel>Device Serial Number</FormLabel>
            <Input
              placeholder="Device ID"
              required
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
            />
          </FormControl>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setModalOpenState(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </ModalDialog>
    </Modal>
  )
}

export default FulfillStudyModal
