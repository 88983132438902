import { ReactElement, useEffect, useState } from "react"
import { Grow } from "@mui/material"

const IndexedFade = ({
  children,
  inx,
  maxMs = 1000,
}: {
  maxMs?: number
  inx: number
  children: ReactElement<any, any>
}) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, Math.min(inx * 150, maxMs))
    return () => clearTimeout(timer)
  }, [])

  return (
    <Grow in={show}>
      <div>{children}</div>
    </Grow>
  )
}

export default IndexedFade
