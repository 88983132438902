import React from "react"

interface EventSummaryProps {
  sahi_3: string
  sahi_4: string
  odi_3: string
  odi_4: string
  total_resp_events: string | number
}

const EventSummary: React.FC<EventSummaryProps> = ({
  sahi_3,
  sahi_4,
  odi_3,
  odi_4,
  total_resp_events,
}) => {
  return (
    <table className="data-table">
      <tr>
        <th className="table-head" colSpan={2}>
          <strong>Events Summary</strong>
        </th>
      </tr>
      <tr>
        <td>sAHI 3%</td>
        <td>{sahi_3}</td>
      </tr>
      <tr>
        <td>sAHI 4%</td>
        <td>{sahi_4}</td>
      </tr>
      <tr>
        <td>ODI 3%</td>
        <td>{odi_3}</td>
      </tr>
      <tr>
        <td>ODI 4%</td>
        <td>{odi_4}</td>
      </tr>
      <tr>
        <td>Total Resp Events</td>
        <td>{total_resp_events}</td>
      </tr>
    </table>
  )
}

export default EventSummary
