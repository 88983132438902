/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEcgReportOut
 */
export interface CreateEcgReportOut {
    /**
     * 
     * @type {string}
     * @memberof CreateEcgReportOut
     */
    presignedUrl: string;
}

/**
 * Check if a given object implements the CreateEcgReportOut interface.
 */
export function instanceOfCreateEcgReportOut(value: object): value is CreateEcgReportOut {
    if (!('presignedUrl' in value) || value['presignedUrl'] === undefined) return false;
    return true;
}

export function CreateEcgReportOutFromJSON(json: any): CreateEcgReportOut {
    return CreateEcgReportOutFromJSONTyped(json, false);
}

export function CreateEcgReportOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEcgReportOut {
    if (json == null) {
        return json;
    }
    return {
        
        'presignedUrl': json['presigned_url'],
    };
}

export function CreateEcgReportOutToJSON(value?: CreateEcgReportOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'presigned_url': value['presignedUrl'],
    };
}

