import { RefObject } from "react"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { renderToString } from "react-dom/server"
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil"
import {
  currentStudy,
  fullNightInterval,
  intervalTimeEventTypeAtom,
  studyPlotOrder,
} from "../state/study.state"
import { usePortalApi } from "../connections"
import { EventPlot } from "../types/event.type"
import PdfStudySummary from "../components/pdfreport/PdfStudySummary"
import PdfInterpretationNotes from "../components/pdfreport/PdfInterpretationNotes"
import { uploadedSignatureUrl } from "../state/signature.state"
import useSignature from "./useSignature"
import { currentUser } from "../state/auth.state"
import { getBase64FromUrl } from "../utils/imageUtils"
import { handleApiError } from "../utils/apiUtils"
import { snackAlert } from "../components/SnackAlerts"
import { pdfUrl } from "../state/pdf.state"
import { JSONContent } from "@tiptap/core"
import { isGeneratingPDFReport } from "../state/pdfReport.state"

interface usePrintPdfParams {
  (chartRef: RefObject<HTMLDivElement>, parentRef: RefObject<HTMLDivElement>): (
    interpretationEditorContentData: JSONContent,
    preview?: boolean
  ) => Promise<void>
}

const usePrintPdf: usePrintPdfParams = (chartRef, parentRef) => {
  const api = usePortalApi()
  const user = useRecoilValue(currentUser)
  const [selectedTimeInterval, setSelectedTimeInterval] = useRecoilState(
    intervalTimeEventTypeAtom
  )
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setPdfUrl = useSetRecoilState(pdfUrl)
  const [plotOrder, setPlotOrder] = useRecoilState(studyPlotOrder)
  const newPlotOrder = [...plotOrder]
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const fullNightIntervalValue = useRecoilValue(fullNightInterval)
  const setIsGeneratingPDF = useSetRecoilState(isGeneratingPDFReport)
  const study = useRecoilValue(currentStudy)
  const setSignatureUrl = useSetRecoilState(uploadedSignatureUrl)
  const htmlStringToPdf = async ({
    htmlString,
    interpretationNotesPage = "",
    interpretationNotesData,
    preview = true,
  }: {
    htmlString: string
    interpretationNotesPage: string
    interpretationNotesData: boolean
    preview: boolean
  }) => {
    const currentInterval = selectedTimeInterval
    const ecgIndex = newPlotOrder.findIndex(
      (plots: { plotType: EventPlot; enabled: boolean }) =>
        plots.plotType === "ECG"
    )
    const ecgStatus = newPlotOrder[ecgIndex]
    if (ecgIndex && newPlotOrder[ecgIndex] && newPlotOrder[ecgIndex].enabled) {
      newPlotOrder[ecgIndex] = {
        plotType: "ECG" as EventPlot,
        enabled: false,
      }
      await setPlotOrder(newPlotOrder)
    }
    setSelectedTimeInterval(fullNightIntervalValue)
    const iframe = document.createElement("iframe")
    const chartSvg = chartRef.current
    if (chartSvg) {
      const graphheightmm = 146
      const graphwidthmm = 197
      //const pxToMm = 0.264583
      const chartHeight = parentRef.current ? parentRef.current.style.height : 0
      const chartWidth = parentRef.current ? parentRef.current.style.width : 0
      //const graphheightpx = (graphheightmm / pxToMm) * 1.5
      //const graphwidthpx = (graphwidthmm / pxToMm) * 1.755
      if (parentRef.current) {
        parentRef.current.style.width = `1000px`
        parentRef.current.style.height = `100%`
      }
      chartRef.current.style.width = `100%`
      chartRef.current.style.height = `800px`
      iframe.style.visibility = "hidden"
      document.body.appendChild(iframe)
      const cssLink = document.createElement("link")
      cssLink.href = "../pdfStyles.css"
      cssLink.rel = "stylesheet"
      cssLink.type = "text/css"
      const iframedoc = iframe.contentDocument || iframe.contentWindow?.document

      if (iframedoc) {
        iframedoc.body.innerHTML = htmlString
        iframedoc.head.appendChild(cssLink)
        const canvas = await html2canvas(iframedoc.body, { scale: 3.0 })
        const chartCanvas = await html2canvas(chartSvg as HTMLElement, {
          scale: 4.0,
        })
        // Convert the iframe into a PNG image using canvas.
        const imgData = canvas.toDataURL("image/png")
        const chartImgData = chartCanvas.toDataURL("image/png")
        if (parentRef.current) {
          parentRef.current.style.width = chartWidth.toString()
          parentRef.current.style.height = chartHeight.toString()
        }
        chartRef.current.style.width = "100%"
        chartRef.current.style.height = "100%"
        // Create a PDF document and add the image as a page.
        const doc = new jsPDF({
          format: "letter",
          unit: "mm",
        })

        const imgWidth = 205
        const imgHeight = (imgWidth / canvas.width) * canvas.height

        // Convert the iframe into a PNG image using canvas.
        doc.addImage(
          imgData,
          "PNG",
          0,
          0,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        )
        doc.addImage(
          chartImgData,
          "PNG",
          7,
          100, //imgHeight + 50,
          graphwidthmm,
          graphheightmm,
          undefined,
          "FAST"
        )
        if (interpretationNotesData && user?.primaryRole === "Physician") {
          iframedoc.body.innerHTML = interpretationNotesPage
          const interpretationCanvas = await html2canvas(iframedoc.body, {
            scale: 3.0,
          })
          const interpretationImgData =
            interpretationCanvas.toDataURL("image/png")
          const interpretationImgWidth = 205
          const interpretationImgHeight =
            (interpretationImgWidth / interpretationCanvas.width) *
            interpretationCanvas.height
          doc.addPage()
          doc.addImage(
            interpretationImgData,
            "PNG",
            0,
            0,
            interpretationImgWidth,
            interpretationImgHeight,
            undefined,
            "FAST"
          )
        }
        if (study && preview) {
          doc.save(study.uuid + ".pdf")
        } else {
          const pdfBlob = doc.output("blob")
          const pdfUrl = window.URL.createObjectURL(pdfBlob)
          await setPdfUrl(pdfUrl)
        }
      }
      document.body.removeChild(iframe)
      setSelectedTimeInterval(currentInterval)
      if (ecgStatus && ecgStatus.enabled && newPlotOrder[ecgIndex]) {
        const newOrder = [...plotOrder]
        newOrder[ecgIndex] = {
          plotType: "ECG" as EventPlot,
          enabled: true,
        }
        setPlotOrder(newOrder)
      }

      setTimeout(() => {
        setIsGeneratingPDF(false)
      }, 4000)
    }
  }
  const signatureApi = useSignature()

  const getSignatureUrl = async () => {
    if (!user || !user.uuid) return

    try {
      const url = await signatureApi.getSignatureUrl({
        physicianId: user?.uuid,
      })
      const signatureImage: string = await getBase64FromUrl(url as string)
      await setSignatureUrl(signatureImage)
      return
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  const printPDF = async (
    interpretationEditorContentData: JSONContent,
    preview = true
  ) => {
    if (api === undefined) return

    setIsGeneratingPDF(true)

    const interpretationNotesData =
      Object.keys(interpretationEditorContentData).length > 0

    if (!preview) {
      await getSignatureUrl()
    }
    const summaryPage = renderToString(
      <RecoilBridge>
        <PdfStudySummary interpretationNotesData={interpretationNotesData} />
      </RecoilBridge>
    )
    const interpretationNotesPage = interpretationNotesData
      ? renderToString(
          <RecoilBridge>
            <PdfInterpretationNotes
              interpretationEditorContentData={interpretationEditorContentData}
            />
          </RecoilBridge>
        )
      : ""
    await htmlStringToPdf({
      htmlString: summaryPage,
      interpretationNotesPage,
      interpretationNotesData,
      preview,
    })
  }
  return printPDF
}

export default usePrintPdf
