import { styled } from "@mui/joy/styles"

export const StyledStepLinePlot = styled("div")({
  width: "100%",
  cursor: "move",
})

export const StyledTimestamp = styled("div")({
  fontSize: "11px",
  lineHeight: "12px",
  color: "#949494",
  marginTop: "0px",
})

export const StyledDatasetSidebar = styled("div")({
  height: "100%",
  backgroundColor: "#FFF",
  minWidth: "400px",
  borderLeft: "solid 1px #EFEEF4",
  userSelect: "none",
})

export const StyledMinimizedDatasetSidebar = styled("div")({
  width: "auto",
  fontSize: "16px",
  color: "#006BB9",
  fontWeight: 500,
  userSelect: "none",
  "& .MuiIconButton-root": {
    backgroundColor: "#D9E9F5",
    color: "#006BB9",
    transform: "rotate(90deg)",
    width: "26px",
    height: "26px",
    marginBottom: "10px",
  },
  "& .MuiIconButton-root svg": {
    width: "16px",
    height: "16px",
  },
})

export const StyledEventsExpandButton = styled("div")({
  // @ts-ignore
  writingMode: "tb-rl",
  transform: "rotate(180deg)",
  color: "#006BB9",
})

export const StyledStudyDatasetContent = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  position: "relative",
  boxSizing: "border-box",
})

export const StyledDatasetPlots = styled("div")({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  margin: 0,
  padding: 0,
  flex: "1",
  minWidth: 0, // fixes shrink overflow issue
})

export const StyledDatasetHeader = styled("div")({
  width: "100%",
  paddingBottom: "5px",
  paddingTop: "10px",
  borderBottom: "2px solid #EFEEF4",
})
