import React from "react"

interface HeartRateStatisticsProps {
  awakemin: string
  awakemean: string
  awakemax: string
  sleepmin: string
  sleepmean: string
  sleepmax: string
}

const HeartRateStatistics: React.FC<HeartRateStatisticsProps> = ({
  awakemin,
  awakemean,
  awakemax,
  sleepmin,
  sleepmean,
  sleepmax,
}) => {
  return (
    <table className="data-table">
      <tbody>
        <tr className="table-head">
          <th className="table-head" colSpan={4}>
            Heart Rate Statistics
          </th>
        </tr>
        <tr>
          <td></td>
          <td>Min </td>
          <td>Mean </td>
          <td>Max</td>
        </tr>
        <tr>
          <td>All Study</td>
          <td>{awakemin}</td>
          <td>{awakemean}</td>
          <td>{awakemax}</td>
        </tr>
        <tr>
          <td>Sleeping</td>
          <td>{sleepmin}</td>
          <td>{sleepmean}</td>
          <td>{sleepmax}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default HeartRateStatistics
