import {
  PositionMap,
  timeSeriesFilterPointsToPointsConverterData,
} from "../const/signals.const"
import { BaseEvent } from "../interfaces/events.interface"
import { EventApi, EventPlot } from "../types/event.type"
import {
  Signal,
  TimeSeriesFilterPointToPointConverterReturnProps,
} from "../interfaces/signals.interface"

interface TimeSeriesFilterPointToPointConverterProps {
  signal: Signal
  startTime: number
  endTime: number
  plotType?: EventPlot | string
}

type MinMaxFunction<T> = (
  array: Iterable<T>,
  accessor?: (d: T) => number | undefined
) => number | undefined

/**
 *
 * @param arr
 * @param startTime
 * @param endTime
 * @param xAllMin
 * @param xAllMax
 * @returns
 */

export const filterConvertedTimeSeriesByDate = (
  arr: Signal,
  startTime: number,
  endTime: number,
  xAllMin: number,
  xAllMax: number,
  type: string
) => {
  let left = 0
  let right = arr.timestamps.length - 1
  let startIdx = -1
  let endIdx = -1

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)
    if (arr.timestamps[mid] < startTime) {
      left = mid + 1
    } else if (arr.timestamps[mid] > endTime) {
      right = mid - 1
    } else {
      if (startIdx === -1 || mid < startIdx) {
        startIdx = mid
      }
      if (endIdx === -1 || mid > endIdx) {
        endIdx = mid
      }

      left = mid + 1
      right = mid - 1
    }
  }

  while (startIdx > 0 && arr.timestamps[startIdx - 1] >= startTime) {
    startIdx--
  }
  while (
    endIdx < arr.timestamps.length - 1 &&
    arr.timestamps[endIdx + 1] <= endTime
  ) {
    endIdx++
  }

  return startIdx !== -1 && endIdx !== -1 && startIdx <= endIdx
    ? {
        timestamps: arr.timestamps.slice(
          startIdx - (type === "filter" ? 0 : xAllMin !== startTime ? 1 : 0),
          endIdx + 1 + (type === "filter" ? 0 : xAllMax !== endTime ? 1 : 0)
        ),
        values: arr.values.slice(
          startIdx - (type === "filter" ? 0 : xAllMin !== startTime ? 1 : 0),
          endIdx + 1 + (type === "filter" ? 0 : xAllMax !== endTime ? 1 : 0)
        ),
      }
    : { timestamps: [], values: [] }
}

/**
 *
 * @param points This is a test function
 * @param excludeZero
 * @returns
 */
export const timeSeriesMinMaxMean = (
  points: number[],
  excludeZero = true
): number => {
  return 0
}

/**
 * Calculates the mean value of a time series.
 * @param points - An array of TimeSeriesPoint objects.
 * @param excludeZero - Optional. If true, excludes points with a value of zero from the calculation. Default is true.
 * @returns The mean value of the time series.
 */
export const timeseriesMean = (
  points: number[],
  excludeZero = true
): number => {
  if (excludeZero) {
    points = points.filter((point) => point !== 0)
  }
  const sum = points.reduce((a, b) => a + b, 0)
  return sum / points.length
}

/**
 * Calculates the minimum value from an array of TimeSeriesPoint objects.
 *
 * @param points - The array of TimeSeriesPoint objects.
 * @param excludeZero - Optional. If true, excludes zero values from the calculation. Default is true.
 * @returns The minimum value from the array of TimeSeriesPoint objects.
 */
export const timeseriesMin = (points: number[], excludeZero = true): number => {
  if (excludeZero) {
    points = points.filter((point) => point !== 0)
  }
  return Math.min(...points.map((point) => point))
}

/**
 * Calculates the maximum value from an array of TimeSeriesPoint objects.
 *
 * @param points - An array of TimeSeriesPoint objects.
 * @param excludeZero - A boolean indicating whether to exclude zero values from the calculation. Default is true.
 * @returns The maximum value from the array of TimeSeriesPoint objects.
 */
export const timeseriesMax = (points: number[], excludeZero = true): number => {
  if (excludeZero) {
    points = points.filter((point) => point !== 0)
  }
  return Math.max(...points.map((point) => point))
}

export const timeSeriesFilterPointsToPointsConverter = ({
  signal,
  startTime,
  endTime,
  plotType,
}: TimeSeriesFilterPointToPointConverterProps): TimeSeriesFilterPointToPointConverterReturnProps => {
  const signalTimeStampsLength = signal.timestamps.length

  if (signalTimeStampsLength !== signal.values.length) {
    throw new Error("Timestamps and values length do not match")
  }
  if (
    startTime > signal.timestamps[signalTimeStampsLength - 1] ||
    endTime < signal.timestamps[0]
  ) {
    return timeSeriesFilterPointsToPointsConverterData
  }
  signal = filterConvertedTimeSeriesByDate(
    signal,
    startTime,
    endTime,
    0,
    0,
    "filter"
  )
  const valueCheck =
    plotType === "Position"
      ? (value: number | null | undefined) =>
          bodyPosValuesToPostionMap(value as number)
      : (value: number | null | undefined) =>
          value && isNumberValue(value) ? value : 0
  let max = -Infinity,
    min = Infinity

  for (let i = 0; i < signal.timestamps.length; i++) {
    signal.values[i] = valueCheck(signal.values[i])
    max = Math.max(signal.values[i], max)
    min = Math.min(signal.values[i], min)
  }
  return {
    data: signal,
    signalLength: signal.timestamps.length,
    min: min,
    max: max,
  }
}

/**
 * Converts time series points to points.
 *
 * @param values - The array of values.
 * @param timestamps - The array of timestamps.
 * @returns An array of TimeSeriesPoint objects.
 */
/*export const timeSeriesPointsToPointsConvert = ({
  values,
  timestamps,
}: {
  values: number[]
  timestamps: number[]
}): Signal =>
  values.map((valuesData: number, index: number) => {
    return {
      date: new Date(timeStampConverter(timestamps[index])),
      value: valuesData === null || isNaN(valuesData) ? 0 : valuesData,
    }
  })*/

/**
 * Converts time series points to points with a maximum and minimum value constraint.
 * @param timeseries - The time series data containing values and timestamps.
 * @param max - The maximum value constraint.
 * @param min - The minimum value constraint.
 * @returns An array of TimeSeriesPoint objects with values constrained between the maximum and minimum values.
 */
/*export const timeSeriesPointsToPointsConvertWithMaxMin = ({
  timeseries,
  max,
  min,
}: {
  timeseries: { values: number[]; timestamps: number[] }
  max: number
  min: number
}): Signal => {
  const newVals = timeSeriesPointsToPointsConvert(timeseries)
  return newVals.map((point) => {
    return {
      ...point,
      value: point.value > max ? max : point.value < min ? min : point.value,
    }
  })
}*/

export function sleepWakeEventsToTimeSeriesPoints(
  events: EventApi[]
): TimeSeriesFilterPointToPointConverterReturnProps {
  const points: Signal = { timestamps: [], values: [] }
  const sleepWakeSamplingTime = 0.5
  const sleepWakeStartingSeries = 120
  const sleepWakeEndingSeries = 600

  //if events are empty, return empty array
  if (!events || events.length === 0) {
    return {
      data: points,
      signalLength: points.timestamps.length,
      min: 0,
      max: 0,
    }
  }

  let max = -Infinity,
    min = Infinity

  //pad the first 120 seconds with the wake label
  for (let i = 0; i < sleepWakeStartingSeries / sleepWakeSamplingTime; i++) {
    points.timestamps.push(
      events[0].startTS - sleepWakeStartingSeries + i * sleepWakeSamplingTime
    )
    points.values.push(0)
  }

  events.forEach((event) => {
    const duration = event.endTS - event.startTS

    const numSleepEpochs = Math.ceil(duration / sleepWakeSamplingTime) // create signal with sampling rate of

    //Last point of sleep and first point of next have same timestamp.
    //So we do not include the first point
    for (let i = 1; i < numSleepEpochs; i++) {
      const value = event.event_data.sleep_label ? 1 : 0
      points.timestamps.push(event.startTS + i * sleepWakeSamplingTime)
      points.values.push(value)
      max = Math.max(value, max)
      min = Math.min(value, min)
    }
  })

  //add 120 of the last sleep label to the end
  for (let i = 1; i < sleepWakeEndingSeries / sleepWakeSamplingTime; i++) {
    const value = events[events.length - 1].event_data.sleep_label ? 1 : 0
    points.timestamps.push(
      events[events.length - 1].endTS + i * sleepWakeSamplingTime
    )
    points.values.push(value)
    max = Math.max(value, max)
    min = Math.min(value, min)
  }

  return {
    data: points,
    signalLength: points.timestamps.length,
    min: min,
    max: max,
  }
}

export const bodyPosValuesToPostionMap = (value: number) =>
  ({
    6: PositionMap.Supine,
    1: PositionMap.Prone,
    12: PositionMap.Left,
    3: PositionMap.Right,
    0: PositionMap.Up,
    9: PositionMap["N/A"],
  }[value] || PositionMap["N/A"])

export const positionMapToBodyPositionValues = (key: number) =>
  ({
    [PositionMap.Supine]: 6,
    [PositionMap.Prone]: 1,
    [PositionMap.Left]: 12,
    [PositionMap.Right]: 3,
    [PositionMap.Up]: 0,
    [PositionMap["N/A"]]: 9,
  }[key] || 9)

/**
 * Gets the points overlapping not overlapping between a time series and an array of events.
 * @param timeSeries array of TimeSeriesPoint objects
 * @param events array of BaseEvent objects
 * @returns TimeSeriesPoint array of overlapping points
 */
export function getNotOverlappingTimeSeriesWithEventArray(
  signalData: Signal,
  events: BaseEvent[]
): Signal {
  const nonOverlappingTimeSeries: Signal = { timestamps: [], values: [] }
  signalData.timestamps.forEach((point, index) => {
    let inEvent = false
    events.forEach((event) => {
      if (point >= event.startTS) {
        if (point <= event.endTS) {
          inEvent = true
        }
      }
    })
    if (!inEvent) {
      nonOverlappingTimeSeries.timestamps.push(point)
      nonOverlappingTimeSeries.values.push(signalData.values[index])
    }
  })
  return nonOverlappingTimeSeries
}

/**
 * Gets the points overlapping between a time series and an array of events. (inclusive of start and end)
 * @param timeSeries array of TimeSeriesPoint objects
 * @param events array of BaseEvent objects
 * @returns TimeSeriesPoint array of overlapping points
 */
export function getOverlappingTimeSeriesWithEventArray(
  signalData: Signal,
  events: BaseEvent[]
): Signal {
  const overlappingTimeSeries: Signal = { timestamps: [], values: [] }
  signalData.timestamps.forEach((point, index) => {
    events &&
      events.forEach((event) => {
        if (point >= event.startTS) {
          if (point <= event.endTS) {
            overlappingTimeSeries.timestamps.push(point)
            overlappingTimeSeries.values.push(signalData.values[index])
            return //no need to continue through events if we already found one
          }
        }
      })
  })
  return overlappingTimeSeries
}

/**
 * Filters an array of TimeSeriesPoint objects based on a specified time range.
 * @param points - The array of TimeSeriesPoint objects to filter.
 * @param startTime - The start time of the range, in seconds.
 * @param endTime - The end time of the range, in seconds.
 * @returns An array of TimeSeriesPoint objects that fall within the specified time range.
 */
/*export const timeFilteredPoints = (
  points: Signal,
  startTime: number,
  endTime: number
): Signal =>
  points.filter((point) => {
    const pointTime = point.date.valueOf()
    return pointTime >= timeStampConverter(startTime) && pointTime <= timeStampConverter(endTime)
  })*/

export const isNumberValue = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value)
}

export const timeStampConverter = (timeStamp: number) => timeStamp * 1000

export const getYMinMax = (
  value: number | unknown,
  data: number[],
  fn: MinMaxFunction<number>
) => {
  return isNumberValue(value) ? value : fn(data, (d: number) => d) || 0
}
