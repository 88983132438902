import { getAlignedTicksInterval } from "@huxley-medical/react-components/utils"
import GridColumns from "@visx/grid/lib/grids/GridColumns"
import { ScaleLinear } from "d3-scale"

interface GridGraphColumnsProps {
  width: number
  height: number
  scale: ScaleLinear<number, number, never>
  stroke: string
  tickIntervals: number
}

const GridGraphColumns = ({
  tickIntervals,
  scale,
  width,
  height,
  stroke,
}: GridGraphColumnsProps) => {
  const [xMin, xMax] = scale.domain()
  return (
    <GridColumns
      tickValues={getAlignedTicksInterval({
        minTick: xMin,
        maxTick: xMax,
        tickInterval: tickIntervals,
      })}
      scale={scale}
      width={width}
      height={height}
      stroke={stroke}
    />
  )
}

export default GridGraphColumns
