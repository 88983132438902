import { Div } from "@huxley-medical/react-components/elements"
import { styled } from "@mui/joy/styles"
import CircularProgress from "@mui/joy/CircularProgress"

interface LoadingIndicatorProps {
  content: string
  header: string
  showCircularProgress?: boolean
}

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  background: "rgba(255, 255, 255, 0.9)",
})

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  header,
  content,
  showCircularProgress = true,
}) => {
  return (
    <Overlay>
      <Div sx={{ textAlign: "center" }}>
        <strong>{header}</strong>
        <br />
        <span style={{ fontSize: "12px" }}>{content}</span>
      </Div>
      {showCircularProgress && (
        <Div>
          <CircularProgress sx={{ marginTop: "10px" }} />
        </Div>
      )}
    </Overlay>
  )
}

export default LoadingIndicator
