/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepStudyInterpretationOut
 */
export interface SleepStudyInterpretationOut {
    /**
     * 
     * @type {object}
     * @memberof SleepStudyInterpretationOut
     */
    interpretationNotes?: object;
}

/**
 * Check if a given object implements the SleepStudyInterpretationOut interface.
 */
export function instanceOfSleepStudyInterpretationOut(value: object): value is SleepStudyInterpretationOut {
    return true;
}

export function SleepStudyInterpretationOutFromJSON(json: any): SleepStudyInterpretationOut {
    return SleepStudyInterpretationOutFromJSONTyped(json, false);
}

export function SleepStudyInterpretationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyInterpretationOut {
    if (json == null) {
        return json;
    }
    return {
        
        'interpretationNotes': json['interpretation_notes'] == null ? undefined : json['interpretation_notes'],
    };
}

export function SleepStudyInterpretationOutToJSON(value?: SleepStudyInterpretationOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interpretation_notes': value['interpretationNotes'],
    };
}

