import { Circle } from "@mui/icons-material"
import Stack from "@mui/joy/Stack/Stack"
import Typography from "@mui/joy/Typography/Typography"

const StatusIndicator = (status: string) => {
  return (
    <Stack direction="row" spacing={2}>
      <Circle
        color={status === "Active" ? "success" : "action"}
        sx={{ width: "8px" }}
      />
      <Typography>{status}</Typography>
    </Stack>
  )
}

export default StatusIndicator
