import { Signal } from "../interfaces"

export function minMaxDownsample(data: Signal, bucketSize: number): Signal {
  const n = data.timestamps.length

  if (n <= 2 || bucketSize >= n || bucketSize === 0) {
    // Not enough points to downsample
    return data
  }

  if (bucketSize <= 3) {
    // Bucketsize is too small
    console.warn("Bucketsize is too small returning data")
    return data
  }

  const sampledData: Signal = { timestamps: [], values: [] }

  for (let i = 0; i < n; i += bucketSize) {
    // give some initial values which are at the beginning and end of the bucket
    let maxLoc = i
    let minLoc = i + bucketSize

    let max = -Infinity
    let min = Infinity

    for (let j = i; j < i + bucketSize; j++) {
      if (j >= n) {
        break
      }
      if (data.values[j] > max) {
        max = data.values[j]
        maxLoc = j
      }
      if (data.values[j] < min) {
        min = data.values[j]
        minLoc = j
      }
    }
    if (maxLoc > minLoc) {
      sampledData.timestamps.push(data.timestamps[minLoc])
      sampledData.values.push(data.values[minLoc])
      sampledData.timestamps.push(data.timestamps[maxLoc])
      sampledData.values.push(data.values[maxLoc])
    } else {
      sampledData.timestamps.push(data.timestamps[maxLoc])
      sampledData.values.push(data.values[maxLoc])
      sampledData.timestamps.push(data.timestamps[minLoc])
      sampledData.values.push(data.values[minLoc])
    }
  }

  return sampledData
}
