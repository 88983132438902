import React, { useEffect, useState } from "react"
import { Auth } from "aws-amplify"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { Box, Button, Stack } from "@mui/joy"
import { useNavigate } from "react-router-dom"

export const UserProfile = () => {
  const [userData, setUserData] = useState({
    name: "",
    username: "",
    role: "",
    email: "",
    phoneNumber: "",
    mfaType: "",
    mfaEnabled: true,
  })
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setUserData({
          name: user.attributes.name || "",
          username: user.username,
          role:
            user.signInUserSession.idToken.payload["cognito:groups"]?.[0] ||
            "User",
          email: user.attributes.email || "",
          phoneNumber: user.attributes.phone_number || "",
          mfaType:
            user.preferredMFA === "SOFTWARE_TOKEN_MFA"
              ? "Authenticator"
              : user.preferredMFA === "SMS"
              ? "SMS"
              : "None",
          mfaEnabled: user.preferredMFA !== "NOMFA",
        })
      } catch (error) {
        console.log("Error fetching user data", error)
      }
    }

    fetchUserData()
  }, [])

  const formatPhoneNumber = (phoneNumber: string) => {
    // Format the phone number as needed
    // For example, you can use a library like libphonenumber-js
    // Here, we're simply inserting dashes for illustration purposes
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
  }

  const handleSetupMFA = () => {
    // Navigate to the MFA setup page or trigger the setup process
    console.log("Navigate to MFA setup page")
    navigate("/mfa")
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          User Profile
        </Typography>

        <Stack
          spacing={2}
          sx={{
            mt: 2,
            width: "100%",
            maxWidth: 500,
          }}
        >
          <Typography variant="h6" color="textPrimary" align="left">
            <strong>Name:</strong> {userData.name}
          </Typography>
          <Typography variant="h6" color="textPrimary" align="left">
            <strong>Role:</strong> {userData.role}
          </Typography>
          <Typography variant="h6" color="textPrimary" align="left">
            <strong>Email:</strong> {userData.email}
          </Typography>
          <Typography variant="h6" color="textPrimary" align="left">
            <strong>Phone Number:</strong>{" "}
            {formatPhoneNumber(userData.phoneNumber)}
          </Typography>
          <Typography variant="h6" color="textPrimary" align="left">
            <strong>MFA Type:</strong>{" "}
            {userData.mfaType === "" ? "None" : userData.mfaType}
          </Typography>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            onClick={handleSetupMFA}
            disabled={userData.mfaEnabled}
          >
            Setup Multi-Factor Authentication
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
