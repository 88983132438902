import { DynamicIntervalHandler } from "../types/handlers.type"

/**
 * DynamicInterval is a class that can be used to create an interval routine
 * like setInterval, except the delay can be changed while running.
 */
export class DynamicInterval {
  private delay: number
  private handler: DynamicIntervalHandler
  private lastInvoked = 0
  private timeoutHandle = 0

  /**
   * used to contain additional mutable state or context for the handler
   */
  public additional: unknown = {}

  started = false

  /**
   * DynamicInterval is a class that can be used to create an interval routine
   * like setInterval, except the delay can be changed while running.
   */
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-empty-function, prettier/prettier
    handler: DynamicIntervalHandler = () => { },
    initalDelay = 1000,
    start = false
  ) {
    this.delay = initalDelay
    this.handler = handler

    if (start) {
      this.start()
    }
  }

  /**
   * starts the dynamic interval routine
   */
  start() {
    this.lastInvoked = new Date().getTime()
    this.started = true
    this.timeoutHandle = window.setTimeout(
      this.loop.bind(this),
      this.delay,
      this
    )
  }

  /**
   * stops the dynamic interval, optionally reset the delay value
   */
  stop(resetDelay?: number | undefined) {
    if (resetDelay !== undefined) {
      this.delay = resetDelay
    }
    window.clearTimeout(this.timeoutHandle)
    this.started = false
  }

  /**
   * loop triggers the provided handler, then setTimeout for the next tick
   */
  loop() {
    this.lastInvoked = new Date().getTime()
    this.handler(this)
    this.timeoutHandle = window.setTimeout(
      this.loop.bind(this),
      this.delay,
      this
    )
  }

  /**
   * setDelay modifies the delay setting. If the delay is less than the elapsed time since last tick,
   * the loop handler is invoked immediately. Otherwise a new tick is scheduled for the difference between
   * the last invoked time and the new delay value.
   *
   * @param newDelay
   */
  setDelay(newDelay: number) {
    this.delay = newDelay

    const currentTime = new Date().getTime()
    const msDiff = currentTime - this.lastInvoked

    window.clearTimeout(this.timeoutHandle)

    if (msDiff > newDelay) {
      this.loop()
    } else {
      this.timeoutHandle = window.setTimeout(this.loop.bind(this), msDiff, this)
    }
  }

  /**
   * setHandler is used to set the handler function to be executed on every tick
   *
   * @param handler
   */
  setHandler(handler: DynamicIntervalHandler) {
    this.handler = handler
  }
}
