import { StyledDataGrid } from "../../styled/DataGrid"
import { TableProps } from "../../types/table.type"

const DataGridTable = ({
  rows,
  columns,
  rowCount,
  hideFooterSelectedRowCount = true,
  disableRowSelectionOnClick = true,
  paginationAction,
  setPaginationModel,
}: TableProps) => {
  return (
    <StyledDataGrid
      rows={rows}
      rowCount={rowCount}
      columns={columns}
      paginationMode="server"
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      onPaginationModelChange={(paginationModal) => {
        setPaginationModel(paginationModal)
        paginationAction(paginationModal)
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  )
}

export default DataGridTable
