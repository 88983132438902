import { activeCriteria } from "../state/study.state"
import { ScoringCriteria } from "../types/event.type"
import { ExclusionAnnotation } from "../types/exclusion.type"
import { getNotOverlappingTimeSeriesWithEventArray } from "../utils/seriesUtils"
import { atom, selector } from "recoil"
import { studySignals } from "./signals.state"
import { Signal } from "../interfaces/signals.interface"

export const ecgExclusions3 = atom<ExclusionAnnotation[]>({
  key: "ecgExclusions3",
  default: [],
})

export const ecgExclusions4 = atom<ExclusionAnnotation[]>({
  key: "ecgExclusions4",
  default: [],
})

export const ecgBadExclusions = atom<ExclusionAnnotation[]>({
  key: "ecgBadExclusions",
  default: [],
})

export const ppgExclusions3 = atom<ExclusionAnnotation[]>({
  key: "ppgExclusions3",
  default: [],
})

export const ppgExclusions4 = atom<ExclusionAnnotation[]>({
  key: "ppgExclusions4",
  default: [],
})

export const allExclusions = selector<ExclusionAnnotation[]>({
  key: "allExclusions",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const ecgEx = get(
      selectedScoringCriteria === ScoringCriteria.THREE
        ? ecgExclusions3
        : ecgExclusions4
    ).filter((exclusion) => !exclusion.removed)
    const ppgEx = get(
      selectedScoringCriteria === ScoringCriteria.THREE
        ? ppgExclusions3
        : ppgExclusions4
    ).filter((exclusion) => !exclusion.removed)
    return [...ecgEx, ...ppgEx]
  },
})

export const nonSelectedCriteriaAllExclusions = selector<ExclusionAnnotation[]>(
  {
    key: "nonSelectedCriteriaAllExclusions",
    get: ({ get }) => {
      const selectedScoringCriteria = get(activeCriteria)
      const ecgEx = get(
        selectedScoringCriteria === ScoringCriteria.THREE
          ? ecgExclusions4
          : ecgExclusions3
      ).filter((exclusion) => !exclusion.removed)
      const ppgEx = get(
        selectedScoringCriteria === ScoringCriteria.THREE
          ? ppgExclusions4
          : ppgExclusions3
      ).filter((exclusion) => !exclusion.removed)
      return [...ecgEx, ...ppgEx]
    },
  }
)

export const nonRemovedEcgExclusions = selector<ExclusionAnnotation[]>({
  key: "nonRemovedEcgExclusions",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const ecgEx = get(
      selectedScoringCriteria === ScoringCriteria.THREE
        ? ecgExclusions3
        : ecgExclusions4
    )
    return ecgEx.filter((exclusion) => !exclusion.removed)
  },
})

export const nonRemovedPpgExclusions = selector<ExclusionAnnotation[]>({
  key: "nonRemovedPpgExclusions",
  get: ({ get }) => {
    const selectedScoringCriteria = get(activeCriteria)
    const ppgEx = get(
      selectedScoringCriteria === ScoringCriteria.THREE
        ? ppgExclusions3
        : ppgExclusions4
    )
    return ppgEx.filter((exclusion) => !exclusion.removed)
  },
})

export const allNonRemovedExclusions = selector<ExclusionAnnotation[]>({
  key: "allNonRemovedExclusions",
  get: ({ get }) => {
    const allexcl = get(allExclusions)
    return allexcl.filter((exclusion) => !exclusion.removed)
  },
})

export const nonExcludedHr = selector<Signal>({
  key: "nonExcludedHr",
  get: ({ get }) => {
    const studySignalData = get(studySignals)
    if (!studySignalData) return { timestamps: [], values: [] }
    const hr = studySignalData.filter((study) => study.title === "HR")
    const exclusions = get(allNonRemovedExclusions)
    return getNotOverlappingTimeSeriesWithEventArray(
      hr.length > 0 && hr[0].data ? hr[0].data : { timestamps: [], values: [] },
      exclusions
    )
  },
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
})
