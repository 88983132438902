/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PresignedUrlResponse
 */
export interface PresignedUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlResponse
     */
    presignedUrl?: string;
}

/**
 * Check if a given object implements the PresignedUrlResponse interface.
 */
export function instanceOfPresignedUrlResponse(value: object): value is PresignedUrlResponse {
    return true;
}

export function PresignedUrlResponseFromJSON(json: any): PresignedUrlResponse {
    return PresignedUrlResponseFromJSONTyped(json, false);
}

export function PresignedUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresignedUrlResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'presignedUrl': json['presigned_url'] == null ? undefined : json['presigned_url'],
    };
}

export function PresignedUrlResponseToJSON(value?: PresignedUrlResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'presigned_url': value['presignedUrl'],
    };
}

