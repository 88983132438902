import React from "react"

interface PdfPatientInformationProps {
  patient_name: string | undefined
  dob: string | undefined
  mrn: string | undefined
  referring_physician: string
  interpreting_physician: string | undefined
  scoring_criteria: string
  study_date: string
  study_start_time: string
  study_end_time: string
}

const PdfPatientInformation: React.FC<PdfPatientInformationProps> = ({
  patient_name,
  dob,
  mrn,
  referring_physician,
  interpreting_physician,
  scoring_criteria,
  study_date,
  study_start_time,
  study_end_time,
}) => {
  return (
    <div id="patient-info">
      <div className="column">
        <p>
          <strong>Patient Name:</strong> {patient_name}
        </p>
        <p>
          <strong>DOB:</strong> {dob}
        </p>
        <p>
          <strong>MRN:</strong> {mrn}
        </p>
      </div>
      <div className="column">
        <p>
          <strong>Referring Physician: </strong>
          {referring_physician}
        </p>
        <p>
          <strong>Interpreting Physician:</strong>
          {interpreting_physician}
        </p>
        <div className="scoring-criteria">
          <p>
            <strong>Scoring Criteria: </strong>
            {scoring_criteria}
          </p>
        </div>
      </div>
      <div className="column">
        <p>
          <strong>Study Date:</strong> {study_date}
        </p>
        <p>
          <strong>Study Start Time:</strong> {study_start_time}
        </p>
        <p>
          <strong>Study End Time:</strong> {study_end_time}
        </p>
      </div>
    </div>
  )
}

export default PdfPatientInformation
