import { Editor } from "@tiptap/core"
import classNames from "classnames"
import { useCallback } from "react"
import {
  BoldButton,
  UnderLineButton,
  ItalicButton,
  BulletListButton,
  OrderedListButton,
} from "./MenuButtons"
import "./editorMenu.css"

interface EditorMenuProps {
  editor: Editor
}

const EditorMenu = ({ editor }: EditorMenuProps) => {
  const toggleBold = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      if (!editor) return null
      editor.chain().focus().toggleBold().run()
    },
    [editor]
  )

  const toggleItalic = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      if (!editor) return null
      editor.chain().focus().toggleItalic().run()
    },
    [editor]
  )

  const toggleBulletList = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      if (!editor) return null
      editor.chain().focus().toggleBulletList().run()
    },
    [editor]
  )

  const underLineToggle = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      if (!editor) return null
      editor.chain().focus().toggleUnderline().run()
    },
    [editor]
  )

  const toggleOrderedList = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault()
      if (!editor) return null
      editor.chain().focus().toggleOrderedList().run()
    },
    [editor]
  )
  return (
    <div className="tiptapmenu">
      <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("bold"),
        })}
        onClick={toggleBold}
      >
        <BoldButton />
      </button>
      <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("underline"),
        })}
        onClick={underLineToggle}
      >
        <UnderLineButton />
      </button>
      <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("italic"),
        })}
        onClick={toggleItalic}
      >
        <ItalicButton />
      </button>
      <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("bulletList"),
        })}
        onClick={toggleBulletList}
      >
        <BulletListButton />
      </button>
      <button
        className={classNames("menu-button", {
          "is-active": editor.isActive("orderedList"),
        })}
        onClick={toggleOrderedList}
      >
        <OrderedListButton />
      </button>
    </div>
  )
}

export default EditorMenu
