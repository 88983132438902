import { CallbackInterface } from "recoil"
import { EventPlot } from "../../../../types/event.type"
import { ScaleLinear } from "d3-scale"
import { drawEventInProgressFamily } from "../../../../state/event.state"

/**
 * setNewRangeCallback is a recoil callback to handle setting absolute state time
 * given DrawnRange px. We must use recoil state here for new event window creation,
 * otherwise we get non-batched state updates that cause rendering issues.
 */
const setNewRangeCallback =
  ({
    plot,
    timeScale,
  }: {
    plot: EventPlot
    timeScale: ScaleLinear<number, number, never>
  }) =>
  ({ set }: CallbackInterface) =>
  (newRangePx: number[]) => {
    set(drawEventInProgressFamily(plot), [
      timeScale.invert(newRangePx[0]),
      timeScale.invert(newRangePx[1]),
    ])
  }

export default setNewRangeCallback
