import { Refresh, Search } from "@mui/icons-material"
import { Grid, IconButton, Input, Sheet, Stack } from "@mui/joy"
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid"
import { snackAlert } from "../../../components/SnackAlerts"
import { usePortalApi } from "../../../connections"
import {
  GetAllPatientsRequest,
  PagedPatientOut,
  PatientOut,
} from "../../../generated/fetchclient"
import React, { useState } from "react"
import { useSetRecoilState } from "recoil"
import { dateSortComparator, formatDate } from "../../../utils/formatDate"
import { PatientSelectTableParams } from "../../../types/table.type"
import { StyledDataGrid } from "../../../styled/DataGrid"
import { handleApiError } from "../../../utils/apiUtils"
import { createBasicGridColDef } from "../../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../../utils/utils"
import { emptySelectedPatient } from "./NewStudyModal"
import GridFormInput from "../../forms/grid/GridFormInput"

const emptyPatientQuery: GetAllPatientsRequest = {
  firstName: "",
  lastName: "",
  mrn: "",
  dob: "",
}

function NoRowsMessage() {
  return (
    <Sheet
      sx={{
        height: "100%",
        display: "flex",
        mt: 2,
        justifyContent: "center",
      }}
    >
      Enter patient information in at least one field then click search to see
      results
    </Sheet>
  )
}

function PatientSelectTable({ setSelectedPatient }: PatientSelectTableParams) {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [patientQuery, setPatientQuery] = useState<GetAllPatientsRequest>({})
  const [patients, setPatients] = useState([] as PatientOut[])
  const [patientCount, setPatientCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>()

  const searchPatients = async (
    paginationModel: GridPaginationModel | undefined = undefined
  ) => {
    if (api === undefined) return

    const filteredQuery = removeEmptyValuesFromObject(patientQuery)

    setIsLoading(true)
    let pagedPatients: PagedPatientOut
    try {
      if (paginationModel === undefined) {
        pagedPatients = await api.getAllPatients(filteredQuery)
      } else {
        pagedPatients = await api.getAllPatients({
          ...filteredQuery,
          limit: paginationModel.pageSize,
          offset: paginationModel.page * paginationModel.pageSize,
        })
      }
      setPatients(pagedPatients.items)
      setPatientCount(pagedPatients.count)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
    setIsLoading(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchPatients()
    }
  }

  const rows = patients.map((p) => ({
    id: p.uuid,
    firstName: p.firstName,
    middleName: p.middleName,
    lastName: p.lastName,
    fullName: p.fullName,
    mrn: p.mrn,
    dob: formatDate(p.dob),
    hasActiveStudy: !!p.activeStudyOrder,
  }))

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID", 0),
    createBasicGridColDef("firstName", "First Name"),
    createBasicGridColDef("middleName", "Middle Name"),
    createBasicGridColDef("lastName", "Last Name"),
    createBasicGridColDef("mrn", "MRN"),
    {
      field: "dob",
      headerName: "DOB",
      flex: 8,
      editable: false,
      sortComparator: (v1: string, v2: string) => dateSortComparator(v1, v2),
    },
  ]

  return (
    <Stack spacing={2}>
      <React.Fragment>
        <Grid container spacing={2} alignItems="flex-end">
          <GridFormInput width={3} label="First Name">
            <Input
              value={patientQuery.firstName}
              onChange={(e) => {
                setPatientQuery({
                  ...patientQuery,
                  firstName: e.target.value,
                })
              }}
              onKeyDown={handleKeyDown}
            />
          </GridFormInput>
          <GridFormInput width={3} label="Last Name">
            <Input
              value={patientQuery.lastName}
              onChange={(e) => {
                setPatientQuery({
                  ...patientQuery,
                  lastName: e.target.value,
                })
              }}
              onKeyDown={handleKeyDown}
            />
          </GridFormInput>
          <GridFormInput width={2} label="MRN">
            <Input
              value={patientQuery.mrn}
              onChange={(e) => {
                setPatientQuery({
                  ...patientQuery,
                  mrn: e.target.value,
                })
              }}
              onKeyDown={handleKeyDown}
            />
          </GridFormInput>
          <GridFormInput width={2.5} label="DOB">
            <Input
              type="date"
              value={patientQuery.dob}
              onChange={(e) => {
                const dob = e.target.valueAsDate
                if (dob) {
                  setPatientQuery({
                    ...patientQuery,
                    dob: dob.toISOString().split("T")[0],
                  })
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </GridFormInput>
          <Grid xs={0.7}>
            <IconButton
              onClick={() => searchPatients(paginationModel)}
              variant="solid"
              color="primary"
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid xs={0.7}>
            <IconButton
              onClick={() => {
                setPatientQuery(emptyPatientQuery)
                setPatients([] as PatientOut[])
              }}
              variant="outlined"
              color="primary"
            >
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </React.Fragment>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        rowCount={patientCount}
        loading={isLoading}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        paginationMode="server"
        onPaginationModelChange={(paginationModel) => {
          setPaginationModel(paginationModel)
          searchPatients(paginationModel)
        }}
        onRowClick={(params) => {
          const { row } = params
          if (row.hasActiveStudy) {
            setSelectedPatient(emptySelectedPatient)
            setSnackAlertMsg({
              open: true,
              message: `Patient ${row.fullName} already has an active study`,
              severity: "warning",
              autoHideDuration: 5000,
            })
          } else {
            setSelectedPatient({
              uuid: row.id,
              name: row.fullName,
            })
          }
        }}
        slots={{
          noRowsOverlay: NoRowsMessage,
        }}
      />
    </Stack>
  )
}

export default PatientSelectTable
