import { OrganizationOut, UserOut } from "../generated/fetchclient"
import { atom } from "recoil"

export const orgUsers = atom<UserOut[]>({
  key: "orgUsers",
  default: [],
})

export const organizations = atom<OrganizationOut[]>({
  key: "organizations",
  default: [],
})

export const organizationCount = atom<number>({
  key: "organizationCount",
  default: 0,
})
