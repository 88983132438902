import { getAlignedTicks } from "@huxley-medical/react-components/utils"
import GridRows from "@visx/grid/lib/grids/GridRows"
import { ScaleLinear } from "d3-scale"

interface GridGraphRowProps {
  plotWidth: number
  lineHeight: number
  yScale: ScaleLinear<number, number, never>
}

const GridGraphRows = ({
  yScale,
  plotWidth,
  lineHeight,
}: GridGraphRowProps) => {
  const [yMin, yMax] = yScale.domain()
  return (
    <GridRows
      tickValues={getAlignedTicks({
        minTick: yMin,
        maxTick: yMax,
        numTicks: 4,
      })}
      scale={yScale}
      width={plotWidth}
      height={lineHeight}
      stroke="#E2E2E2"
    />
  )
}

export default GridGraphRows
