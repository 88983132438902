import { atom } from "recoil"
import { InterpretationTemplateOut } from "../generated/fetchclient"

export const interpretationTemplates = atom<InterpretationTemplateOut[]>({
  key: "interpretationtemplates",
  default: [],
})

export const interpretationTemplateCount = atom<number>({
  key: "interpretationTemplateCount",
  default: 0,
})

export const isTemplateLoading = atom<boolean>({
  key: "isTemplateLoading",
  default: false,
})
