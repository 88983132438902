import {
  IconButton,
  Paper,
  PaperProps,
  styled,
  Typography,
} from "@mui/material"
import MoreIcon from "@mui/icons-material/MoreVert"

const StyledCard = styled(Paper)({
  position: "relative",
  backgroundColor: "#FFF",
  borderRadius: "13px",
  border: "1px solid #EFEEF4",
  boxShadow: "none",
  padding: "7px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginBottom: "15px",
})

const Card = ({
  titleSection,
  children,
  ...otherProps
}: { titleSection?: React.ReactNode } & PaperProps) => {
  return (
    <StyledCard {...otherProps}>
      <Typography component="div">{titleSection}</Typography>

      {children}

      <IconButton
        size="large"
        aria-label="display more actions"
        edge="end"
        color="inherit"
        sx={{
          position: "absolute",
          top: "8px",
          right: "20px",
          padding: 0,
        }}
      >
        <MoreIcon sx={{ width: "20px", height: "20px", color: "#8991AB" }} />
      </IconButton>
    </StyledCard>
  )
}

export default Card
