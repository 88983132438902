import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Stack,
  Typography,
} from "@mui/joy"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import CheckIcon from "@mui/icons-material/Check"
import React, { useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { snackAlert } from "../../components/SnackAlerts"
import useStudies from "../../hooks/useStudies"
import { studyToBeUploaded } from "../../state/modal.state"

function StudyUploadForm() {
  const studyOrderID = useRecoilValue(studyToBeUploaded)
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [file, setFile] = useState<File>()
  const studies = useStudies()
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.item(0)
    if (selectedFile) {
      setFile(selectedFile)
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("Submitting study upload " + studyOrderID)
    setUploadInProgress(true)
    event.preventDefault()
    if (!file) {
      setSnackAlertMsg({
        open: true,
        message: "Please select a file.",
        severity: "warning",
        autoHideDuration: 5000,
      })
      return
    }
    if (studyOrderID === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. Study ID not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }
    studies
      .uploadStudy(studyOrderID, file)
      .then(() => {
        setUploadComplete(true)
      })
      .catch(() => {
        setUploadFailed(true)
      })
      .finally(() => {
        setUploadInProgress(false)
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl sx={{ width: "200px" }}>
            <Button
              component="label"
              startDecorator={<CloudUploadOutlinedIcon />}
              variant="outlined"
            >
              Select an .hdp file
              <input
                onChange={handleFileSelect}
                type="file"
                accept=".hdp"
                hidden
              />
            </Button>
          </FormControl>
          <Box>{file && file.name}</Box>
          <Box>
            <Button type="submit" disabled={!file}>
              Upload Study
            </Button>
          </Box>
        </Stack>
      </form>
      {(uploadInProgress || uploadComplete || uploadFailed) && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.95)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          {uploadInProgress && <CircularProgress />}
          {uploadComplete && (
            <CircularProgress determinate value={100} color="success">
              <CheckIcon color="success"></CheckIcon>
            </CircularProgress>
          )}
          {uploadFailed && (
            <Stack spacing={2} justifyContent={"center"}>
              <Typography level="h2">Upload Failed</Typography>
              <Button onClick={() => setUploadFailed(false)}>Retry</Button>
            </Stack>
          )}
        </div>
      )}
    </div>
  )
}

export default StudyUploadForm
