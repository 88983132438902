/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InterpretationTemplateUserOut } from './InterpretationTemplateUserOut';
import {
    InterpretationTemplateUserOutFromJSON,
    InterpretationTemplateUserOutFromJSONTyped,
    InterpretationTemplateUserOutToJSON,
} from './InterpretationTemplateUserOut';

/**
 * 
 * @export
 * @interface InterpretationTemplateOut
 */
export interface InterpretationTemplateOut {
    /**
     * 
     * @type {string}
     * @memberof InterpretationTemplateOut
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof InterpretationTemplateOut
     */
    title: string;
    /**
     * 
     * @type {object}
     * @memberof InterpretationTemplateOut
     */
    notes?: object;
    /**
     * 
     * @type {Date}
     * @memberof InterpretationTemplateOut
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterpretationTemplateOut
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterpretationTemplateOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {InterpretationTemplateUserOut}
     * @memberof InterpretationTemplateOut
     */
    user: InterpretationTemplateUserOut;
}

/**
 * Check if a given object implements the InterpretationTemplateOut interface.
 */
export function instanceOfInterpretationTemplateOut(value: object): value is InterpretationTemplateOut {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    return true;
}

export function InterpretationTemplateOutFromJSON(json: any): InterpretationTemplateOut {
    return InterpretationTemplateOutFromJSONTyped(json, false);
}

export function InterpretationTemplateOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterpretationTemplateOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'title': json['title'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'user': InterpretationTemplateUserOutFromJSON(json['user']),
    };
}

export function InterpretationTemplateOutToJSON(value?: InterpretationTemplateOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'title': value['title'],
        'notes': value['notes'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'user': InterpretationTemplateUserOutToJSON(value['user']),
    };
}

