import { DeviceOut } from "../generated/fetchclient"
import { atom } from "recoil"

export const devices = atom<DeviceOut[]>({
  key: "devices",
  default: [],
})

export const deviceCount = atom<number>({
  key: "deviceCount",
  default: 0,
})
