import { useEffect } from "react"

type UseHandleDeleteKeyParams = {
  onDeleteKeyPress?: () => void
  activated: boolean
}

/**
 * useHandleDeleteKey hook used to register handlers for keyboard delete key
 * presses. activated param can be used to activate or deactivate handlers.
 *
 * @param {UseHandleDeleteKeyParams} useHandleDeleteKeyParams
 */
export const useHandleDeleteKey = (
  { onDeleteKeyPress, activated }: UseHandleDeleteKeyParams,
  deps: any[] = []
) => {
  const handleDeleteKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Delete" && onDeleteKeyPress !== undefined && activated)
      onDeleteKeyPress()
  }

  useEffect(() => {
    window.addEventListener("keyup", handleDeleteKeyPress)
    return () => {
      window.removeEventListener("keyup", handleDeleteKeyPress)
    }
  }, [activated, ...deps])
}

type UseHandleArrowKeysCallbacks = {
  onLeftArrow?: () => void
  onRightArrow?: () => void
  onUpArrow?: () => void
  onDownArrow?: () => void
}

/**
 * useHandleArrowKeys hook used to register handlers for keyboard arrow key
 * presses.
 *
 * @param {UseHandleArrowKeysCallbacks} useHandleArrowKeysCallbacks
 * @param {any[]} deps - Dependency array used in useEffect to trigger callback
 * closure changes
 */
export const useHandleArrowKeys = (
  {
    onLeftArrow,
    onRightArrow,
    onUpArrow,
    onDownArrow,
  }: UseHandleArrowKeysCallbacks,
  deps: any[] = []
) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
        // Left pressed
        if (onLeftArrow !== undefined) onLeftArrow()
        break
      case "ArrowRight":
        // Right pressed
        if (onRightArrow !== undefined) onRightArrow()
        break
      case "ArrowUp":
        // Up pressed
        if (onUpArrow !== undefined) onUpArrow()
        break
      case "ArrowDown":
        // Down pressed
        if (onDownArrow !== undefined) onDownArrow()
        break
      default:
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
    // Pass in external deps
  }, [...deps])
}
