/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Schema for the frontend to update a SleepStudy model
 * @export
 * @interface SleepStudyIn
 */
export interface SleepStudyIn {
    /**
     * 
     * @type {object}
     * @memberof SleepStudyIn
     */
    eventDataAhiThree?: object;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyIn
     */
    eventDataAhiFour?: object;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyIn
     */
    eventDataEcg?: object;
}

/**
 * Check if a given object implements the SleepStudyIn interface.
 */
export function instanceOfSleepStudyIn(value: object): value is SleepStudyIn {
    return true;
}

export function SleepStudyInFromJSON(json: any): SleepStudyIn {
    return SleepStudyInFromJSONTyped(json, false);
}

export function SleepStudyInFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyIn {
    if (json == null) {
        return json;
    }
    return {
        
        'eventDataAhiThree': json['event_data_ahi_three'] == null ? undefined : json['event_data_ahi_three'],
        'eventDataAhiFour': json['event_data_ahi_four'] == null ? undefined : json['event_data_ahi_four'],
        'eventDataEcg': json['event_data_ecg'] == null ? undefined : json['event_data_ecg'],
    };
}

export function SleepStudyInToJSON(value?: SleepStudyIn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event_data_ahi_three': value['eventDataAhiThree'],
        'event_data_ahi_four': value['eventDataAhiFour'],
        'event_data_ecg': value['eventDataEcg'],
    };
}

