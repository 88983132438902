import { useEffect } from "react"
import { DynamicInterval } from "../../../utils/DynamicInterval"
import { epochMs } from "../../../constants"
import { SnackAlert } from "../../../types/snackAlert.types"
import {
  ScrollRoutineContext,
  UseScrollRoutineSchedulerParams,
} from "../../../types/scroller.type"
import { eventIntersector } from "../../../components/scoring/event/utils"

export const scrollError: SnackAlert = {
  open: true,
  message: "Unable to scroll, other event in the way",
  severity: "error",
}

/**
 * scrollHandlerDragResize is used to handle epoch scrolling when the event window
 * reaches the start or end of visible plots. It's used with dragging and resizing of
 * event windows. It is invoked by an instance of DynamicInterval.
 *
 * @param {DynamicInterval} _this
 * @returns {void} void
 */
const scrollHandlerDragResize = (_this: DynamicInterval): void => {
  const {
    scrollMode,
    rangePx,
    currentEpoch,
    timeScale,
    eventID,
    exclusionData,
    allPlotEvents,
    totalEpochs,
    parentWidth,
    setSnackAlertMsg,
    setCurrentEpoch,
    setNewRange,
  } = _this.additional as ScrollRoutineContext
  if (scrollMode === undefined || rangePx === undefined) {
    return
  }

  // Default to drag for method
  const method = scrollMode.method ?? "drag"

  // Calculate px width of epoch
  const [minX] = timeScale.domain()
  const epochWidthPx = timeScale(minX + epochMs)

  let newEpoch = currentEpoch
  let newStart = parentWidth - rangePx[1]
  let newEnd = parentWidth

  // Advance epoch in either direction
  if (scrollMode.orientation === "right") {
    // Start stays the same for resize
    if (method === "drag") newStart += epochWidthPx
    newEnd += epochWidthPx
    newEpoch += 1
  } else {
    newStart = 0 - epochWidthPx
    // Calculate end
    if (method === "drag") {
      newEnd = rangePx[1] - epochWidthPx
    } else {
      newEnd = rangePx[1]
    }

    newEpoch -= 1
  }

  // Prevent intersection with other events at prposed newStart and newEnd
  const eventIntersectionDetector = eventIntersector({
    eventID,
    widthPx: rangePx[1],
    exclusions: exclusionData,
    parentWidth,
    allPlotEvents,
    timeScale,
  })
  const intersect = eventIntersectionDetector(newStart, newEnd)
  if (intersect.intersectedWith !== undefined) {
    setSnackAlertMsg(scrollError)
    return
  }

  const epochPerPage = parentWidth / epochWidthPx

  // Ensure we don't scroll before or after study epochs
  if (newEpoch < 0) {
    newEpoch = 0
    newStart = 0
    newEnd = rangePx[1]
  } else if (newEpoch + epochPerPage > totalEpochs) {
    newStart = parentWidth - rangePx[1]
    newEnd = parentWidth
    newEpoch = currentEpoch
  }

  setNewRange([newStart, newEnd])
  setCurrentEpoch(newEpoch)
}

/**
 * useScrollRoutine is a hook used to run a routine inside DynamicInterval
 * to scroll an event window to the left or right on drag.
 *
 * Triggers: [scrollMode, moveEventStart]
 *
 * @param {UseScrollRoutineSchedulerParams} useScrollRoutineParams
 */
export const useScrollRoutineScheduler = ({
  scrollMode,
  moveEventStart,
  scrollRoutine,
  setScrollMode,
  scrollHandler = scrollHandlerDragResize,
}: UseScrollRoutineSchedulerParams) => {
  useEffect(() => {
    scrollRoutine.current.setHandler(scrollHandler)
    return () => scrollRoutine.current.stop(1000)
  }, [])

  useEffect(() => {
    const sr = scrollRoutine.current

    if (scrollMode !== undefined && moveEventStart !== undefined) {
      // Start scroll mode
      if (!sr.started) {
        sr.start()
      } else {
        sr.setDelay(1000 / scrollMode.speed)
      }
    }

    // When mouseUp event occurs or scrollMode is cancelled in event handler
    if (
      sr.started &&
      (moveEventStart === undefined || scrollMode === undefined)
    ) {
      // Stop if scroll mode is not set
      sr.stop(1000)
      setScrollMode(undefined)
    }
  }, [scrollMode, moveEventStart])
}
