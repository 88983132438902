import { atom } from "recoil"

export const studyToBeUploaded = atom<string | undefined>({
  key: "studyToBeUploaded",
  default: undefined,
})

export const deleteStudyModalOpen = atom<boolean>({
  key: "deleteStudyModalOpen",
  default: false,
})

export const studyToBeDeleted = atom<string | undefined>({
  key: "studyToBeDeleted",
  default: undefined,
})

export const fulfillStudyModalOpen = atom<boolean>({
  key: "fulfillStudyModalOpen",
  default: false,
})

export const studyToBeFulfilled = atom<string | undefined>({
  key: "studyToBeFulfilled",
  default: undefined,
})

export const studyUploadModalOpen = atom<boolean>({
  key: "studyUploadModalOpen",
  default: false,
})
