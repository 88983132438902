import { useEffect, useRef, useState } from "react"
import { styled } from "@mui/material"
import { Clear, Div, Span } from "../../elements"
import { styleIf } from "../../utils"

const StyledStackedBarData = styled("div")({
  marginBottom: "10px",
})

const StyledStackedBarPlot = styled("div")({
  float: "left",
  height: "0px",
  borderRadius: "46px",
  width: "15px",
  overflow: "hidden",
  marginRight: "30px",
  transition: "height 2s, width 2s",
})

const StackedBar = ({
  length,
  color = "#87C2FA",
  variant = "vertical",
}: {
  length: number
  color?: string
  variant?: "vertical" | "horizontal"
}) => {
  return (
    <Div
      data-testid="stacked-bar"
      sx={{
        backgroundColor: color,
        ...styleIf(variant === "vertical", {
          height: `${length}px`,
        }),
        ...styleIf(variant === "horizontal", {
          float: "left",
          height: "15px",
          width: `${length}%`,
        }),
      }}
    />
  )
}

const StyledBarLegendItem = styled(Div)({
  width: "18px",
  height: "18px",
  borderRadius: "18px",
  display: "inline-block",
  verticalAlign: "sub",
  marginRight: "6px",
})

type StackedBarParam = {
  title?: string
  color?: string
  value: number
}

const StackedBarDataPlot = ({
  variant = "vertical",
  stackedBars,
}: {
  stackedBars: StackedBarParam[]
  variant?: "vertical" | "horizontal"
}) => {
  const total = stackedBars.reduce((previousValue, currentValue) => ({
    value: previousValue.value + currentValue.value,
  })).value
  const labels = useRef<HTMLDivElement>(null)
  const [barPlotLength, setBarPlotLength] = useState(0)

  useEffect(() => {
    setBarPlotLength(
      variant === "vertical"
        ? labels.current?.clientHeight || 200
        : labels.current?.clientWidth || 0
    )
  }, [])

  return (
    <StyledStackedBarData
      className="styled-stacked-bar-data"
      data-testid="stacked-bar-data"
    >
      <StyledStackedBarPlot
        className="styled-stacked-bar-plot"
        data-testid="stacked-bar-plot"
        sx={{
          height: variant === "horizontal" ? "15px" : barPlotLength,
          width: variant === "horizontal" ? barPlotLength : "15px",
          ...styleIf(variant === "horizontal", {
            marginRight: "0px",
            float: "none",
            marginBottom: "25px",
          }),
        }}
      >
        {stackedBars.map((bar) => {
          const percent = bar.value / total
          return (
            <StackedBar
              key={bar.value}
              variant={variant}
              color={bar.color}
              length={
                variant === "horizontal"
                  ? percent * 100
                  : barPlotLength * percent
              }
            />
          )
        })}
      </StyledStackedBarPlot>
      <Div
        className="styled-stacked-bar-labels"
        ref={labels}
        sx={{ float: variant === "vertical" ? "left" : "none" }}
      >
        {stackedBars.map((bar) => {
          return (
            <Div
              key={bar.value}
              className="styled-stacked-bar-label"
              sx={{
                marginBottom: "40px",
                "&:last-child": { marginBottom: 0 },
                ...(variant === "horizontal" && {
                  marginBottom: "5px",
                }),
              }}
            >
              <StyledBarLegendItem sx={{ backgroundColor: bar.color }} />
              <Span
                sx={{
                  fontWeight: 600,
                  ...styleIf(variant === "horizontal", {
                    float: "right",
                  }),
                }}
              >
                {bar.value}
              </Span>{" "}
              <Span
                sx={{
                  fontSize: "12px",
                  color: "#8991AB",
                  ...styleIf(variant === "horizontal", {
                    color: bar.color,
                    fontWeight: 600,
                  }),
                }}
              >
                {bar.title}
              </Span>
            </Div>
          )
        })}
      </Div>
      <Clear />
    </StyledStackedBarData>
  )
}

export default StackedBarDataPlot
