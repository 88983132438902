import { generateHTML } from "@tiptap/html"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Bold from "@tiptap/extension-bold"
import Text from "@tiptap/extension-text"
import Heading from "@tiptap/extension-heading"
import OrderedList from "@tiptap/extension-ordered-list"
import Underline from "@tiptap/extension-underline"
import Italic from "@tiptap/extension-italic"
import BulletList from "@tiptap/extension-bullet-list"
import ListItem from "@tiptap/extension-list-item"
import HardBreak from "@tiptap/extension-hard-break"
import { AnyExtension, JSONContent } from "@tiptap/core"

export const generateHTMLFromJsonContent = (
  content: JSONContent,
  editorFormatExtensions: AnyExtension[]
) => {
  const defaultEditorFormatExtensions = [
    Document,
    Paragraph,
    Text,
    Bold,
    Heading,
    Underline,
    Italic,
    OrderedList,
    ListItem,
    BulletList,
    HardBreak,
  ]
  return generateHTML(content as JSONContent, [
    ...defaultEditorFormatExtensions,
    ...editorFormatExtensions,
  ])
}

export const initialEditorData: JSONContent = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Example ",
        },
        {
          type: "text",
          marks: [
            {
              type: "bold",
            },
          ],
          text: "Text",
        },
      ],
    },
  ],
}
