import { useAuthenticator } from "@aws-amplify/ui-react"
import { Configuration, DefaultApi } from "./generated/fetchclient"
import { useRecoilState } from "recoil"
import { useMemo } from "react"
import { Auth } from "aws-amplify"
import { JWT } from "./state/auth.state"

export const djangoAPIHost = process.env.REACT_APP_DJANGO_API_ENDPOINT

export const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID

export const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID

export const launchDarklyClientID =
  process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || ""

/**
 * usePortalApi is a hook that constructs a PortalApi instance used for backend requests.
 */
// export const usePortalApi = () => {
//   const { user, signOut } = useAuthenticator((context) => [context.user])
//   const setupJwt = () =>
//     Auth.currentSession()
//       .then((session) => session.getIdToken().getJwtToken())
//       .then((newJWT) => {
//         return newJWT
//       })
//       .catch((err) => {
//         signOut()
//         return undefined
//       })
//   const api = useMemo(() => {
//     const jwt = setupJwt()
//     if (jwt) {
//       const configuration = new Configuration({
//         basePath: djangoAPIHost,
//         accessToken: jwt,
//       })
//       return new DefaultApi(configuration)
//     }
//   }, [user])

//   if (jwt === undefined) {
//     return
//   }

//   return api
// }
export const usePortalApi = (): DefaultApi | undefined => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const [jwt, setJWT] = useRecoilState(JWT)

  const setupJwt = async () => {
    try {
      const session = await Auth.currentSession()
      const idToken = await session.getIdToken()
      const newJWT = idToken.getJwtToken()
      if (idToken.decodePayload()["token_use"] === "id") {
        setJWT(newJWT)
      }
    } catch (error) {
      setJWT(undefined)
      signOut()
    }
  }

  const api = useMemo(() => {
    setupJwt()
    if (jwt && user) {
      const configuration = new Configuration({
        basePath: djangoAPIHost,
        accessToken: jwt,
      })
      return new DefaultApi(configuration)
    }
  }, [jwt, user])
  return api
}
