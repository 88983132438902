import { Bar } from "@visx/shape"
import { ScrollIndicatorParams } from "../../../types/scroller.type"

/**
 * ScrollIndicator is used to display a visual gradient when scrolling and event
 *
 * @param {ScrollIndicatorParams} ScrollIndicatorParams
 * @returns {JSX.Element | null}
 */
export const ScrollIndicator = ({
  scrollMode,
  height,
  width,
  color,
}: ScrollIndicatorParams): JSX.Element | null => {
  if (scrollMode === undefined) {
    return null
  }

  return (
    <>
      <linearGradient id="scroll-active-left">
        <stop stopColor={color} stopOpacity={0.8} offset="0%" />
        <stop stopColor={color} stopOpacity={0.5} offset="50%" />
        <stop stopColor={color} stopOpacity={0} offset="100%" />
      </linearGradient>
      <linearGradient id="scroll-active-right">
        <stop stopColor={color} stopOpacity={0} offset="0%" />
        <stop stopColor={color} stopOpacity={0.5} offset="50%" />
        <stop stopColor={color} stopOpacity={0.8} offset="100%" />
      </linearGradient>
      <Bar
        pointerEvents="none"
        x={scrollMode.orientation === "left" ? 0 : width - 15}
        y={0}
        width={15}
        height={height}
        fill={`url(#scroll-active-${scrollMode.orientation})`}
      />
    </>
  )
}
