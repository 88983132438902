export const patientRaceOptions = [
  { label: "Asian", value: "AS" },
  { label: "American Indian or Alaska Native", value: "AI" },
  { label: "Black or African American", value: "AA" },
  { label: "Native Hawaiian or Pacific Islander", value: "PI" },
  { label: "White", value: "WH" },
]

export const patientSexOptions = [
  { label: "Female", value: "F" },
  { label: "Male", value: "M" },
]

export const patientRaceToString: { [key: string]: string } = {
  AS: "Asian",
  AI: "American Indian or Alaska Native",
  AA: "Black or African American",
  PI: "Native Hawaiian or Pacific Islander",
  WH: "White",
}
