/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationLocationOut
 */
export interface OrganizationLocationOut {
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationLocationOut
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationOut
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLocationOut
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationLocationOut
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the OrganizationLocationOut interface.
 */
export function instanceOfOrganizationLocationOut(value: object): value is OrganizationLocationOut {
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    return true;
}

export function OrganizationLocationOutFromJSON(json: any): OrganizationLocationOut {
    return OrganizationLocationOutFromJSONTyped(json, false);
}

export function OrganizationLocationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationLocationOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'description': json['description'] == null ? undefined : json['description'],
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'phone': json['phone'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'isPrimary': json['is_primary'] == null ? undefined : json['is_primary'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
    };
}

export function OrganizationLocationOutToJSON(value?: OrganizationLocationOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'description': value['description'],
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'phone': value['phone'],
        'enabled': value['enabled'],
        'is_primary': value['isPrimary'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
    };
}

