import {
  Box,
  Chip,
  Dropdown,
  FormControl,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Option,
  Select,
  Sheet,
  Stack,
} from "@mui/joy"
import { MoreVert, Refresh, Search } from "@mui/icons-material"
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import NewUserModal from "./newusermodal"
import { UserRole } from "../../views/auth/roles"
import { StyledDataGrid } from "../../styled/DataGrid"
import { createBasicGridColDef } from "../../utils/tableUtils"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import useUsers from "../../hooks/useUsers"
import { GetAllUsersRequest, UserOut } from "../../generated/fetchclient"
import StatusIndicator from "../statusindicator/StatusIndicator"

export interface UserTableRow {
  id?: string
  firstName?: string
  lastName?: string
  email: string
  phone: string
  primaryRole: string
  status: string
}

const userRoles = Object.keys(UserRole)

const userStatuses = ["Active", "Inactive"]

const userStatusValueOptions = userStatuses.map((status: string) => ({
  value: status,
  label: status,
}))

const emptyUserQuery: GetAllUsersRequest = {
  text: "",
  roles: [],
  statuses: [],
}

function UserTable() {
  const users = useUsers()
  const { users: apiUsers, userCount, setUserActive } = users
  const [isLoading, setIsLoading] = useState(false)
  const [roleFilter, setRoleFilter] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [tableRows, setTableRows] = useState<UserTableRow[]>(
    [] as UserTableRow[]
  )
  const [userQuery, setUserQuery] = useState<GetAllUsersRequest>(emptyUserQuery)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>()

  useEffect(() => {
    const rows: UserTableRow[] = apiUsers.map((u: UserOut) => ({
      id: u.uuid,
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
      phone: u.phone,
      primaryRole: u.primaryRole,
      status: u.isActive ? "Active" : "Inactive",
    }))

    setTableRows(rows)
  }, [apiUsers])

  const searchUsers = async (
    paginationModel: GridPaginationModel | undefined = undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(userQuery)

    setIsLoading(true)
    if (paginationModel === undefined) {
      await users.listUsers(filteredQuery)
    } else {
      await users.listUsers({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const resetPassword = async (userId: string) => {
    //   if (api !== undefined) {
    //     try {
    //       await api.hstportalApiResetPassword({
    //         userId: userId,
    //       })
    //       setSnackAlertMsg({
    //         open: true,
    //         severity: "success",
    //         message: "Password reset email sent",
    //         autoHideDuration: 5000,
    //       })
    //     } catch (error) {
    //       handleApiError(setSnackAlertMsg)(error)
    //     }
    //   }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchUsers(paginationModel)
    }
  }

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID", 0),
    createBasicGridColDef("firstName", "First Name", 10),
    createBasicGridColDef("lastName", "Last Name", 10),
    createBasicGridColDef("email", "Email", 10),
    createBasicGridColDef("phone", "Phone", 10),
    createBasicGridColDef("primaryRole", "Role"),
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 12,
      editable: false,
      valueOptions: userStatusValueOptions,
      renderCell: (params) => {
        const currentRow = params.row

        return StatusIndicator(currentRow.status)
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      renderCell: (params) => {
        const currentRow = params.row

        return (
          <Dropdown>
            <MenuButton slots={{ root: IconButton }}>
              <MoreVert />
            </MenuButton>
            <Menu placement="bottom-end">
              <MenuItem onClick={() => resetPassword(currentRow.id)}>
                Reset Password
              </MenuItem>
              {currentRow.status === "Active" ? (
                <MenuItem onClick={() => setUserActive(currentRow.id, false)}>
                  Disable User
                </MenuItem>
              ) : (
                <MenuItem onClick={() => setUserActive(currentRow.id, true)}>
                  Enable User
                </MenuItem>
              )}
            </Menu>
          </Dropdown>
        )
      },
    },
  ]

  return (
    <Stack spacing={2} sx={{ overflow: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Stack direction="row" spacing={2} display="flex" alignItems="center">
          <FormControl>
            <Input
              placeholder="Name, email, or phone"
              value={userQuery.text}
              onChange={(e) =>
                setUserQuery({
                  ...userQuery,
                  text: e.target.value,
                })
              }
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <FormControl sx={{ minWidth: 250 }}>
            <Select
              startDecorator="Role"
              multiple
              value={roleFilter}
              onChange={(_, newValue) => {
                setUserQuery({
                  ...userQuery,
                  roles: newValue,
                })
                setRoleFilter(newValue)
              }}
              placeholder="All"
              renderValue={(value) => {
                return (
                  <Sheet sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {value.map((selectedOption) => (
                      <Chip
                        key={selectedOption.value}
                        sx={{
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                      >
                        {selectedOption.label}
                      </Chip>
                    ))}
                  </Sheet>
                )
              }}
            >
              {userRoles.map((role, index) => (
                <Option key={index} value={role}>
                  {role}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 250 }}>
            <Select
              startDecorator="Status"
              multiple
              value={statusFilter}
              onChange={(_, newValue) => {
                const statuses = newValue.map((status) =>
                  status === "Active" ? "True" : "False"
                )
                setUserQuery({
                  ...userQuery,
                  statuses,
                })
                setStatusFilter(newValue)
              }}
              placeholder="All"
              renderValue={(value) => {
                return (
                  <Sheet sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {value.map((selectedOption) => (
                      <Chip
                        key={selectedOption.value}
                        sx={{
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                      >
                        {selectedOption.label}
                      </Chip>
                    ))}
                  </Sheet>
                )
              }}
            >
              {userStatuses.map((status, index) => (
                <Option key={index} value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => searchUsers(paginationModel)}
            variant="solid"
            color="primary"
          >
            <Search />
          </IconButton>
          <IconButton
            onClick={() => {
              setUserQuery(emptyUserQuery)
              setRoleFilter([])
              setStatusFilter([])
              if (paginationModel === undefined) {
                users.listUsers()
              } else {
                users.listUsers({
                  limit: paginationModel.pageSize,
                  offset: paginationModel.page * paginationModel.pageSize,
                })
              }
            }}
            variant="outlined"
            color="primary"
          >
            <Refresh />
          </IconButton>
        </Stack>
        <NewUserModal />
      </Box>
      <Box sx={{ height: "calc(100% - 52px)" }}>
        <StyledDataGrid
          rows={tableRows}
          columns={columns}
          rowCount={userCount}
          loading={isLoading}
          paginationMode="server"
          onPaginationModelChange={(paginationModel) => {
            setPaginationModel(paginationModel)
            searchUsers(paginationModel)
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Box>
    </Stack>
  )
}

export default UserTable
