import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Container from "@mui/joy/Container"
import { Typography } from "@mui/material"
import { AuthenticatorSetup } from "../../components/auth/AuthenticatorSetup"
import { useState } from "react"

export const MFAView = () => {
  const [selectedMethod, setSelectedMethod] = useState("")

  const handleSelectOption = (option: string) => {
    setSelectedMethod(option)
  }

  const handleBack = () => {
    setSelectedMethod("")
  }

  return (
    <>
      <Container component="main" maxWidth="md">
        {selectedMethod === "" ? (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Multi-Factor Authentication Setup
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography variant="body2" color="textSecondary" align="center">
                Select an option to set up Multi-Factor Authentication:
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Button
                color="primary"
                onClick={() => handleSelectOption("authenticator")}
              >
                Use Authenticator App
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                onClick={() => handleSelectOption("sms")}
                disabled={true}
              >
                Use SMS
              </Button>
            </Box>
          </Box>
        ) : selectedMethod === "authenticator" ? (
          <>
            <Box sx={{ mt: 3 }}>
              <Box>
                <AuthenticatorSetup />
              </Box>
              <Box>
                <Button color="neutral" onClick={handleBack}>
                  Back
                </Button>
              </Box>
            </Box>
          </>
        ) : null}
      </Container>
    </>
  )
}
