import { useEffect } from "react"
import {
  Authenticator,
  useAuthenticator,
  Theme,
  ThemeProvider as CognitoThemeProvider,
} from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { useLocation, useNavigate } from "react-router-dom"
import AuthLayout from "./AuthContainer"
import { Box, Typography, useTheme } from "@mui/joy"
import { StyledLogo } from "../../styled/Img"

export function AuthStyle() {
  const components = {
    SignIn: {
      Header() {
        return (
          <Box
            sx={{
              pt: { xs: 1, sm: 3 },
            }}
          >
            <StyledLogo
              src="/img/SANSA_Logo_Color_byHuxley@2x.png"
              alt="logo"
            />
          </Box>
        )
      },
    },
  }
  const theme = useTheme()
  const cognitoTheme: Theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: theme.vars.palette.background.body,
          },
          secondary: {
            value: theme.vars.palette.background.body,
          },
        },
        font: {
          interactive: {
            value: theme.vars.palette.text.primary,
          },
        },
        brand: {
          primary: {
            "10": theme.vars.palette.primary[100],
            "80": theme.vars.palette.primary[500],
            "90": theme.vars.palette.primary[500],
            "100": theme.vars.palette.primary[700],
          },
        },
      },
      components: {
        authenticator: {
          router: {
            borderColor: {
              value: theme.vars.palette.divider,
            },
            borderWidth: {
              value: "0px",
            },
            borderStyle: {
              value: "solid",
            },
            boxShadow: {
              value: "none",
            },
          },
        },
      },
    },
  }
  return (
    <CognitoThemeProvider theme={cognitoTheme}>
      <Authenticator hideSignUp={true} components={components} />
    </CognitoThemeProvider>
  )
}

export function CognitoLogin() {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || "/"
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])
  return (
    <AuthLayout>
      <AuthStyle />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontSize={7}>
          Version: {process.env.REACT_APP_GIT_TAG}
        </Typography>
      </Box>
    </AuthLayout>
  )
}
