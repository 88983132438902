import { useEffect, useState } from "react"
import * as EmailValidator from "email-validator"
import Box from "@mui/joy/Box"
import Dropdown from "@mui/joy/Dropdown"
import IconButton from "@mui/joy/IconButton"
import Menu from "@mui/joy/Menu"
import MenuButton from "@mui/joy/MenuButton"
import MenuItem from "@mui/joy/MenuItem"
import Stack from "@mui/joy/Stack"
import LoadingIndicator from "../../components/loading/LoadingIndicator"
import { usePortalApi } from "../../connections"
import useOrganizations from "../../hooks/useOrganizations"
import DataGridTable from "../../components/datagridtable/DataGridTable"
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid"
import { createBasicGridColDef } from "../../utils/tableUtils"
import MoreVert from "@mui/icons-material/MoreVert"
import {
  GetAllOrganizationsRequest,
  OrganizationOut,
} from "../../generated/fetchclient"
import OrganizationContentMenuHeader from "./OrganizationContentMenuHeader"
import OrganizationContentSearchHeader from "./OrganizationContentSearchHeader"
import { removeEmptyValuesFromObject } from "../../utils/utils"
import { OrganizationTableRow } from "../../types/table.type"
import OrganizationAddEditModal from "./OrganizationAddEditModal"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../../components/SnackAlerts"
import phone from "phone"
import {
  emptyOrganization,
  emptyOrganizationInputErrors,
  emptyOrganizationQuery,
} from "../../const/organization.const"
import { emptyOrganizationLocationErrors } from "../../interfaces/organization.interface"
import StatusIndicator from "../../components/statusindicator/StatusIndicator"

const OrganizationContent = () => {
  const api = usePortalApi()
  const organizationsApi = useOrganizations()
  const { organizations, organizationCount, resetOrganizationData } =
    organizationsApi
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [individualOrganization, setIndividualOrganization] =
    useState<OrganizationTableRow>({} as OrganizationTableRow)
  const [organizationRows, setOrganizationRows] = useState<
    OrganizationTableRow[]
  >([] as OrganizationTableRow[])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [inputOrganizationErrors, setInputOrganizationErrors] = useState(
    emptyOrganizationInputErrors
  )
  const statusOrganizationModal = (status: boolean) => {
    setOpenModal(status)
    setIndividualOrganization(emptyOrganization)
  }
  const [modalTitle, setModalTitle] = useState<string>("")
  const openAddModal = () => {
    setModalTitle("Create Organization")
    statusOrganizationModal(true)
  }
  const editOrganization = (organizationData: OrganizationTableRow) => {
    setModalTitle("Edit Organization")
    statusOrganizationModal(true)
    setIndividualOrganization(organizationData)
  }
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>()
  const [organizationQuery, setOrganizationQuery] =
    useState<GetAllOrganizationsRequest>(emptyOrganizationQuery)
  const searchOrganization = async (
    paginationModel: GridPaginationModel | undefined
  ) => {
    const filteredQuery = removeEmptyValuesFromObject(organizationQuery)
    setIsLoading(true)
    if (paginationModel === undefined) {
      await organizationsApi.listOrganizations(filteredQuery)
    } else {
      await organizationsApi.listOrganizations({
        ...filteredQuery,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
      })
    }
    setIsLoading(false)
  }

  const changeOrganizationStatus = async (
    organization: OrganizationTableRow
  ) => {
    setIsLoading(true)
    await organizationsApi.updateOrganization(organization.id as string, {
      ...organization,
      enabled: !organization.enabled,
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (modalTitle !== "Edit Organization") statusOrganizationModal(openModal)
  }, [openModal])

  useEffect(() => {
    if (api === undefined) return

    setIsLoading(true)
    const fetchData = async () => {
      await organizationsApi.listOrganizations()
      setIsLoading(false)
    }
    fetchData()

    return () => {
      resetOrganizationData()
    }
  }, [api])

  useEffect(() => {
    const rows: OrganizationTableRow[] = organizations.map(
      (orgData: OrganizationOut, index: number) => ({
        id: orgData.uuid,
        name: orgData.name,
        contactName: orgData.contactName,
        contactPhone: orgData.contactPhone,
        contactEmail: orgData.contactEmail,
        description: orgData.description,
        enabled: !!orgData.enabled ? orgData.enabled : false,
        locations: orgData.locations,
        users: orgData.users,
        index: index,
      })
    )
    setOrganizationRows(rows)
  }, [organizations])

  const validOrganizationForm = () => {
    let nameError = ""
    let contactNameError = ""
    let contactEmailError = ""
    let contactPhoneError = ""
    const locationErrorsArray: emptyOrganizationLocationErrors[] = []

    if (individualOrganization.name.trim() === "") {
      nameError = "Name is required"
    }

    if (individualOrganization.contactName.trim() === "") {
      contactNameError = "Contact Name is required"
    }

    if (individualOrganization.contactName.trim() === "") {
      nameError = "Contact Name is required"
    }

    if (!phone(individualOrganization.contactPhone).isValid) {
      contactPhoneError = "Invalid phone number"
    }

    if (!EmailValidator.validate(individualOrganization.contactEmail)) {
      contactEmailError = "Invalid email address"
    }

    individualOrganization.locations.forEach((location, index) => {
      const locationErrors: emptyOrganizationLocationErrors = {}
      if (!location.address1) locationErrors.address1 = "Address 1 is required"
      if (!location.city) locationErrors.city = "City is required"
      if (!location.state) locationErrors.state = "State is required"
      if (!location.zip) locationErrors.zip = "Zip is required"
      if (!phone(location.phone).isValid)
        locationErrors.phone = "Phone is required"
      if (location.isPrimary === undefined)
        locationErrors.isPrimary = "Primary is required"
      if (location.enabled === undefined)
        locationErrors.enabled = "Enabled is required"

      if (Object.keys(locationErrors).length > 0) {
        locationErrorsArray[index] = locationErrorsArray[index] || []
        locationErrorsArray[index] = locationErrors
      }
    })

    const errors = {
      name: nameError,
      contactName: contactNameError,
      contactEmail: contactEmailError,
      contactPhone: contactPhoneError,
      locations: locationErrorsArray,
    }
    setInputOrganizationErrors(errors)

    const isLocationErrorEmpty = (
      locationError: emptyOrganizationLocationErrors
    ) => {
      return Object.values(locationError).every((field) => field === "")
    }

    const allLocationErrorsEmpty =
      locationErrorsArray.every(isLocationErrorEmpty)

    const allErrorsEmpty = Object.values(errors).every((e) => {
      if (Array.isArray(e)) {
        return allLocationErrorsEmpty
      }
      return e === ""
    })

    return allErrorsEmpty
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validOrganizationForm()) {
      return
    }
    setIsLoading(true)
    modalTitle === "Edit Organization"
      ? await organizationsApi.updateOrganization(
          individualOrganization.id as string,
          individualOrganization
        )
      : await organizationsApi.createOrganization(individualOrganization)
    setIndividualOrganization(emptyOrganization)
    setOpenModal(false)
    setIsLoading(false)
  }

  const columns: GridColDef[] = [
    createBasicGridColDef("id", "ID"),
    createBasicGridColDef("name", "Name"),
    createBasicGridColDef("contactName", "Admin Name"),
    createBasicGridColDef("contactEmail", "Admin Email"),
    createBasicGridColDef("contactPhone", "Primary Contact"),
    {
      field: "enabled",
      headerName: "Status",
      sortable: true,
      editable: false,
      renderCell: (params) => {
        return StatusIndicator(params.row.enabled ? "Active" : "InActive")
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{
                root: { variant: "outlined", color: "neutral" },
              }}
            >
              <MoreVert />
            </MenuButton>
            <Menu placement="bottom-end">
              <MenuItem
                disabled={!params.row.enabled}
                onClick={() => editOrganization(params.row)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault()
                  changeOrganizationStatus(params.row)
                }}
              >
                {params.row.enabled
                  ? "Disable Organization"
                  : "Enable Organization"}
              </MenuItem>
            </Menu>
          </Dropdown>
        )
      },
    },
  ]

  return (
    <>
      <Box
        sx={{
          px: { xs: 0, sm: 2 },
          padding: 0,
          border: 0,
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <OrganizationContentMenuHeader />
        <Stack spacing={2} sx={{ overflow: "hidden" }}>
          <OrganizationContentSearchHeader
            openAddModal={openAddModal}
            organizationQuery={organizationQuery}
            setOrganizationQuery={setOrganizationQuery}
            paginationModel={paginationModel}
            searchOrganization={searchOrganization}
            emptyOrganizationQuery={emptyOrganizationQuery}
          />
          <Box sx={{ height: "calc(100% - 52px)" }}>
            <DataGridTable
              rows={organizationRows}
              rowCount={organizationCount}
              columns={columns}
              setPaginationModel={setPaginationModel}
              paginationAction={searchOrganization}
              hideFooterSelectedRowCount={true}
              disableRowSelectionOnClick={true}
            />
          </Box>
        </Stack>
      </Box>
      <OrganizationAddEditModal
        openModal={openModal}
        setOpen={setOpenModal}
        modalTitle={modalTitle}
        inputOrganizationErrors={inputOrganizationErrors}
        handleSubmit={handleSubmit}
        individualOrganization={individualOrganization}
        setIndividualOrganization={setIndividualOrganization}
      />
      {isLoading && (
        <LoadingIndicator header="Loading Organizations" content=" " />
      )}
    </>
  )
}

export default OrganizationContent
