import { LinePathPlot } from "@huxley-medical/react-components/components"
import { ScaleLinear } from "d3-scale"
import { Signal } from "../../../interfaces/signals.interface"

interface GraphLinePathPlotProps {
  data: Signal
  timeScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  plotWidth: number
  lineColor: string
}

const GraphLinePathPlot = ({
  data,
  timeScale,
  yScale,
  plotWidth,
  lineColor,
}: GraphLinePathPlotProps) => {
  return (
    <LinePathPlot
      data={data}
      timeScale={timeScale}
      yScale={yScale}
      width={plotWidth}
      stroke={lineColor}
      strokeWidth={1.5}
      downsampleMethod="minMax"
    />
  )
}

export default GraphLinePathPlot
