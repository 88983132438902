/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudyEdfDownload
 */
export interface StudyEdfDownload {
    /**
     * 
     * @type {string}
     * @memberof StudyEdfDownload
     */
    presignedUrl?: string;
}

/**
 * Check if a given object implements the StudyEdfDownload interface.
 */
export function instanceOfStudyEdfDownload(value: object): value is StudyEdfDownload {
    return true;
}

export function StudyEdfDownloadFromJSON(json: any): StudyEdfDownload {
    return StudyEdfDownloadFromJSONTyped(json, false);
}

export function StudyEdfDownloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyEdfDownload {
    if (json == null) {
        return json;
    }
    return {
        
        'presignedUrl': json['presigned_url'] == null ? undefined : json['presigned_url'],
    };
}

export function StudyEdfDownloadToJSON(value?: StudyEdfDownload | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'presigned_url': value['presignedUrl'],
    };
}

