import { Slide, SvgIconTypeMap, Typography } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Div } from "../../elements"
import Box from "./Box"

type StatBoxParams = {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
  iconColor?: string
  title: React.ReactNode
  subtext: React.ReactNode
  onInfoClick?: () => void
}

const StatBox = ({
  icon,
  iconColor = "#5A97F7",
  title,
  subtext,
  onInfoClick,
}: StatBoxParams) => {
  const Icon = icon
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .MuiTypography-h4": {
          fontSize: "16px",
          fontWeight: 600,
        },
        "& .MuiTypography-h3": {
          fontSize: "24px",
          fontWeight: 600,
        },
      }}
    >
      <Slide in direction="up" mountOnEnter unmountOnExit>
        <Icon
          sx={{
            color: iconColor,
            width: "37px",
            height: "37px",
            marginRight: "25px",
          }}
        />
      </Slide>
      <Div>
        <Typography
          variant="h3"
          sx={{ marginBottom: "6px", lineHeight: "30px" }}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "#7E7B96",
            fontSize: "15px",
          }}
        >
          {subtext}
          <InfoOutlinedIcon
            role="button"
            onClick={onInfoClick}
            sx={{
              width: "20px",
              height: "20x",
              cursor: "pointer",
              verticalAlign: "middle",
              marginLeft: "5px",
            }}
          />
        </Typography>
      </Div>
    </Box>
  )
}

export default StatBox
