/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SleepStudyOutBase } from './SleepStudyOutBase';
import {
    SleepStudyOutBaseFromJSON,
    SleepStudyOutBaseFromJSONTyped,
    SleepStudyOutBaseToJSON,
} from './SleepStudyOutBase';
import type { SleepStudyPatientOut } from './SleepStudyPatientOut';
import {
    SleepStudyPatientOutFromJSON,
    SleepStudyPatientOutFromJSONTyped,
    SleepStudyPatientOutToJSON,
} from './SleepStudyPatientOut';
import type { PhysicianOut } from './PhysicianOut';
import {
    PhysicianOutFromJSON,
    PhysicianOutFromJSONTyped,
    PhysicianOutToJSON,
} from './PhysicianOut';

/**
 * 
 * @export
 * @interface SleepStudyOrderOut
 */
export interface SleepStudyOrderOut {
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    uuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOrderOut
     */
    deletedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SleepStudyOrderOut
     */
    id: number;
    /**
     * 
     * @type {SleepStudyPatientOut}
     * @memberof SleepStudyOrderOut
     */
    patient: SleepStudyPatientOut;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    zip: string;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOrderOut
     */
    orderDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SleepStudyOrderOut
     */
    scheduledDate: Date;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof SleepStudyOrderOut
     */
    statusHistory: object;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    oxygenDesatThreshold: string;
    /**
     * 
     * @type {PhysicianOut}
     * @memberof SleepStudyOrderOut
     */
    orderingPhysician: PhysicianOut;
    /**
     * 
     * @type {PhysicianOut}
     * @memberof SleepStudyOrderOut
     */
    interpretingPhysician: PhysicianOut;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    assignedDevice: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    organization: string;
    /**
     * 
     * @type {Array<SleepStudyOutBase>}
     * @memberof SleepStudyOrderOut
     */
    studies: Array<SleepStudyOutBase>;
    /**
     * 
     * @type {string}
     * @memberof SleepStudyOrderOut
     */
    activeStudyId?: string;
}

/**
 * Check if a given object implements the SleepStudyOrderOut interface.
 */
export function instanceOfSleepStudyOrderOut(value: object): value is SleepStudyOrderOut {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('patient' in value) || value['patient'] === undefined) return false;
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('orderDate' in value) || value['orderDate'] === undefined) return false;
    if (!('scheduledDate' in value) || value['scheduledDate'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('statusHistory' in value) || value['statusHistory'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('oxygenDesatThreshold' in value) || value['oxygenDesatThreshold'] === undefined) return false;
    if (!('orderingPhysician' in value) || value['orderingPhysician'] === undefined) return false;
    if (!('interpretingPhysician' in value) || value['interpretingPhysician'] === undefined) return false;
    if (!('assignedDevice' in value) || value['assignedDevice'] === undefined) return false;
    if (!('organization' in value) || value['organization'] === undefined) return false;
    if (!('studies' in value) || value['studies'] === undefined) return false;
    return true;
}

export function SleepStudyOrderOutFromJSON(json: any): SleepStudyOrderOut {
    return SleepStudyOrderOutFromJSONTyped(json, false);
}

export function SleepStudyOrderOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepStudyOrderOut {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'deletedAt': json['deleted_at'] == null ? undefined : (new Date(json['deleted_at'])),
        'id': json['id'],
        'patient': SleepStudyPatientOutFromJSON(json['patient']),
        'address1': json['address_1'],
        'address2': json['address_2'] == null ? undefined : json['address_2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'orderDate': (new Date(json['order_date'])),
        'scheduledDate': (new Date(json['scheduled_date'])),
        'status': json['status'],
        'statusHistory': json['status_history'],
        'type': json['type'],
        'oxygenDesatThreshold': json['oxygen_desat_threshold'],
        'orderingPhysician': PhysicianOutFromJSON(json['ordering_physician']),
        'interpretingPhysician': PhysicianOutFromJSON(json['interpreting_physician']),
        'assignedDevice': json['assigned_device'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'organization': json['organization'],
        'studies': ((json['studies'] as Array<any>).map(SleepStudyOutBaseFromJSON)),
        'activeStudyId': json['active_study_id'] == null ? undefined : json['active_study_id'],
    };
}

export function SleepStudyOrderOutToJSON(value?: SleepStudyOrderOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'deleted_at': value['deletedAt'] == null ? undefined : ((value['deletedAt']).toISOString()),
        'id': value['id'],
        'patient': SleepStudyPatientOutToJSON(value['patient']),
        'address_1': value['address1'],
        'address_2': value['address2'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'order_date': ((value['orderDate']).toISOString().substring(0,10)),
        'scheduled_date': ((value['scheduledDate']).toISOString().substring(0,10)),
        'status': value['status'],
        'status_history': value['statusHistory'],
        'type': value['type'],
        'oxygen_desat_threshold': value['oxygenDesatThreshold'],
        'ordering_physician': PhysicianOutToJSON(value['orderingPhysician']),
        'interpreting_physician': PhysicianOutToJSON(value['interpretingPhysician']),
        'assigned_device': value['assignedDevice'],
        'notes': value['notes'],
        'organization': value['organization'],
        'studies': ((value['studies'] as Array<any>).map(SleepStudyOutBaseToJSON)),
        'active_study_id': value['activeStudyId'],
    };
}

