// - Connection Imports
import { djangoAPIHost, usePortalApi } from "../../connections"

// - MUI Imports
import { IconButton, Stack, Typography } from "@mui/joy"
import { RefreshOutlined } from "@mui/icons-material"

// - React Imports
import { useEffect } from "react"
import { useCookies } from "react-cookie"

// - Recoil Imports
import { useSetRecoilState } from "recoil"

// - Component Imports
import { snackAlert } from "../../components/SnackAlerts"

// - Reusable Function Imports
import { handleApiError } from "../../utils/apiUtils"

const AdminContent = () => {
  const api = usePortalApi()
  const [cookies, setCookie] = useCookies()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)

  async function loadSessionKey(ignoreUndefinedApi = false) {
    if (api === undefined) {
      if (!ignoreUndefinedApi) {
        setSnackAlertMsg({
          open: true,
          message: "Something went wrong. API not found.",
          severity: "error",
          autoHideDuration: 5000,
        })
      }
      return
    }

    try {
      const response = await api.logIn()
      const { sessionKey } = response
      if (sessionKey) {
        setCookie("sessionid", sessionKey, {
          sameSite:
            process.env.REACT_APP_RUNTIME_ENV === "prod" ? "strict" : "lax",
          secure: true,
        })
        window.open(`${djangoAPIHost}/huxleyadminint`)
      }
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  useEffect(() => {
    const apiCall = async () => {
      // `api` may be undefined on first render so avoid showing an error alert
      await loadSessionKey(true)
    }
    apiCall()
  }, [api])

  return (
    <Stack
      alignItems="center"
      height="100%"
      justifyContent="center"
      spacing={10}
    >
      <Typography level="h1">
        {cookies.sessionid
          ? "Django Admin pages opened in new window."
          : "Django Admin session has expired."}
        <br />
        <br />
        Click the button below to re-
        {cookies.sessionid ? "open" : "authenticate"}.
      </Typography>
      <IconButton
        variant="plain"
        color="neutral"
        component="a"
        onClick={async () => await loadSessionKey()}
        sx={{
          minHeight: "200px",
          minWidth: "200px",
        }}
      >
        <RefreshOutlined
          sx={{
            minHeight: "150px",
            minWidth: "150px",
          }}
        />
      </IconButton>
    </Stack>
  )
}

export default AdminContent
