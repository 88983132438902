import React from "react"
import { useRecoilValue } from "recoil"
import { currentStudy, studyMetaData3 } from "../../state/study.state"
import PdfTemplate from "./templates/PdfTemplate"
import { generateHTMLFromJsonContent } from "../../utils/tipTapUtils"
import { JSONContent } from "@tiptap/core"
import PdfClinicianSignature from "./templates/PdfClinicianSignature"

const PdfInterpretationNotes: React.FC<{
  interpretationEditorContentData: JSONContent
}> = ({ interpretationEditorContentData }) => {
  const study = useRecoilValue(currentStudy)
  const studyMetaDataValue = useRecoilValue(studyMetaData3)

  const pdfTemplateData = {
    study_id: study?.id,
    sw_version: studyMetaDataValue.swVersion,
  }

  const interPretationNotesData = generateHTMLFromJsonContent(
    interpretationEditorContentData,
    []
  )

  return (
    <PdfTemplate
      study_id={pdfTemplateData.study_id}
      sw_version={pdfTemplateData.sw_version}
    >
      <div className="section-header">Interpretation Notes</div>
      <div className="pdf-interpretation-content">
        <div
          dangerouslySetInnerHTML={{ __html: interPretationNotesData }}
        ></div>
      </div>
      <PdfClinicianSignature />
    </PdfTemplate>
  )
}

export default PdfInterpretationNotes
