import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy"
import { useRecoilState, useRecoilValue } from "recoil"
import { deleteDeviceModalOpen, deviceToBeDeleted } from "./state"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import useDevices from "../../hooks/useDevices"

// TODO: Refactor this and the other modals to reduce duplication
function DeleteDeviceModal() {
  const deviceApi = useDevices()
  const [modalOpen, setModalOpenState] = useRecoilState(deleteDeviceModalOpen)
  const serialNumber = useRecoilValue(deviceToBeDeleted)

  const deleteDevice = async () => {
    if (serialNumber === undefined) return

    await deviceApi.deleteDevice(serialNumber)
    setModalOpenState(false)
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={modalOpen}
      onClose={() => {
        setModalOpenState(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog variant="outlined" role="alertdialog">
        <ModalClose />
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to remove this device (SN: {serialNumber})?
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={deleteDevice}>
            Delete Device
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => setModalOpenState(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default DeleteDeviceModal
