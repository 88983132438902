import { Box } from "@mui/joy"
import Header from "../../../components/Header"
import Sidebar from "../../../components/Sidebar"
import { useHandleSignOut } from "./hooks"
import { Outlet } from "react-router-dom"

interface AuthenticatedLayoutParams {
  children?: React.ReactNode
  header?: React.ReactNode
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutParams) => {
  useHandleSignOut()

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100dvh",
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      <Header />
      <Sidebar />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 2,
          },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 1,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          marginLeft: {
            xs: 0,
            md: "80px",
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100%",
          boxSizing: "border-box",
          gap: 1,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default AuthenticatedLayout
