import * as React from "react"

import { FormControl, FormHelperText, FormLabel, Grid } from "@mui/joy"

type GridFormInputParams = {
  width: number
  label: string
  error?: string
  children?: React.ReactNode
}

function GridFormInput({
  width,
  label,
  error = "",
  children,
}: GridFormInputParams) {
  return (
    <Grid xs={width}>
      <FormControl error={!!error}>
        <FormLabel>{label}</FormLabel>
        {children}
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Grid>
  )
}

export default GridFormInput
