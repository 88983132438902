import { useSetRecoilState } from "recoil"
import { usePortalApi } from "../connections"
import { snackAlert } from "../components/SnackAlerts"
import { handleApiError } from "../utils/apiUtils"
import { CreateStudyEcgReportRequest } from "../generated/fetchclient"
import { useRef } from "react"
import { isGeneratingPDFReport } from "../state/pdfReport.state"

const useGenerateReportPdf = () => {
  const api = usePortalApi()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const setIsGeneratingPdf = useSetRecoilState(isGeneratingPDFReport)
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)
  const stopTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const generatePdf = async (studyId: CreateStudyEcgReportRequest) => {
    if (api === undefined) return
    try {
      const url = await api.createStudyEcgReport(studyId)
      const getTheFile = async (url: string) => {
        const response = await fetch(url)
        if (!response.ok) {
          intervalIdRef.current = setTimeout(() => {
            getTheFile(url)
          }, 5000)
          return
        }
        const blob = await response.blob()
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = studyId.studyId + ".pdf"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setIsGeneratingPdf(false)
        stopFunction(false)
        setSnackAlertMsg({
          open: true,
          message: "Report generated successfully",
          severity: "success",
          autoHideDuration: 5000,
        })
      }

      const stopFunction = (status: boolean) => {
        if (intervalIdRef.current) {
          clearTimeout(intervalIdRef.current)
        }
        if (stopTimeoutIdRef.current) {
          clearTimeout(stopTimeoutIdRef.current)
        }
        setIsGeneratingPdf(false)
        if (status) {
          setSnackAlertMsg({
            open: true,
            message: "Please try after some time",
            severity: "error",
            autoHideDuration: 5000,
          })
        }
      }

      getTheFile(url.presignedUrl)
      stopTimeoutIdRef.current = setTimeout(() => {
        stopFunction(true)
      }, 120000)
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
    }
  }

  return {
    generatePdf,
  }
}

export default useGenerateReportPdf
