import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import FilterListIcon from "@mui/icons-material/FilterList"
import { SvgIcon } from "@mui/material"

type optionParams = {
  label: number | string
  value: number | string
}

type SelectMenuParams = {
  icon?: typeof SvgIcon
  title: string
  selectItems: optionParams[]
  noneSelectedDefault: string | number
  defaultSelected?: string | number
  onSelectionChange: (selected: string) => void
}

/**
 * SelectMenu is a functional component for select menus
 *
 * @returns {JSX.Element} JSX.Element
 */
const SelectMenu = ({
  title = "Select Menu",
  icon = FilterListIcon,
  selectItems,
  noneSelectedDefault = "All",
  defaultSelected,
  onSelectionChange,
}: SelectMenuParams): JSX.Element => {
  const Icon = icon
  const FilterLabel = ({ label }: { label: string }) => (
    <>
      <Icon
        sx={{
          verticalAlign: "middle",
          marginRight: "5px",
          color: "#8991AB",
          fontSize: "20px",
        }}
      />
      {label}
    </>
  )

  const handleChange = (e: SelectChangeEvent) => {
    const changeVal = e.target.value

    onSelectionChange(changeVal)
  }

  return (
    <FormControl
      role="menu"
      sx={{ width: "50%", fontSize: "13px" }}
      size="small"
    >
      <InputLabel
        id="select-label"
        data-testid="menu-select"
        sx={{ color: "#000", fontWeight: "600", fontSize: "13px" }}
      >
        <FilterLabel label={title} />
      </InputLabel>
      <Select
        labelId="select-label"
        value={defaultSelected || noneSelectedDefault}
        label={<FilterLabel label={title} />}
        onChange={handleChange}
        sx={{ fontSize: "inherit" }}
      >
        {selectItems.map((t) => (
          <MenuItem role="option" key={t.label} value={t.value}>
            {t.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectMenu
