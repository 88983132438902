import { Fade, IconButton, styled, Typography } from "@mui/material"
import { PortalWithState } from "react-portal"
import CloseIcon from "@mui/icons-material/Close"
import { SxProps } from "@mui/system"
import { Div } from "../../elements"
import Box from "./Box"

const StyledModal = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(255,255,255, 0.1)",
  zIndex: 1101,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const StyledModelTitle = styled(Typography)({
  color: "#4A4A4A",
  fontWeight: 600,
  fontSize: "16px",
  borderBottom: "1px solid #DEDEDE",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingTop: "20px",
  paddingBottom: "20px",
})

const StyledInnerBox = styled(Box)({
  width: "70%",
  minHeight: "65px",
  position: "relative",
  padding: 0,
  borderColor: "#dedede",
})

const Modal = ({
  title,
  children,
  sx,
  onModalClose,
  onModalOpen,
  activate,
  renderChildren,
}: {
  title?: React.ReactNode
  children?: React.ReactNode
  onModalClose?: () => void
  onModalOpen?: () => void
  renderChildren?: (closeModal: () => void) => JSX.Element
  activate: ({ openModel }: { openModel: (event?: any) => void }) => JSX.Element
  sx?: SxProps
}) => {
  return (
    <PortalWithState closeOnEsc>
      {({ openPortal, closePortal, portal, isOpen }) => {
        const openModel = (e: any) => {
          openPortal(e)
          if (onModalOpen !== undefined) onModalOpen()
          document.body.style.overflow = "hidden"
        }

        const closeModal = () => {
          closePortal()
          if (onModalClose !== undefined) onModalClose()
          document.body.style.overflow = "auto"
        }

        return (
          <>
            {activate({ openModel })}
            {portal(
              <StyledModal className="styled-modal">
                <Fade timeout={750} in={isOpen}>
                  <StyledInnerBox sx={sx} role="dialog">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </IconButton>

                    <StyledModelTitle variant="h2">{title}</StyledModelTitle>

                    <Div sx={{ padding: "20px" }}>
                      {children}
                      {renderChildren && renderChildren(closeModal)}
                    </Div>
                  </StyledInnerBox>
                </Fade>
              </StyledModal>
            )}
          </>
        )
      }}
    </PortalWithState>
  )
}

export default Modal
