import React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Avatar from "@mui/material/Avatar"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import MoreIcon from "@mui/icons-material/MoreVert"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import { Badge, Link, styled, SxProps, Theme } from "@mui/material"
import SearchBar from "../base/SearchBar"
import { themeColors } from "../../theme/colors"

import HuxleyLogo from "../../assets/img/HUXLEY_CENTERED_Medium.png"
import AvatarImg from "../../assets/img/avatar.png"

// const settings = ["Profile", "Account", "Dashboard", "Logout"]

type HeaderMenuParams = {
  anchorElNav: HTMLElement | null
  handleCloseNavMenu: () => void
}

/* TODO: tests and customizations */
const HeaderMenu = ({ anchorElNav, handleCloseNavMenu }: HeaderMenuParams) => {
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      <MenuItem>
        <Typography textAlign="center">Studies</Typography>
      </MenuItem>
      <MenuItem>
        <Typography textAlign="center">Order New Study</Typography>
      </MenuItem>
    </Menu>
  )
}

const StyledAppHeaderBar = styled(AppBar)({
  backgroundColor: "#FFFFFF",
  fontFamily: "Inter",
  height: "90px",
  boxShadow: "none",
  borderBottom: "1px solid #EFEEF4",
  paddingLeft: "25px",
  paddingRight: "25px",
  "& .MuiBadge-root": {
    width: "40px",
    height: "40px",
    marginRight: "20px",
  },
  "& .MuiBadge-root .MuiSvgIcon-root": {
    width: "1.2em",
    height: "1.2em",
  },
  "& .MuiBadge-root .MuiBadge-anchorOriginTopRight": {
    top: "5px",
    right: "11px",
  },
  "& .MuiAvatar-circular": {
    backgroundColor: "#F1F3F3",
    width: "46px",
    height: "46px",
    padding: "4px",
  },
  "& .MuiIconButton-root": {
    backgroundColor: "#FFF",
    color: "#8991AB",
    width: "40px",
    height: "40px",
    marginRight: "5px",
  },
  "& .MuiIconButton-root:hover": {
    backgroundColor: themeColors.palette.primary.background,
    color: "#8991AB",
  },
  "& .MuiLink-root": {
    lineHeight: "90px",
    color: "#5A97F7",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: 500,
    fontSize: "16px",
    marginRight: "32px",
  },
  "& .huxley-logo": {
    marginRight: "32px",
    cursor: "pointer",
  },
  "& .MuiButton-root": {
    marginTop: "24px",
    marginBottom: "24px",
    fontWeight: 600,
    fontFamily: "Inter",
    textTransform: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
})

type LinkObject = {
  title: string
  path: string
}

type HandleCloseCallback = (handleClose: () => void) => React.ReactNode

type AppBarHeaderParams = {
  sx?: SxProps<Theme> | undefined
  children?: React.ReactNode
  links?: LinkObject[]
  onLinkClick?: (link: LinkObject) => void
  onSearchChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  hamberMenuItems?: React.ReactNode[] | HandleCloseCallback[]
  userFullName?: string
}

const AppHeaderBar = ({
  sx,
  children,
  links = [{ title: "Studies", path: "/studies" }],
  hamberMenuItems,
  userFullName,
  onLinkClick = () => {},
  onSearchChange,
}: AppBarHeaderParams) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <StyledAppHeaderBar
      sx={{
        borderBottom: "1px solid #EFEEF4",
        // boxShadow: "2.02937px -4px 30.4406px rgb(236 236 236 / 60%)",
        paddingLeft: "25px",
        paddingRight: "25px",
        ...sx,
      }}
      position="fixed"
    >
      <Toolbar sx={{ height: "90px" }} disableGutters>
        <img src={HuxleyLogo} className="huxley-logo" alt="Huxley Logo" />

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <HeaderMenu
            anchorElNav={anchorElNav}
            handleCloseNavMenu={handleCloseNavMenu}
          />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {links.map((link) => (
            <Link
              key={link.title}
              onClick={() => {
                onLinkClick(link)
              }}
              textAlign="center"
            >
              {link.title}
            </Link>
          ))}

          {children}
        </Box>

        <SearchBar
          onChange={onSearchChange}
          placeholder="Search study or patient"
        />

        <Box sx={{ flexGrow: 0 }}>
          <Badge badgeContent="2" color="primary">
            <IconButton sx={{ p: 0 }}>
              <NotificationsNoneOutlinedIcon color="action" />
            </IconButton>
          </Badge>
          <Tooltip title={`Hello, ${userFullName}!`}>
            <IconButton sx={{ p: 0 }}>
              <Avatar
                alt={`The beautiful human named ${userFullName}`}
                src={AvatarImg}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {hamberMenuItems?.map((menuItem) => {
              if (typeof menuItem === "function") {
                return menuItem(handleCloseUserMenu)
              }
              return menuItem
            })}
          </Menu>

          <Tooltip title="Open settings">
            <IconButton
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </StyledAppHeaderBar>
  )
}

export default AppHeaderBar
