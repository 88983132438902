import { Button, Divider, Grid, Input, Option, Select } from "@mui/joy"
import React, { useState } from "react"
import { useSetRecoilState } from "recoil"
import { snackAlert } from "../../SnackAlerts"
import { usePortalApi } from "../../../connections"
import { DeviceIn, DeviceOut } from "../../../generated/fetchclient"
import useDevices from "../../../hooks/useDevices"
import GridFormInput from "../../forms/grid/GridFormInput"

type NewDeviceFormParams = {
  deviceData?: DeviceOut
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const emptyDeviceInput = {
  serialNumber: "",
  deviceType: "sansa",
}

const emptyInputErrors = {
  serialNumber: "",
}

export const deviceTypeOptions = [{ label: "SANSA", value: "sansa" }]

function NewDeviceForm({ deviceData, setOpen }: NewDeviceFormParams) {
  const api = usePortalApi()
  const devicesApi = useDevices()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const [deviceInput, setDeviceInput] = useState<DeviceIn>(
    deviceData
      ? {
          serialNumber: deviceData.serialNumber,
          deviceType: deviceData.deviceType,
        }
      : emptyDeviceInput
  )
  const [inputErrors, setInputErrors] = useState(emptyInputErrors)

  const handlePropChange = (property: keyof DeviceIn, value: Date | string) => {
    setDeviceInput({
      ...deviceInput,
      [property]: value,
    })
  }

  const validateForm = () => {
    let serialNumberError = ""

    if (deviceInput.serialNumber.trim() === "") {
      serialNumberError = "Required"
    }

    const errors = {
      serialNumber: serialNumberError,
    }

    setInputErrors(errors)

    return Object.values(errors).every((e) => e === "")
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (api === undefined) {
      setSnackAlertMsg({
        open: true,
        message: "Something went wrong. API not found.",
        severity: "error",
        autoHideDuration: 5000,
      })
      return
    }

    if (!validateForm()) {
      return
    }

    deviceData
      ? await devicesApi.updateDevice(deviceData.serialNumber, deviceInput)
      : await devicesApi.createDevice(deviceInput)

    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <GridFormInput
          width={6}
          label="Serial Number"
          error={inputErrors.serialNumber}
        >
          <Input
            value={deviceInput.serialNumber}
            onChange={(e) => handlePropChange("serialNumber", e.target.value)}
            required
          />
        </GridFormInput>
        <GridFormInput width={6} label="Device Type">
          <Select
            value={deviceInput.deviceType}
            onChange={(_, value) => {
              if (value) {
                handlePropChange("deviceType", value)
              }
            }}
            disabled
            required
          >
            {deviceTypeOptions.map((option, index) => {
              return (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              )
            })}
          </Select>
        </GridFormInput>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end">
          <Button type="submit">{deviceData ? "Update" : "Create"}</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewDeviceForm
