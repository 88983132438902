/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestUploadIn
 */
export interface RequestUploadIn {
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestUploadIn
     */
    md5Hashes: Array<string>;
}

/**
 * Check if a given object implements the RequestUploadIn interface.
 */
export function instanceOfRequestUploadIn(value: object): value is RequestUploadIn {
    if (!('md5Hashes' in value) || value['md5Hashes'] === undefined) return false;
    return true;
}

export function RequestUploadInFromJSON(json: any): RequestUploadIn {
    return RequestUploadInFromJSONTyped(json, false);
}

export function RequestUploadInFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestUploadIn {
    if (json == null) {
        return json;
    }
    return {
        
        'md5Hashes': json['md5_hashes'],
    };
}

export function RequestUploadInToJSON(value?: RequestUploadIn | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'md5_hashes': value['md5Hashes'],
    };
}

