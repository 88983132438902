import { styled } from "@mui/joy/styles"
import { Group } from "@visx/group"

export const StyledEventGroup = styled(Group)({
  borderRadius: "0px",
})

export const StyledExclusionGroup = styled(Group)({
  borderRadius: "0px",
})
