import { ButtonProps, styled } from "@mui/material"
import { useState } from "react"
import { Span } from "../../elements"
import Button from "./Button"
import { styleIf } from "../../utils"

type FilterButtonProps = {
  count: number
  selected?: boolean
} & ButtonProps

const StyledFilterButton = styled(Button)<FilterButtonProps>(
  ({ selected }) => ({
    backgroundColor: "#FFF",
    color: "#2F2E2E", // black text theme?
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "20px",
    borderRadius: "7px",
    marginRight: "14px",
    boxShadow: "none",
    "&:last-child": {
      marginRight: 0,
    },
    "& span": {
      color: "#B1C3D1",
      fontSize: "12px",
      marginLeft: "5px",
    },
    "&:hover": {
      backgroundColor: "#D9E9F5",
      color: "#006BB9",
      boxShadow: "none",
    },
    ...styleIf(selected, {
      backgroundColor: "#D9E9F5", // background blue theme color
      color: "#006BB9", // blue text theme color?
      "& span": {
        fontWeight: 300,
        fontSize: "12px",
        marginLeft: "5px",
      },
    }),
  })
)

const FilterButton = (props: FilterButtonProps) => {
  const { children, count } = props
  return (
    <StyledFilterButton {...props}>
      {children} <Span>{count}</Span>
    </StyledFilterButton>
  )
}

const StyledFilterButtonBar = styled("div")({
  whiteSpace: "nowrap",
  overflowX: "auto",
  paddingBottom: "10px", // No clip on box shadow for buttons
})

type FilterButtonParams = {
  title: string
  count: number
  filter?: any
}

const FilterButtonBar = ({ buttons }: { buttons: FilterButtonParams[] }) => {
  const [selectedInx, setSelectedInx] = useState(0)

  return (
    <StyledFilterButtonBar>
      {buttons.map((button, i) => (
        <FilterButton
          selected={selectedInx === i}
          onClick={() => {
            setSelectedInx(i)
          }}
          key={button.title}
          count={button.count}
        >
          {button.title}
        </FilterButton>
      ))}
    </StyledFilterButtonBar>
  )
}

export default FilterButtonBar
