import {
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy"

import "react-phone-number-input/style.css"
import { useRecoilState } from "recoil"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import StudyUploadForm from "../../components/upload"
import { studyUploadModalOpen } from "../../state/modal.state"

function StudyUploadModal() {
  const [modalOpen, setModalOpenState] = useRecoilState(studyUploadModalOpen)

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={modalOpen}
      onClose={() => {
        setModalOpenState(false)
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalDialog variant="outlined">
        <DialogTitle>
          <CloudUploadOutlinedIcon />
          Upload Study
        </DialogTitle>
        <Divider />
        <DialogContent>Upload A Study File For Processing.</DialogContent>
        <StudyUploadForm />

        <Button
          variant="plain"
          color="neutral"
          onClick={() => setModalOpenState(false)}
        >
          Cancel
        </Button>
      </ModalDialog>
    </Modal>
  )
}

export default StudyUploadModal
