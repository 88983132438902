// - MUI imports
import { Box } from "@mui/joy"

type GuestLayoutParams = {
  children?: React.ReactNode
}

const AuthLayout = ({ children }: GuestLayoutParams) => {
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <Box
        sx={{
          boxShadow: 1,
          borderRadius: "25px",
          p: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default AuthLayout
