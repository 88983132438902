import { GetAllStudyOrdersStudyStatusesEnum } from "../generated/fetchclient"

export const getQueryFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const params: {
    [key: string]: string | GetAllStudyOrdersStudyStatusesEnum[]
  } = {}
  const studyStatuses: string[] = []
  urlParams.forEach((value, key) => {
    if (key === "studyStatuses") {
      studyStatuses.push(value)
    } else {
      params[key] = value
    }
  })
  if (studyStatuses.length) {
    params["studyStatuses"] =
      studyStatuses as GetAllStudyOrdersStudyStatusesEnum[]
  }
  return params
}
