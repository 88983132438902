import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import AddIcon from "@mui/icons-material/Add"
import Stack from "@mui/joy/Stack"
import { GetAllOrganizationsRequest } from "../../generated/fetchclient/apis/DefaultApi"
import FormControl from "@mui/joy/FormControl"
import Input from "@mui/joy/Input"
import IconButton from "@mui/joy/IconButton"
import { Search, Refresh } from "@mui/icons-material"
import useOrganizations from "../../hooks/useOrganizations"
import { GridPaginationModel } from "@mui/x-data-grid"

interface OrganizationContentSearchHeaderProps {
  openAddModal: () => void
  organizationQuery: GetAllOrganizationsRequest
  setOrganizationQuery: React.Dispatch<
    React.SetStateAction<GetAllOrganizationsRequest>
  >
  searchOrganization: (paginationModel: GridPaginationModel | undefined) => void
  paginationModel: GridPaginationModel | undefined
  emptyOrganizationQuery: GetAllOrganizationsRequest
}

const OrganizationContentSearchHeader = ({
  openAddModal,
  organizationQuery,
  setOrganizationQuery,
  searchOrganization,
  emptyOrganizationQuery,
  paginationModel,
}: OrganizationContentSearchHeaderProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      searchOrganization(paginationModel)
    }
  }

  const organizationsApi = useOrganizations()
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Stack direction="row" spacing={2} display="flex" alignItems="center">
        <FormControl>
          <Input
            placeholder="Organization Name"
            value={organizationQuery.name}
            onChange={(e) => setOrganizationQuery({ name: e.target.value })}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <IconButton
          onClick={() => searchOrganization(paginationModel)}
          variant="solid"
          color="primary"
        >
          <Search />
        </IconButton>
        <IconButton
          onClick={() => {
            setOrganizationQuery(emptyOrganizationQuery)
            if (paginationModel === undefined) {
              organizationsApi.listOrganizations()
            } else {
              organizationsApi.listOrganizations({
                limit: paginationModel.pageSize,
                offset: paginationModel.page * paginationModel.pageSize,
              })
            }
          }}
          variant="outlined"
          color="primary"
        >
          <Refresh />
        </IconButton>
      </Stack>
      <Button
        size="sm"
        startDecorator={<AddIcon />}
        onClick={() => openAddModal()}
      >
        New Organization
      </Button>
    </Box>
  )
}

export default OrganizationContentSearchHeader
