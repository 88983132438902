import {
  Box,
  Divider,
  IconButton,
  listItemButtonClasses,
  Sheet,
  Stack,
} from "@mui/joy"
import {
  AccountCircle,
  AdminPanelSettingsOutlined,
  DevicesOutlined,
  // CloudUploadOutlined,
  GroupsOutlined,
  InsertChartOutlined,
  LogoutRounded,
  MedicalInformationOutlined,
} from "@mui/icons-material"
import BusinessIcon from "@mui/icons-material/Business"
import { Auth } from "aws-amplify"
import HuxleyLogo from "./icons/HuxleyLogo"
import { useRecoilValue } from "recoil"
import { canViewUserPageRoles } from "../views/auth/roles"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { currentUser, isHuxleyUser } from "../state/auth.state"
import SidebarLink from "../components/SidebarLink"

export const openSidebar = () => {
  if (typeof document !== "undefined") {
    document.body.style.overflow = "hidden"
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1")
  }
}

export const closeSidebar = () => {
  if (typeof document !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn")
    document.body.style.removeProperty("overflow")
  }
}

export const toggleSidebar = () => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn")
    if (slideIn) {
      closeSidebar()
    } else {
      openSidebar()
    }
  }
}

export default function Sidebar() {
  const isAdminUser = useRecoilValue(isHuxleyUser)
  const user = useRecoilValue(currentUser)
  const [showUsersIcon, setShowUsersIcon] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.primaryRole) {
      setShowUsersIcon(canViewUserPageRoles.includes(user.primaryRole))
    }
  }, [user])

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "fixed",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "80px",
        top: 0,
        px: 1,
        py: 1,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
          marginLeft: {
            xs: "80px",
            md: "0px",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box
        sx={{
          display: "flex",
          gap: 0,
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <HuxleyLogo />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <Stack spacing={2}>
          <SidebarLink
            target="/"
            icon={<InsertChartOutlined />}
            label="Studies"
          />
          <SidebarLink
            target="/patients"
            icon={<MedicalInformationOutlined />}
            label="Patients"
          />
          {isAdminUser && (
            <SidebarLink
              target="/admin"
              icon={<AdminPanelSettingsOutlined />}
              label="Admin"
            />
          )}
          {isAdminUser && (
            <SidebarLink
              target="/devices"
              icon={<DevicesOutlined />}
              label="Devices"
            />
          )}
          {isAdminUser && (
            <SidebarLink
              target="/organizations"
              icon={<BusinessIcon />}
              label="Orgs"
            />
          )}
          {showUsersIcon && (
            <SidebarLink
              target="/users"
              icon={<GroupsOutlined />}
              label="Users"
            />
          )}
          {/* TODO: Uncomment this once a general upload page (showing studies that can be uploaded) is created
          <SidebarLink
            target="/upload"
            icon={<CloudUploadOutlined />}
            label="Upload"
            visibility={adminIconsVisibility}
          /> */}
        </Stack>
      </Box>
      <Divider />
      <IconButton
        size="md"
        variant="plain"
        color="neutral"
        onClick={() => {
          navigate("/profile")
        }}
      >
        <AccountCircle />
      </IconButton>
      <IconButton
        size="md"
        variant="plain"
        color="neutral"
        onClick={() => {
          Auth.signOut()
        }}
      >
        <LogoutRounded />
      </IconButton>
    </Sheet>
  )
}
