import { IconButton, Stack, Typography } from "@mui/joy"
import { useNavigate } from "react-router-dom"
import { Visibility } from "../types/event.type"
import { ReactElement } from "react"

type SidebarLinkParams = {
  target: string
  icon: ReactElement
  label: string
  visibility?: Visibility
}

export default function SidebarLink({
  target,
  icon,
  label,
  visibility = "visible",
}: SidebarLinkParams) {
  const nav = useNavigate()

  return (
    <Stack alignItems="center" spacing={0.2} visibility={visibility}>
      <IconButton
        size="lg"
        variant="plain"
        color="neutral"
        component="a"
        onClick={(_) => nav(target)}
      >
        {icon}
      </IconButton>
      <Typography level="body-xs">{label}</Typography>
    </Stack>
  )
}
