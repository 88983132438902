import { useRecoilValue, useSetRecoilState } from "recoil"
import useStudies from "./useStudies"
import { currentStudy } from "../state/study.state"
import { JSONContent } from "@tiptap/core"
import { handleApiError } from "../utils/apiUtils"
import { snackAlert } from "../components/SnackAlerts"

interface InterpretStudyProps {
  interpretationEditorContentData: JSONContent
}

const useInterpretStudy = () => {
  const studiesApi = useStudies()
  const setSnackAlertMsg = useSetRecoilState(snackAlert)
  const study = useRecoilValue(currentStudy)

  const interpretStudy = async ({
    interpretationEditorContentData,
  }: InterpretStudyProps) => {
    if (study?.uuid === undefined) return

    try {
      await studiesApi.updateStudyEvents(study?.uuid)
      await studiesApi.interpretStudy(study?.uuid, {
        interpretationNotes: { ...interpretationEditorContentData },
      })
      return true
    } catch (error) {
      handleApiError(setSnackAlertMsg)(error)
      throw error
    }
  }

  return {
    interpretStudy,
  }
}

export default useInterpretStudy
