import { Breadcrumbs, Link, Typography } from "@mui/joy"
import React from "react"
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"

export interface BreadCrumbListInterface {
  fontWeight: number
  color?: "success" | "danger" | "primary" | "neutral"
  fontSize: number
  text: string | undefined
  link?: string
}

export interface InternalHeaderProps {
  children: React.ReactNode
  breadCrumbList: BreadCrumbListInterface[]
  seperator?: React.ReactNode
}

const InternalHeader: React.FC<InternalHeaderProps> = ({
  children,
  breadCrumbList,
  seperator,
}) => {
  return (
    <Breadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={seperator ?? <ChevronRightRoundedIcon />}
      sx={{ pl: 0 }}
    >
      {children}
      {breadCrumbList.length > 0 &&
        breadCrumbList.map(
          (
            {
              color,
              fontWeight,
              fontSize,
              text,
              link,
            }: BreadCrumbListInterface,
            index: number
          ) => (
            <Typography
              key={index}
              color={color ?? "primary"}
              fontWeight={fontWeight}
              fontSize={fontSize}
            >
              {(link && <Link href={link}>{text}</Link>) || text}
            </Typography>
          )
        )}
    </Breadcrumbs>
  )
}

export default InternalHeader
