import { Group } from "@visx/group"
import { Line } from "@visx/shape"
import { ScaleLinear } from "d3-scale"
import { Signal } from "../../interfaces"

type StepLinePlotParams = {
  width: number
  height: number
  data: Signal
  children?: React.ReactNode
  xScale: ScaleLinear<number, number, never>
  yScale: ScaleLinear<number, number, never>
  stroke?: string
  strokeWidth?: number
}

type HorizontalLine = {
  yValue: number
  xDomain: {
    min: number
    max: number
  }
}

type Domain = {
  min: number
  max: number
}

type VerticalLine = {
  xValue: number
  yDomain: Domain
}

/*
Logic:
get horizontal lines to draw
 - group y values that are the same contiguously
     - find min and max x values
 - for every horizontal line
    - Draw vertical line from y1 to y2
*/

/**
 * getHorizontalLines returns the horizontal lines for contiguous y values given a list of points.
 *
 * @param {Point[]} data
 * @returns {HorizontalLine[]} horizontalLines
 */
const getHorizontalLines = (data: Signal): HorizontalLine[] =>
  data.values.reduce<HorizontalLine[]>(
    (accumulator: HorizontalLine[], currentValue: number, index: number) => {
      const lastVal: HorizontalLine = accumulator[accumulator.length - 1]

      const newLine = {
        yValue: currentValue,
        xDomain: {} as Domain,
      }

      // extend the min and max
      if (lastVal !== undefined && lastVal.yValue === currentValue) {
        lastVal.xDomain.max = Math.max(
          lastVal.xDomain.max,
          data.timestamps[index]
        )
        lastVal.xDomain.min = Math.min(
          lastVal.xDomain.min,
          data.timestamps[index]
        )
        return accumulator
      }
      if (lastVal !== undefined) {
        // cut the line
        newLine.xDomain = {
          // extend the last max to the min
          min: Math.min(data.timestamps[index], lastVal.xDomain.max),
          max: data.timestamps[index],
        }
        return [...accumulator, newLine]
      }
      newLine.xDomain = {
        min: data.timestamps[index],
        max: data.timestamps[index],
      }
      return [...accumulator, newLine]
    },
    []
  )

/**
 * getVerticalLines returns the vertical connecting lines given a set of horizontal lines
 *
 * @param horizontalLines
 * @returns {VerticalLine[]} verticalLines
 */
const getVerticalLines = (horizontalLines: HorizontalLine[]): VerticalLine[] =>
  horizontalLines.reduce<VerticalLine[]>(
    (
      accumulator: VerticalLine[],
      currentValue: HorizontalLine,
      currentIndex: number,
      array: HorizontalLine[]
    ) => {
      const nextLine = array[currentIndex + 1]

      if (nextLine !== undefined) {
        return [
          ...accumulator,
          {
            xValue: currentValue.xDomain.max,
            yDomain: {
              min: currentValue.yValue,
              max: nextLine.yValue,
            },
          },
        ]
      }
      return accumulator
    },
    []
  )

/**
 * StepLinePlot renders a generic step line plot
 *
 * @param {StepLinePlotParams} StepLinePlotParams
 * @returns {JSX.Element} JSX.Element
 */
const StepLinePlot = ({
  data,
  width,
  height,
  children,
  xScale,
  yScale,
  stroke = "#000",
  strokeWidth = 1.5,
}: StepLinePlotParams): JSX.Element => {
  const horizontalLines = getHorizontalLines(data)
  const verticalLines = getVerticalLines(horizontalLines)

  return (
    <svg width={width} height={height}>
      <Group data-testid="step-lines">
        {horizontalLines.map((lineData) => {
          return (
            <Line
              key={JSON.stringify(lineData)}
              from={{
                y: yScale(lineData.yValue),
                x: xScale(lineData.xDomain.min),
              }}
              to={{
                y: yScale(lineData.yValue),
                x: xScale(lineData.xDomain.max),
              }}
              stroke={stroke}
              // This is a simple fix rather than refacotring to polyline
              strokeLinecap="round"
              strokeWidth={strokeWidth}
              shapeRendering="geometricPrecision"
            />
          )
        })}

        {verticalLines.map((lineData) => {
          return (
            <Line
              key={JSON.stringify(lineData)}
              from={{
                y: yScale(lineData.yDomain.min),
                x: xScale(lineData.xValue),
              }}
              to={{
                y: yScale(lineData.yDomain.max),
                x: xScale(lineData.xValue),
              }}
              stroke={stroke}
              strokeLinecap="round"
              strokeWidth={strokeWidth}
              shapeRendering="geometricPrecision"
            />
          )
        })}
      </Group>

      <Group className="children-group">{children}</Group>
    </svg>
  )
}

export default StepLinePlot
