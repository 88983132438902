const getMimeTypeFromExtension = (extension: string): string => {
  const mimeTypes: { [key: string]: string } = {
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    bmp: "image/bmp",
    gif: "image/gif",
    tiff: "image/tiff",
  }
  return mimeTypes[extension.toLowerCase()] || "application/octet-stream"
}
export const getBase64FromUrl = async (url: string): Promise<string> => {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`)
  }

  const urlParts = url.split(".")
  const extension = urlParts[urlParts.length - 1].split("?")[0]
  const mimeType = getMimeTypeFromExtension(extension)

  const blob = await response.blob()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        const base64Image = reader.result.replace(
          /^data:.*?;base64,/,
          `data:${mimeType};base64,`
        )
        resolve(base64Image)
      } else {
        reject(new Error("Failed to convert image to base64"))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
