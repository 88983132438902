/* eslint-disable prettier/prettier */
import { atom, selectorFamily } from "recoil"
import { StudyMetadata } from "../interfaces/study.interface"
import { eventFamily, eventIDs } from "../state/event.state"
import {EventPlot, RefineEventType, ScoringCriteria} from "../types/event.type"
import { SleepStudyOrderOut } from "../generated/fetchclient/models/SleepStudyOrderOut"
import { timeIntervalType } from "../types/time.type"
import { SleepStudyOutData } from "../types/study.type"

export const currentStudy = atom<SleepStudyOutData | undefined>({
  key: "currentStudy",
  default: undefined,
})

export const studyCount = atom<number>({
  key: "studyCount",
  default: 0,
})

export const activeStudyID = atom<string | undefined>({
  key: "activeStudyID",
})

export const isLoadingStudy = atom<boolean>({
  key: "isLoadingStudy",
  default: false,
})

export const studies = atom<SleepStudyOrderOut[]>({
  key: "studies",
  default: [],
})

export const editEventExclusionStatus = atom<boolean>({
  key: "editEventExclusionStatus",
  default: false,
})

export const eventExclusionLoader = atom<boolean>({
  key: "eventExclusionLoader",
  default: false,
})

export const reviewStudyChanges = atom<boolean>({
  key: "reviewStudyChanges",
  default: false,
})

export const studyPlotOrder = atom<{ plotType: EventPlot, enabled: boolean }[]>(
  {
      key: "studyPlotOrder",
      default: [
        {plotType: "Snoring", enabled: true},
        {plotType: "Resp", enabled: true},
        {plotType: "ECG", enabled: true},
        {plotType: "SpO2", enabled: true},
        {plotType: "HR", enabled: true},
        {plotType: "Chest", enabled: true},
        {plotType: "Actigraphy", enabled: true},
        {plotType: "Position", enabled: true},
        {plotType: "SANSASleepStage", enabled: true},
      ],
  }
)

export const studyMetaData3 = atom<StudyMetadata>({
  key: "studyMetaData3",
  default: {
    friendlyId: undefined,
    studyStartTime: 0,
    studyEndTime: 0,
    scoringCriteria: ScoringCriteria.THREE,
    swVersion: undefined,
  },
})

export const studyMetaData4 = atom<StudyMetadata>({
  key: "studyMetaData4",
  default: {
    friendlyId: undefined,
    studyStartTime: 0,
    studyEndTime: 0,
    scoringCriteria: ScoringCriteria.FOUR,
    swVersion: undefined,
  },
})

export const activeCriteria = atom<ScoringCriteria>({
  key: "activeCriteria",
  default: ScoringCriteria.THREE
})

export const timeIntervals = atom<timeIntervalType[]>({
  key: "timeIntervals",
  default: [],
})

export const fullNightInterval = atom<number>({
  key: "fullNightInterval",
  default: 0,
})

export const refineEventTypeAtom = atom<RefineEventType>({
  key: "refineEventTypeAtom",
  default: "All",
})

/**
 * plotSidebarTimeSort - returns sorted list of eventIDs based on
 * refineEventTypeAtom atom state
 */
export const plotSidebarTimeSort = selectorFamily({
  key: `plotSidebarTimeSort`,
  get:
    ({ studyID }: { studyID: string }) =>
      ({ get }) =>
        get(eventIDs)
          .map((eventID) => get(eventFamily(eventID)))
          .filter(
            (eventData) =>
              get(refineEventTypeAtom) === "All" ||
              eventData.type === get(refineEventTypeAtom)
          )
          .sort((a, b) => a.event_ts[0] - b.event_ts[0])
          .map((eventData) => eventData.id),
})

export const intervalTimeEventTypeAtom = atom<number>({
  key: "intervalTimeEventTypeAtom",
  default: 0,
})