import { Point } from "@huxley-medical/react-components/types"
import { DynamicInterval } from "../../../../utils/DynamicInterval"
import {
  ResizeScrollMode,
  ScrollRoutineContext,
} from "../../../../types/scroller.type"
import { SnackAlert } from "../../../../types/snackAlert.types"
import { SetterOrUpdater } from "recoil"
import { DragMoveCallback } from "@huxley-medical/react-components/hooks/mouse"
import {
  eventIntersector,
  findEventIntersectEnd,
  findEventIntersectStart,
} from "../../event/utils"
import { scrollError } from "../../scroll/hooks"

type HandleCreateEventResizeParams = {
  drawEventStart: Point | undefined
  plotWidth: number
  scrollRoutine: React.MutableRefObject<DynamicInterval>
  setDrawEventStart: React.Dispatch<React.SetStateAction<Point | undefined>>
  setNewRange: (newRangePx: number[]) => void
  setScrollMode: React.Dispatch<
    React.SetStateAction<ResizeScrollMode | undefined>
  >
  setSnackAlertMsg: SetterOrUpdater<SnackAlert>
}

/**
 * handleCreateEventSelection is a callback for handling new event creation. It
 * prevents windows from being created on top of other events. It will invoke a scroll
 * under certain conditions: (start or end of selection goes off-screen).
 *
 * TODO: This code could be DRYd and refactored in itself. It could also (maybe) be combined
 * with handleEventResize
 *
 * @param {HandleCreateEventResizeParams} handleCreateEventResizeParams
 * @returns {DragMoveCallback} DragMoveCallback
 */
export const handleCreateEventSelection =
  ({
    drawEventStart,
    plotWidth,
    scrollRoutine,
    setDrawEventStart,
    setNewRange,
    setScrollMode,
    setSnackAlertMsg,
  }: HandleCreateEventResizeParams): DragMoveCallback =>
  (relPoint: Point, _: Point) => {
    if (drawEventStart === undefined) return

    const ctx = scrollRoutine.current.additional as ScrollRoutineContext
    if (ctx.rangePx === undefined) return

    const relWidth = relPoint.x - drawEventStart.x

    const eventIntersectionDetector = eventIntersector({
      widthPx: ctx.rangePx[1],
      parentWidth: plotWidth,
      exclusions: ctx.exclusionData,
      allPlotEvents: ctx.allPlotEvents,
      timeScale: ctx.timeScale,
    })

    let suggestedStart = Math.min(drawEventStart.x, relPoint.x)
    const suggestedWidth = Math.abs(relWidth)
    let suggestedEnd = suggestedStart + suggestedWidth

    const orientation = relWidth > 0 ? "right" : "left"

    if (orientation === "right") {
      // Prevent event from exceding any start
      const intersectEventStart = findEventIntersectStart(
        ctx.allPlotEvents,
        ctx.exclusionData,
        ctx.timeScale,
        undefined,
        suggestedStart,
        suggestedEnd
      )

      if (intersectEventStart !== undefined) {
        suggestedEnd = intersectEventStart
      }

      // Does the end go off-screen? If so, start scroll mode
      // Prevent right scrolling when start is to the right side off screen
      if (suggestedEnd > plotWidth && suggestedStart < plotWidth) {
        // Ensure the event window is flush with end of plot before scroller startup
        if (ctx.scrollMode === undefined) {
          setNewRange([ctx.rangePx[0], plotWidth])
        }

        const intersect = eventIntersectionDetector(
          suggestedStart,
          suggestedEnd
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        // scroll
        setScrollMode({
          method: "resize",
          orientation: orientation,
          // times per second
          speed: Math.min(10, Math.max((suggestedEnd - plotWidth) / 10, 1)),
        })

        return
      }

      if (scrollRoutine.current.started) {
        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setDrawEventStart({
          // end of plot, minus where the range was originally clicked
          x: ctx.rangePx[0],
          // x: absPoint.x,
          y: drawEventStart.y,
        })

        return
      }

      setNewRange([suggestedStart, suggestedEnd])
    } else if (orientation === "left") {
      // Prevent event start from being less than any end
      const intersectEventEnd = findEventIntersectEnd(
        ctx.allPlotEvents,
        ctx.exclusionData,
        ctx.timeScale,
        undefined,
        suggestedStart,
        suggestedEnd
      )

      if (intersectEventEnd !== undefined) {
        suggestedStart = intersectEventEnd
      }

      // Does the start go off-screen? If so, start scroll mode
      if (suggestedStart < 0 && suggestedEnd > 0) {
        const intersect = eventIntersectionDetector(
          suggestedStart,
          suggestedEnd
        )

        // Don't activate scroll mode if the window positioned at end
        // of plot would intersect
        if (intersect.intersectedWith !== undefined) {
          setSnackAlertMsg(scrollError)
          return
        }

        // scroll
        setScrollMode({
          method: "resize",
          orientation: orientation,
          // times per second
          speed: Math.min(10, Math.max(Math.abs(suggestedStart) / 10, 1)),
        })

        // Ensure the event window is flush with end of plot before scroller startup
        if (ctx.scrollMode === undefined) {
          setNewRange([0, suggestedEnd])
        }
        return
      }

      if (scrollRoutine.current.started) {
        // Trigger stop in useScrollRoutine
        setScrollMode(undefined)

        // Refresh useWindowDrag here
        setDrawEventStart({
          // end of plot, minus where the range was originally clicked
          x: ctx.rangePx[0] + ctx.rangePx[1],
          // x: absPoint.x,
          y: drawEventStart.y,
        })

        return
      }

      setNewRange([suggestedStart, suggestedEnd])
    }
  }

export default handleCreateEventSelection
